import React from "react";
import { Modal, Button } from "semantic-ui-react";
import styles from "./index.module.css";

const TasksModalContent = ({ open, setOpen, title, tasks, onConfirm }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={handleClose}
      className={styles.archivedModal}
    >
      <Modal.Content className={styles.archivedModalContent}>
        <div className={styles.archivedModalContentHeader}>
          {title} the following tasks?
        </div>
        {tasks?.map((item, index) => (
          <div key={index} className={styles.archivedModalContentList}>
            {index + 1} SL-{item.id} ({item.name})
          </div>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="text"
          content="Cancel"
          onClick={handleClose}
        />
        <Button content={title} onClick={onConfirm} primary />
      </Modal.Actions>
    </Modal>
  );
};

export default TasksModalContent;
