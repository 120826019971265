import moment from "moment";
import { matterToUnits } from "utils/constToUnits";

/**
 * @typedef SprayTaskChemicalCost
 * @type {object}
 * @property {number} chemicalCostsPerHectare
 * @property {number} chemicalId
 * @property {string} chemicalMatter
 * @property {string} chemicalName
 * @property {number} quantityPerHectare
 * @property {number} sprayTaskId
 */

/**
 * @typedef SprayTask
 * @type {object}
 * @property {number} chemicalCost
 * @property {number} labourCost
 * @property {number} machineryCost
 * @property {Array.<SprayTaskChemicalCost>} sprayTaskChemicalCosts
 * @property {number} sprayTaskId
 * @property {number} totalAreasSize
 */

/**
 * @param {Number} dilutionRate
 * @param {Number} areaTreated
 * @returns {number}
 */
export const calculateQuantityPerHectare = (areaTreated, dilutionRate) =>
  areaTreated * dilutionRate;
/**
 * @param {Number} areaTreated
 * @param {Number} literPerHectare
 * @param {Number} dilutionRate
 * @param {Number} actualQuantity
 * @returns {number}
 */
export const calculateQuantityPer100L = (
  areaTreated,
  literPerHectare,
  dilutionRate,
  actualQuantity
) =>
  (actualQuantity
    ? (actualQuantity * dilutionRate) / 100
    : (areaTreated * literPerHectare) / 100) * dilutionRate;

/**
 * @param {Number} quantity
 * @param {Number} price
 * @returns {number}
 */
export const calculateCost = (quantity, price) => quantity * price;

export const getGroupedChemicalsBreakdown = (
  breakdown,
  chemicalsTypeFilters,
  sprayTasks
) => {
  const IndexedBreakdown = {};
  breakdown.forEach(t => (IndexedBreakdown[t.taskIDClear] = t));
  sprayTasks.forEach(t => (IndexedBreakdown[t.id].sprayTask = t));
  const filteredByID = breakdown
    // filters
    .filter(({ chemical }) => chemicalsTypeFilters.includes(chemical.type.id))
    // data
    .map(chemical => {
      const actualTask = chemical.sprayTask;
      let actualQuantity = 0;
      if (actualTask)
        actualQuantity = actualTask.actualTotalTanks * actualTask.machinerySize;
      const quantity =
        chemical.dilutionRateType === "PER_HECTARE"
          ? calculateQuantityPerHectare(
              chemical.areaTreated,
              chemical.dilutionRate
            )
          : calculateQuantityPer100L(
              chemical.areaTreated,
              chemical.literPerHectare,
              chemical.dilutionRate,
              actualQuantity
            );

      const cost = calculateCost(quantity, chemical.chemicalHistory.price);
      const numberOfApplications = Object.values(IndexedBreakdown).filter(
        c => chemical.chemical.id === c.chemical.id
      ).length;

      return {
        chemicalID: chemical.chemical.id,
        name: chemical.chemical.name,
        type: chemical.chemical.type.type,
        chemicalTypeID: chemical.chemical.type.id,
        quantity: quantity,
        areaTreated: chemical.areaTreated,
        cost: cost,
        numberOfApplications,
        costHectare: cost / chemical.areaTreated
      };
    });

  const numOfChemicals = {};

  const groupedByID =
    //group by chemical id
    filteredByID.reduce((prev, chemical) => {
      const { chemicalID } = chemical;
      numOfChemicals[chemicalID] = (numOfChemicals[chemicalID] || 0) + 1;
      return {
        ...prev,
        [chemical.chemicalID]: prev[chemicalID]
          ? {
              ...prev[chemicalID],
              quantity: prev[chemicalID].quantity + chemical.quantity,
              areaTreated: prev[chemicalID].areaTreated + chemical.areaTreated,
              cost: prev[chemicalID].cost + chemical.cost,
              costHectare: prev[chemicalID].costHectare + chemical.costHectare
            }
          : chemical
      };
    }, {});

  return (
    Object.values(groupedByID)
      .map(chemical => ({
        ...chemical,
        avgCostPerHa: chemical.costHectare / numOfChemicals[chemical.chemicalID]
      }))
      //group by chemical type
      .reduce((prev, chemical) => {
        const { chemicalTypeID } = chemical;
        return {
          ...prev,
          [chemical.chemicalTypeID]: prev[chemicalTypeID]
            ? [...prev[chemicalTypeID], chemical]
            : [chemical]
        };
      }, {})
  );
};

/**
 * @param {[]} sprayTasks
 * @param {String} filterKey
 * @param {Number} filterValue
 * @returns {any[]}
 */
export const getChemicalsFromSprayTasks = (
  sprayTasks,
  filterKey,
  filterValue = null
) =>
  sprayTasks
    .map(sprayTask => {
      const computedData = sprayTask.areas.reduce(
        (prev, area) => {
          if (filterKey === "area" && area.area.id !== filterValue) return prev;
          if (filterKey === "farm" && area.area.farm.id !== filterValue)
            return prev;

          const { hectares, actualTanks, plannedTanks } = area;

          const usedTanks = actualTanks ? actualTanks : plannedTanks;

          return {
            actualTanks: prev.actualTanks + usedTanks,
            plannedTanks: prev.plannedTanks + plannedTanks,
            areaTreated: prev.areaTreated + hectares
          };
        },
        { areaTreated: 0, actualTanks: 0, plannedTanks: 0 }
      );

      return sprayTask.chemicals.map(chemical => {
        return {
          ...chemical,
          literPerHectare: sprayTask.literPerHectare,
          taskID: `AT-${sprayTask.id}`,
          taskIDClear: sprayTask.id,
          areaTreated: computedData.areaTreated
        };
      });
    })
    .flat();

/**
 *
 * @param {Array.<SprayTask>} sprayTasks
 * @param {number} itemSize - can be either farm size or block (area) size
 * @returns {{breakdownDataTotals, breakdownData}}
 */
export const getFormatedSprayTasksForBreakdown = (sprayTasks, itemSize) => {
  const breakdownDataTotals = sprayTasks.reduce(
    (accumulator, task) => {
      return {
        chemicalCost: accumulator.chemicalCost + task.chemicalCost,
        machineryCost: accumulator.machineryCost + task.machineryCost,
        laborCost: accumulator.laborCost + task.labourCost,
        cost:
          accumulator.cost +
          task.chemicalCost +
          task.machineryCost +
          task.labourCost
      };
    },
    {
      chemicalCost: 0,
      machineryCost: 0,
      laborCost: 0,
      cost: 0
    }
  );

  breakdownDataTotals.size = itemSize;

  const breakdownData = sprayTasks.map(sprayTask => {
    const totalCost =
      sprayTask.chemicalCost + sprayTask.labourCost + sprayTask.machineryCost;
    return {
      ...sprayTask,
      taskType: "Spray",
      totalCost,
      costHectare: totalCost / itemSize,
      sprayTaskChemicalCosts: {
        totalAreasSize: sprayTask.totalAreasSize,
        chemicals: sprayTask.sprayTaskChemicalCosts
      },
      chemicalsForPdf: sprayTask.sprayTaskChemicalCosts.reduce(
        (prev, chemical) => {
          return {
            ...prev,
            chemicalNames: [...prev.chemicalNames, chemical.chemicalName],
            areasTreated: [...prev.areasTreated, sprayTask.totalAreasSize],
            quantitiesApplied: [
              ...prev.quantitiesApplied,
              {
                value: chemical.quantityPerHectare,
                type: chemical.chemicalMatter === "LIQUID" ? "L" : "Kg"
              }
            ],
            costsHectare: [
              ...prev.costsHectare,
              chemical.chemicalCostsPerHectare
            ],
            totalCosts: [
              ...prev.totalCosts,
              sprayTask.totalAreasSize * chemical.chemicalCostsPerHectare
            ]
          };
        },
        {
          chemicalNames: [],
          areasTreated: [],
          quantitiesApplied: [],
          costsHectare: [],
          totalCosts: []
        }
      )
    };
  });

  return {
    breakdownData,
    breakdownDataTotals
  };
};

/**
 *
 * @param {{}} chemical
 * @param {Number} size
 * @param {String} rowsToSpray
 * @param {Number} widthPerRow
 * @param {Number} literPerHectare
 * @returns {{quantity, units}}
 */
export const calculateChemicalQuantity = (
  chemical,
  size,
  rowsToSpray,
  widthPerRow,
  literPerHectare,
  conc
) => {
  const units = matterToUnits(chemical.matter);
  const rateType = chemical.rate.rateType;
  const dilutionRate = chemical.rate.rate || 0;
  const defaultAreaTreated =
    size * (rowsToSpray === "ALL" ? 1 : 0.5) * (widthPerRow / 100);
  const areaTreated =
    rateType === "PER_HECTARE" ? defaultAreaTreated : defaultAreaTreated * conc;
  const quantity =
    rateType === "PER_HECTARE"
      ? calculateQuantityPerHectare(areaTreated, dilutionRate)
      : calculateQuantityPer100L(areaTreated, literPerHectare, dilutionRate, 0);

  return { quantity, units };
};
