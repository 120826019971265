import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Header, Button, Icon } from "semantic-ui-react";
import { isEmptyValue } from "../../../../utils/tasksUtils";
import TaskRow from "../../../SprayDiary/TaskDetailsMobile/TaskRow";
import styles from "./Sidebar.module.css";
import { Link } from "react-router-dom";
const Sidebar = ({ vendor, id, setActiveItem }) => {
  return vendor ? (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column className={styles.wrapper} width={16}>
            <Header as="h1" className={styles.name}>
              {vendor.name}
            </Header>
            <div className={styles.btnWrapper}>
              <Button
                as={Link}
                primary
                to={`/harvest/vendors/vendor/${id}`}
              >
                Edit
              </Button>
              <Icon
                className={styles.closeIcon}
                name="close"
                onClick={() => setActiveItem(null)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header>Information</Header>
      <Grid>
        <TaskRow
          label="Email"
          name="email"
          value={<span>{isEmptyValue(vendor.email)}</span>}
        />
      </Grid>
    </>
  ) : (
    <div>Nothing to show</div>
  );
};

Sidebar.propTypes = {
  vendor: PropTypes.object,
};
Sidebar.defaultProps = {
  vendor: {},
};

const mapStateToProps = (state) => {
  const {
    notifications: { isSending, success, error, showMessage },
  } = state;
  return { notifications: { isSending, success, error, showMessage } };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
