import { isArray } from "lodash";
import React from "react";
import { Popup, Label } from "semantic-ui-react";

export const Chip = ({ color, items, label }) => {
  return (
    <Popup
      position="right center"
      style={{ textAlign: "left" }}
      trigger={
        <Label
          as="a"
          style={{
            display: "inline-flex",
            alignItems: "center",
            backgroundColor: "#F5F7F7",
            color: color === "green" ? "#00A9B5" : "#F0AC47",
            borderRadius: 20,
            padding: "4px 8px",
            paddingLeft: "0px",
          }}
        >
          <Label
            size="small"
            circular
            style={{
              backgroundColor: color === "green" ? "#00A9B5" : "#F0AC47",
              color: "#FFF",
              marginRight: 8,
              marginLeft: 8,
            }}
          >
            {isArray(items) ? items.length : items}
          </Label>
          <span>{label}</span>
        </Label>
      }
      content={
        isArray(items)
          ? items.map((item, index) => (
              <div key={`${label}-${index}`}>{item}</div>
            ))
          : items
      }
      inverted
    />
  );
};
