import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { Icon, Form, Input, Segment, Dropdown } from "semantic-ui-react";
import styles from "./EditModal.module.css";
import { useSelector } from "react-redux";
import { calculateTimeDifference } from "routes/Tasks/components/helper";
import DateRangePickerComponent from "./DateRangePicker";
import { machineryFilterOptions } from "selectors/generalTasks";
import { assigneeFilterOptions } from "selectors/harvestUnits";
import moment from "moment";

const EditForm = ({
  assignee,
  setErrorChecker,
  handleUpdateValue,
  item,
  wtIndex,
  addChecker,
  employeeList,
  setError,
  areaIndex,
  index
}) => {
  const { values, setFieldValue } = useFormikContext();
  const detailedItem =
    values?.blocks[areaIndex]?.assignee[index]?.workingTime[wtIndex];

  const machineryOptions = useSelector(machineryFilterOptions).options.map(
    item => ({
      key: item.key,
      text: item.label,
      value: item.value
    })
  );
  const assigneeOptions = useSelector(assigneeFilterOptions).options.map(
    item => ({
      key: item.key,
      text: item.label,
      value: item.value
    })
  );
  const from = `${moment(detailedItem?.start).format("yyyy-MM-DD")}T${
    detailedItem?.wtStart
  }:00`;
  const to = `${moment(detailedItem?.end).format("yyyy-MM-DD")}T${
    detailedItem?.wtEnd
  }:00`;

  const startDate = new Date(from);
  const endDate = new Date(to);
  const differenceMs = endDate - startDate;
  const differenceHours = differenceMs / (1000 * 60 * 60);
  const detailsHoursDifference =
    item?.wtStart &&
    item?.wtEnd &&
    calculateTimeDifference(item?.wtStart, item?.wtEnd, item?.lunchInterval);
  const detailsMinutesDifference =
    item?.wtStart &&
    item?.wtEnd &&
    calculateTimeDifference(item?.wtStart, item?.wtEnd, item?.lunchInterval);
  useEffect(() => {
    return setErrorChecker(!!differenceHours && differenceHours >= 12);
  }, [values]);

  return (
    <div className={styles.formWrapper}>
      <Form>
        <Segment className={styles.editModalSegment}>
          <Form.Group>
            {addChecker && (
              <Form.Field width={8}>
                <label>Employee</label>
                <Dropdown
                  placeholder="Select Assignee"
                  search
                  selection
                  options={assigneeOptions}
                  value={item.employeeId || ""}
                  onChange={async (_, { value }) => {
                    const employee = employeeList.find(i => i.id === value);
                    return (
                      handleUpdateValue("employeeId", value),
                      handleUpdateValue("employee", employee),
                      assignee.assignee
                        .map(item => item?.employee?.id)
                        .some(item => value === item)
                        ? setError(true)
                        : setError(null)
                    );
                  }}
                />
              </Form.Field>
            )}
            <Form.Field width={addChecker ? 8 : 16}>
              <label>Machinery</label>
              <Dropdown
                placeholder="Select Machinery"
                search
                selection
                options={machineryOptions}
                value={item.machineryId || ""}
                onChange={(_, { value }) => {
                  return handleUpdateValue("machineryId", value);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ gap: "40px" }}>
            <Form.Field width={8} className={styles.formField} required>
              <label>Date range </label>
              <DateRangePickerComponent
                values={values}
                handleUpdateValue={handleUpdateValue}
                wtIndex={wtIndex}
                setFieldValue={setFieldValue}
                item={item}
              />
            </Form.Field>
            <Form.Field width={8} className={styles.formField}>
              <label>Working time</label>
              <Field
                as={Input}
                disabled={true}
                icon={<Icon name="clock outline" />}
                className={styles.disabledField}
                name="row"
                value={
                  detailsHoursDifference && detailsMinutesDifference
                    ? `${detailsHoursDifference?.hoursDifference}h ${detailsMinutesDifference?.minutesDifference}mm`
                    : "0h 00mm"
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ gap: "40px" }}>
            <span style={{ width: "50%", display: "flex" }}>
              <Form.Field width={8} className={styles.formField}>
                <label>Start</label>
                <Field
                  as={Input}
                  name="wtStart"
                  type="time"
                  value={item?.wtStart}
                  onChange={(_, { value }) => {
                    handleUpdateValue("wtStart", value);
                  }}
                />
              </Form.Field>
              <Form.Field width={8} className={styles.formField}>
                <label>Finish</label>
                <Field
                  as={Input}
                  name="wtEnd"
                  type="time"
                  value={item?.wtEnd}
                  onChange={(_, { value }) => {
                    return handleUpdateValue("wtEnd", value);
                  }}
                />
              </Form.Field>
            </span>
            <Form.Field width={8} className={styles.formField}>
              <label>Piece rate</label>
              <Field
                as={Input}
                name="price"
                value={item?.price}
                onChange={(_, { value }) => {
                  return handleUpdateValue("price", value);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ gap: "40px" }}>
            <Form.Field required width={8} className={styles.formField}>
              <label>Lunch interval (min)</label>
              <Field
                as={Input}
                icon={<Icon name="clock outline" />}
                className={styles.disabledField}
                name="lunchInterval"
                value={item?.lunchInterval}
                onChange={(_, { value }) => {
                  return handleUpdateValue("lunchInterval", value);
                }}
              />
            </Form.Field>
            <span style={{ width: "50%", display: "flex" }}>
              <Form.Field width={8} type="number" className={styles.formField}>
                <label>No. of pieces</label>
                <Field
                  as={Input}
                  name="noOfPieces"
                  value={item?.noOfPieces}
                  onChange={(_, { value }) => {
                    return handleUpdateValue("noOfPieces", value);
                  }}
                />
              </Form.Field>
              <Form.Field width={8} className={styles.formField}>
                <label>Total ($)</label>
                <Field
                  as={Input}
                  disabled={true}
                  className={styles.disabledField}
                  name="number"
                  value={
                    item?.price && item?.noOfPieces
                      ? !isNaN(+item?.price * +item?.noOfPieces)
                        ? +item?.price * +item?.noOfPieces
                        : "0.00"
                      : "0.00"
                  }
                />
              </Form.Field>
            </span>
          </Form.Group>
        </Segment>
        {!!differenceHours && differenceHours >= 12 && (
          <div
            style={{
              width: "100%",
              marginTop: "16px",
              color: "#D92D20"
            }}
          >
            Should be earlier than 12 hours after Clocked In time
          </div>
        )}
      </Form>
    </div>
  );
};

export default EditForm;
