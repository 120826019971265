import React, { useState } from "react";
import {
  Button,
  Icon,
  Modal,
  Header,
  List,
  Container
} from "semantic-ui-react";
import styles from "../Inventory.module.css";

const InventoryActionButtons = ({
  archiveButtonTitle,
  archiveButtonIcon,
  onArchiveButtonClick,
  withModal,
  selectedProducts
}) => {
  const [isArchiveConfirmShown, setArchiveConfirmShown] = useState(false);

  return (
    <>
      <Modal
        className={styles.messageModal}
        onClose={() => setArchiveConfirmShown(false)}
        open={isArchiveConfirmShown}
        size="tiny"
      >
        <Modal.Content>
          <Header className={styles.modalMessageHeader}>
            Archive the following products?
          </Header>
          <List as="ol">
            {selectedProducts.map(product => (
              <List.Item as="li">{product.name}</List.Item>
            ))}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="text"
            content="Cancel"
            onClick={() => setArchiveConfirmShown(false)}
          />
          <Button
            primary
            content="Archive"
            onClick={onArchiveButtonClick}
          />
        </Modal.Actions>
      </Modal>

      <Button
        size="small"
        onClick={
          withModal ? () => setArchiveConfirmShown(true) : onArchiveButtonClick
        }
        primary
      >
        <Icon  name={archiveButtonIcon} />
        {archiveButtonTitle}
      </Button>
    </>
  );
};

export default InventoryActionButtons;
