import React from "react";
import { Button, Modal, Header } from "semantic-ui-react";
import modalStyles from "./ContractorActionButtons.module.css";
import styles from "../../Contractors/Contractors.module.css";

const ConfirmationModal = ({
  open,
  selectedItem,
  actionType,
  onChangeItem,
  onClose,
  selectedItems,
  modalLocation
}) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      closeOnDimmerClick={false}
      className={modalStyles.modal}
      size="mini"
    >
      <Modal.Content className={modalStyles.modalContentContainer}>
        <Header>
          <div className={modalStyles.modalHeaderText}>
            {selectedItem
              ? `${actionType}  ${selectedItem && selectedItem.name
                ? selectedItem.name
                : selectedItem.firstName + " " + selectedItem.lastName
              }?`
              : `${actionType} the following  ${modalLocation}?`}
          </div>
        </Header>
        {modalLocation && (
          <div className={modalStyles.listContainer}>
            <ol>
              {modalLocation === "employees"
                ? selectedItems().map(item => (
                  <li key={item.id} className={modalStyles.listItem}>
                    {item.firstName + " " + item.lastName}
                  </li>
                ))
                : selectedItems().map(item => (
                  <li key={item.id} className={modalStyles.listItem}>
                    {item.name}
                  </li>
                ))}
            </ol>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancel"
          type="text"
          onClick={onClose}
        />
        <Button
          content={actionType}
          type="text"
          onClick={onChangeItem}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmationModal;
