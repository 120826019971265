import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import filterTypes from "components/Table/filterTypes";
import { connect, useDispatch, useSelector } from "react-redux";
import { Icon, Button } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import {
  formaterData,
  selectTableStructuredData,
  selectExpotStructuredData
} from "selectors/reports/shed";
import PrintReports from "./NutrientPrintout/PrintReports";
import {
  fetchNutrientBreakdownTableData,
  fetchNutrientBreakdownTableDataList
} from "actions/Reports/shedsReport";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { history } from "../../../../store";
import { blocListSelector, patchListSelector } from "selectors/areas";
import styles from "./NutrientBreakdown.module.css";
import Numeric from "components/Numeric/Numeric";
import { farmsOptionsFilterSelector } from "selectors/farms";
import { useReactToPrint } from "react-to-print";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
import { excelExport } from "utils/excelExport";
import { get } from "lodash";

const headerColumn = [
  "Farm",
  "Nitrogen (N)",
  "Phosphorus (P)",
  "Potassium (K)",
  "Calcium (Ca)",
  "Magnesium (Mg)",
  "Sulphur (S)",
  "Iron (Fe)",
  "Boron (B)",
  "Manganese (Mn)",
  "Zinc (Zn)",
  "Copper (Cu)",
  "Molybdenum (Mo)",
  "Chlorine (Cl)",
  "Sodium (Na)"
];

const NutrientBreakdown = ({
  location,
  route,
  isFetching,
  areasList,
  farmList,
  navigate
}) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    cropIds: [],
    varietyIds: [],
    blockIds: [],
    patchIds: [],
    harvestUnitIds: [],
    seasons: [],
    from: "",
    to: ""
  });

  const [open, setOpen] = useState(false);
  const [filtersList, setFiltersList] = useState([]);
  const [expandedRow, setExpandedRow] = useState(false);
  const farmsList = useSelector(farmsOptionsFilterSelector);
  const blockList = useSelector(blocListSelector);
  const patchList = useSelector(patchListSelector);
  const componentRef = useRef();
  const fetchPrintoutAllInformation = check => {
    return dispatch(fetchNutrientBreakdownTableDataList(filtersList));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const printButton = () => {
    fetchPrintoutAllInformation().then(() => {
      handlePrint();
    });
  };
  const handleExcelExport = async () => {
    const data = await dispatch(
      fetchNutrientBreakdownTableDataList(filtersList)
    );

    const contentUpdated = data.content
      .map(item => {
        if (item.children && item.items) {
          return {
            farmId: item.farmId,
            children: [...item.children, ...item.items]
          };
        }

        if (item.children && !item.items) {
          return item;
        }

        if (!item.children && item.items) {
          return {
            farmId: item.farmId,
            children: [...item.items]
          };
        }
      })
      .map((block, item) => {
        const farmData = farmList.find(
          ({ farm }) => farm.id === get(block, "farmId", "")
        );
        return {
          withPatches: !!block.children,
          ...(block.children &&
            block.children.reduce(
              (prevPatch, current, index, childrens) => {
                const areaData = areasList.find(
                  ({ id }) => id === current.areaId
                );
                return {
                  ...prevPatch,
                  name: get(farmData.farm, "name", "-"),
                  nitrogen: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.n, 0),
                  potassium: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.k, 0),
                  calcium: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.ca, 0),
                  copper: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.cu, 0),
                  sulphur: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.s, 0),
                  iron: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.fe, 0),
                  boron: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.b, 0),
                  molybdenum: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.mo, 0),
                  chlorine: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.cl, 0),
                  phosphorus: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.p, 0),
                  sodium: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.na, 0),
                  zinc: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.zn, 0),
                  manganese: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.mn, 0),
                  magnesium: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.mg, 0),
                  subRows: [
                    ...(prevPatch && prevPatch.subRows),
                    (current.items ? current.items : childrens).reduce(
                      (prev, curr, _, items) => {
                        const areaData = areasList.find(
                          ({ id }) => id === current.areaId
                        );

                        return {
                          ...prev,
                          name: get(areaData, "name", "-"),
                          nitrogen: current.items
                            ? items.reduce((prev, curr) => prev + curr.n, 0)
                            : current.n,
                          potassium: current.items
                            ? items.reduce((prev, curr) => prev + curr.k, 0)
                            : current.k,
                          calcium: current.items
                            ? items.reduce((prev, curr) => prev + curr.ca, 0)
                            : current.ca,
                          copper: current.items
                            ? items.reduce((prev, curr) => prev + curr.cu, 0)
                            : current.cu,
                          sulphur: current.items
                            ? items.reduce((prev, curr) => prev + curr.s, 0)
                            : current.s,
                          iron: current.items
                            ? items.reduce((prev, curr) => prev + curr.fe, 0)
                            : current.fe,
                          boron: current.items
                            ? items.reduce((prev, curr) => prev + curr.b, 0)
                            : current.b,
                          molybdenum: current.items
                            ? items.reduce((prev, curr) => prev + curr.mo, 0)
                            : current.mo,
                          chlorine: current.items
                            ? items.reduce((prev, curr) => prev + curr.cl, 0)
                            : current.cl,
                          phosphorus: current.items
                            ? items.reduce((prev, curr) => prev + curr.p, 0)
                            : current.p,
                          sodium: current.items
                            ? items.reduce((prev, curr) => prev + curr.na, 0)
                            : current.na,
                          zinc: current.items
                            ? items.reduce((prev, curr) => prev + curr.zn, 0)
                            : current.zn,
                          manganese: current.items
                            ? items.reduce((prev, curr) => prev + curr.mn, 0)
                            : current.mn,
                          magnesium: current.items
                            ? items.reduce((prev, curr) => prev + curr.mg, 0)
                            : current.mg,
                          isSubRows: true,
                          ...(current.items && {
                            subRows: current.items.map(
                              ({
                                areaId,
                                b,
                                ca,
                                cu,
                                s,
                                n,
                                fe,
                                k,
                                mo,
                                na,
                                p,
                                cl,
                                mg,
                                mn,
                                zn
                              }) => {
                                const areaData = areasList.find(
                                  item => item.id === areaId
                                );
                                return {
                                  name: get(areaData, "name", "-"),
                                  nitrogen: n,
                                  potassium: k,
                                  calcium: ca,
                                  copper: cu,
                                  sulphur: s,
                                  iron: fe,
                                  boron: b,
                                  molybdenum: mo,
                                  chlorine: cl,
                                  phosphorus: p,
                                  sodium: na,
                                  zinc: zn,
                                  manganese: mn,
                                  magnesium: mg,
                                  subRows: []
                                };
                              }
                            )
                          })
                        };
                      },
                      {
                        name: "",
                        boron: null,
                        calcium: null,
                        copper: null,
                        sulphur: null,
                        nitrogen: null,
                        iron: null,
                        potassium: null,
                        molybdenum: null,
                        manganese: null,
                        manganese: null,
                        zinc: null,
                        chlorine: null,
                        phosphorus: null,
                        sodium: null,
                        subRows: []
                      }
                    )
                  ]
                };
              },
              {
                name: "",
                boron: null,
                calcium: null,
                copper: null,
                sulphur: null,
                nitrogen: null,
                iron: null,
                potassium: null,
                molybdenum: null,
                manganese: null,
                manganese: null,
                zinc: null,
                chlorine: null,
                phosphorus: null,
                sodium: null,
                subRows: []
              }
            ))
        };
      }, {});
    const expandedData = contentUpdated
      .map(item => {
        const child = item.subRows
          ?.map(sub => {
            return sub.subRows.length
              ? [
                ...sub.subRows.map(s => ({
                  ...s,
                  name: `${sub.name}/${s.name}`
                }))
              ]
              : sub;
          })
          .flat();
        return [item, ...child];
      })
      .flat();
    if (expandedData.length) {
      const excelData = expandedData.map(
        ({
          name,
          nitrogen,
          phosphorus,
          potassium,
          calcium,
          magnesium,
          sulphur,
          iron,
          boron,
          manganese,
          zinc,
          copper,
          molybdenum,
          chlorine,
          sodium
        }) => {
          return {
            ...{ name: name },
            ...{
              nitrogen: nitrogen?.toFixed(nitrogen > 1000 ? 0 : 2) || "0.00"
            },
            ...{
              phosphorus:
                phosphorus?.toFixed(phosphorus > 1000 ? 0 : 2) || "0.00"
            },
            ...{
              potassium: potassium?.toFixed(potassium > 1000 ? 0 : 2) || "0.00"
            },
            ...{ calcium: calcium?.toFixed(calcium > 1000 ? 0 : 2) || "0.00" },
            ...{
              magnesium: magnesium?.toFixed(magnesium > 1000 ? 0 : 2) || "0.00"
            },
            ...{ sulphur: sulphur?.toFixed(sulphur > 1000 ? 0 : 2) || "0.00" },
            ...{ iron: iron?.toFixed(iron > 1000 ? 0 : 2) || "0.00" },
            ...{ boron: boron?.toFixed(boron > 1000 ? 0 : 2) || "0.00" },
            ...{
              manganese: manganese?.toFixed(manganese > 1000 ? 0 : 2) || "0.00"
            },
            ...{ zinc: zinc?.toFixed(zinc > 1000 ? 0 : 2) || "0.00" },
            ...{ copper: copper?.toFixed(copper > 1000 ? 0 : 2) || "0.00" },
            ...{
              molybdenum:
                molybdenum?.toFixed(molybdenum > 1000 ? 0 : 2) || "0.00"
            },
            ...{
              chlorine: chlorine?.toFixed(chlorine > 1000 ? 0 : 2) || "0.00"
            },
            ...{ sodium: sodium?.toFixed(sodium > 1000 ? 0 : 2) || "0.00" }
          };
        }
      );
      excelExport(excelData, headerColumn);
    }
  };

  const getData = useCallback(
    params => {
      setFiltersList(params);
      dispatch(fetchNutrientBreakdownTableData(params));
    },
    [fetchNutrientBreakdownTableData]
  );

  const tableData = useSelector(selectTableStructuredData);
  const printData = useSelector(selectExpotStructuredData);

  useEffect(() => {
    return setExpandedRow(false);
  }, [tableData.number]);

  const columnsConfig = useMemo(
    () => [
      {
        accessor: "name",
        id: "expander",
        Header: "",
        filterId: "date",
        withSort: false,
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 1.5}rem`
                }
              })}
            >
              <Icon
                onClick={() => setExpandedRow(row.id)}
                name={`angle ${row.isExpanded ? "up" : "down"}`}
              />
              {value}
            </span>
          ) : row.depth == 1 ? (
            <span style={{ paddingLeft: `${1.5}rem` }}>{value}</span>
          ) : (
            <span style={{ paddingLeft: `${3}rem` }}>{value}</span>
          );
        },
        filter: {
          title: "Date",
          type: filterTypes.DateRangeSelect,
          selector: <DateRangeSelect loading={isFetching} />
        },
        minWidth: 280
      },
      {
        Header: "Nitrogen (N)",
        id: "nitrogen",
        withSort: false,
        accessor: "nitrogen",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        filterId: "seasons",
        filter: {
          title: "Season",
          type: filterTypes.SingleSelect,
          selector: seasonsOptionsSelector
        }
      },
      {
        accessor: "phosphorus",
        Header: "Phosphorus (P)",
        id: "phosphorus",
        withSort: false,
        Cell: ({ value, row }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        )
      },
      {
        accessor: "potassium",
        id: "potassium",
        withSort: false,
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Potassium (K)"
      },
      {
        accessor: "calcium",
        id: "calcium",
        withSort: false,
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Calcium (Ca)"
      },
      {
        accessor: "magnesium",
        id: "magnesium",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        withSort: false,
        Header: "Magnesium (Mg)"
      },
      {
        accessor: "sulphur",
        id: "sulphur",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        withSort: false,
        Header: "Sulphur  (S)"
      },
      {
        accessor: "iron",
        id: "iron",
        withSort: false,
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Iron (Fe)"
      },
      {
        accessor: "boron",
        id: "boron",
        withSort: false,
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Boron (B)"
      },
      {
        withSort: false,
        id: "manganese",
        accessor: "manganese",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Manganese (Mn)"
      },
      {
        withSort: false,
        id: "zinc",
        accessor: "zinc",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Zinc (Zn)"
      },
      {
        accessor: "copper",
        id: "copper",
        withSort: false,
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Copper (Cu)"
      },
      {
        Header: "Molybdenum (Mo)",
        withSort: false,
        id: "molybdenum",
        accessor: "molybdenum",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        )
      },
      {
        withSort: false,
        id: "chlorine",
        accessor: "chlorine",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Chlorine (Cl)"
      },
      {
        accessor: "sodium",
        withSort: false,
        id: "sodium",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Sodium (Na)"
      }
    ],
    [filters]
  );
  const handleBackClick = () => {
    navigate({
      pathname: "/reports",
      state: {
        activeNode: location?.state?.reportNode
      }
    });
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <PrintReports
          farmsList={farmsList}
          blockList={blockList}
          patchList={patchList}
          farms={filters.farmIds}
          from={filters.from}
          to={filters.to}
          data={printData}
          ref={componentRef}
        />
      </div>
      <TablePageHolder
        navigate={navigate}
        backButton={
          <Button
            floated="right"
            className={styles.backIconWrapper}
            onClick={handleBackClick}
          >
            <Icon className={styles.backIcon} name="angle left" />
          </Button>
        }
        actionsButtons={
          <>
            <Button
              primary
              onClick={() => handleExcelExport()}
            >
              <div className={styles.iconWrapperForPage}>
                <Icon name="download" />
                Export
              </div>
            </Button>
            <Button
              primary
              onClick={() => {
                return printButton();
              }}
            >
              <div className={styles.iconWrapperForPage}>
                <Icon name="print" />
                Print
              </div>
            </Button>
          </>
        }
        rowId={expandedRow}
        getData={getData}
        isFetching={tableData.isFetching}
        location={location}
        onRowClick={() => { }}
        open={open}
        setOpen={setOpen}
        setFilters={setFilters}
        pageTitle={route.name}
        route={route}
        tableColumns={columnsConfig}
        withSelection={false}
        tableData={tableData}
        withoutSort={false}
        firstColumnFixed={true}
        singleLine
        fixed={false}
      />
    </>
  );
};

NutrientBreakdown.propTypes = {
  Can: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};

export default connect((state, props) => {
  return {
    areasList: state.areas.list.content,
    farmList: state.farms.summary,
    filterValues: state.packedBoxes.filters,
    isFetching: state.packedBoxes.data.isFetching,
    error: state.packedBoxes.error
  };
})(NutrientBreakdown);
