import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Grid, Header, Icon, Button } from "semantic-ui-react";
import styles from "./Sidebar.module.css";
import moment from "moment";
import { Area } from "../../../../models/block.model";
import Numeric from "../../../../components/Numeric";
import { isEmptyValue } from "../../../../utils/tasksUtils";
import TaskRow from "../../../SprayDiary/TaskDetailsMobile/TaskRow";
import { Link } from "react-router-dom";

class Sidebar extends PureComponent {
  render() {
    const { packedBox: scan, setActiveItem, id } = this.props;
    const areaVariety =
      scan && new Area(scan.area).areaVarietyByDate(scan.scannedAt);
    return scan ? (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column className={styles.wrapper} width={16}>
              <Header as="h1" className={styles.name}>
                {scan.barcodeId}
              </Header>
              <div className={styles.btnWrapper}>
                <Button
                  as={Link}
                  primary
                  to={`/harvest/packed_boxes/edit/${id}`}
                >
                  Edit
                </Button>
                <Icon
                  className={styles.closeIcon}
                  name="close"
                  onClick={() => setActiveItem(null)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <TaskRow
            label="Scan date"
            name="scanDate"
            value={
              <span>
                {isEmptyValue(
                  scan.scannedAt && moment(scan.scannedAt).format("DD/MM/YYYY")
                )}
              </span>
            }
          />
          <TaskRow
            label="Farm"
            name="farm"
            value={
              <span>{isEmptyValue(scan.area && scan.area.farm.name)}</span>
            }
          />
          <TaskRow
            label="Area"
            name="area"
            value={
              <span>
                {isEmptyValue(
                  scan.area &&
                  `${scan.area.parent ? `${scan.area.parent.name} - ` : ""}${scan.area.name
                  }`
                )}
              </span>
            }
          />
          <TaskRow
            label="Registration number"
            name="registrationNumber"
            value={
              <span>
                {isEmptyValue(scan.area && scan.area.registrationNumber)}
              </span>
            }
          />
          <TaskRow
            label="Crop"
            name="crop"
            value={
              <span>
                {isEmptyValue(
                  areaVariety && areaVariety.crop && areaVariety.crop.name
                )}
              </span>
            }
          />
          <TaskRow
            label="Variety"
            name="variety"
            value={<span>{isEmptyValue(areaVariety && areaVariety.name)}</span>}
          />
          <TaskRow
            label="Pack type"
            name="boxUnit"
            value={
              <span>{isEmptyValue(scan.boxUnit && scan.boxUnit.name)}</span>
            }
          />
          <TaskRow
            label="Box type"
            name="boxUnit"
            value={
              <span>{isEmptyValue(scan.boxUnit && scan.boxUnit.name)}</span>
            }
          />
          <TaskRow
            label="Pack location"
            name="packLocation"
            value={<span>{isEmptyValue(scan.packLocation)}</span>}
          />
          {scan.packingShed && (
            <TaskRow
              label="Packing shed"
              name="packingShed"
              value={
                <span>
                  {isEmptyValue(scan.packingShed && scan.packingShed.name)}
                </span>
              }
            />
          )}
          <TaskRow
            label="Net weight"
            name="netWeight"
            value={
              <Numeric value={scan.netWeight} units={"Kg"} fractionDigits={2} />
            }
          />
          <TaskRow
            label="Intended destination"
            name="intendedDestination"
            value={<span>{isEmptyValue(scan.intendedDestination)}</span>}
          />
          <TaskRow
            label="Notes"
            name="notes"
            value={<span>{isEmptyValue(scan.notes)}</span>}
            wide
          />
        </Grid>
        <Header>Pricing</Header>
        <Grid>
          <TaskRow
            label="Picking price"
            name="pickingPrice"
            value={
              <span>
                <Numeric
                  value={scan.pickingPrice}
                  units={"$"}
                  unitsPosition={"left"}
                />
              </span>
            }
          />
          <TaskRow
            label="Padding price"
            name="paddingPrice"
            value={
              <span>
                <Numeric
                  value={scan.paddingPrice}
                  units={"$"}
                  unitsPosition={"left"}
                />
              </span>
            }
          />
          <TaskRow
            label="Carting price"
            name="cartingPrice"
            value={
              <span>
                <Numeric
                  value={scan.cartingPrice}
                  units={"$"}
                  unitsPosition={"left"}
                />
              </span>
            }
          />
        </Grid>
      </>
    ) : (
      <div>No Data</div>
    );
  }
}

Sidebar.propTypes = {
  packedBox: PropTypes.any,
};

export default Sidebar;
