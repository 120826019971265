import React, { useState } from "react";
import styles from "./SideBar.module.css";
import Loader from "components/Loader";
import CollapsibleBlock from "components/CollapsibleBlock";
import { Icon, Table, Button, Confirm ,Popup} from "semantic-ui-react";
import { updateGeneralTask, deleteGeneralTaskWorkingTime } from "actions/Tasks";
import moment from "moment";
import { useDispatch } from "react-redux";

const SideBar = ({
  task,
  activeRow,
  areaType,
  activeTab,
  employeeList,
  machineryList,
  onClose,
  setSelectedItems,
  setOpenModal,
  setActiveArea,
  activeArea,
  activeWorkingTime,
}) => {
  const dispatch = useDispatch();
  const [openCondirm, setOpenConfirm] = useState(false);
  const filtredSupervisor = employeeList.filter((item) =>
    task?.supervisors?.map((i) => i.employeeId).includes(item.id)
  );
  const filtredAssignees = employeeList.filter((item) =>
    task?.assignedTo?.map((i) => i.employeeId).includes(item.id)
  );
  const filtredWt = task?.workingTimes?.filter((wt) =>
    wt.areas.map((a) => a.areaId).includes(activeRow.id)
  );
  const filtredContractor = filtredWt
    ?.filter((wt) => wt.contractor)
    ?.map((i) => i.contractor);
  const filtredMachinery = machineryList?.filter((item) =>
    task?.equipments?.map((i) => i.machineryId)?.includes(item.id)
  );
  const blockUniqeArray = Array.from(
    new Set(activeRow?.varieties?.map((obj) => obj.variety))
  );

  const handleUpdate = async () => {
    const updatedAreas = Array.isArray(activeArea)
      ? activeArea.map((item) => ({ areaId: item, completed: false }))
      : [{ areaId: activeArea, completed: false }];
    const dataToSend = {
      ...task,
      areas: [
        ...task.areas.filter((item) =>
          updatedAreas.every((area) => area.areaId !== item.areaId)
        ),
        ...updatedAreas,
      ],
    };

    return (
      await dispatch(updateGeneralTask(task.id, dataToSend)),
      activeWorkingTime?.forEach(
        async (item) =>
          await dispatch(deleteGeneralTaskWorkingTime(task.id, item.id))
      ),
      setOpenConfirm(false),
      setActiveArea(null)
    );
  };
  return (
    <div className={styles.root}>
      {activeRow ? (
        <div className={styles.sidebarWrapper}>
          <div className={styles.headerWrapper}>
            <div className={styles.header}>
              {areaType === "ALL"
                ? activeRow?.children?.length
                  ? `${activeRow?.name} Patch ${activeRow?.children[0]?.name}`
                  : `Block ${activeRow?.name}`
                : `Block ${activeRow?.name}`}
            </div>
            <div className={styles.iconWrapper}>
              {activeTab === "Completed" && (
                <Popup
                  inverted
                  position="bottom center"
                  content={"Edit"}
                  size="tiny"
                  trigger={
                    <div className={styles.editButtonWrapper}>
                      <Button
                        onClick={async () => {
                          return (
                            await setSelectedItems([]),
                            setActiveArea(
                              activeRow.children.length
                                ? activeRow.children.map((i) => i.id)
                                : activeRow.id
                            ),
                            setOpenModal(true)
                          );
                        }}
                        className={styles.editButton}
                        floated="right"
                        icon={<Icon name="edit" />}
                        size="small"
                      />
                    </div>
                  }
                />
              )}
              <Icon
                name="close"
                className={styles.closeBtn}
                onClick={() => onClose()}
              />
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <CollapsibleBlock
              title="General Information"
              className={styles.blockContainer}
            >
              <Table className={styles.sectionTable} definition fixed>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className={styles.headerSection}>
                      Planned date
                    </Table.Cell>
                    <Table.Cell className={styles.valueSection}>
                      {`${moment(task?.startAt).format("DD/MM/YY")} -
                        ${moment(task?.endAt).format("DD/MM/YY")}`}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className={styles.headerSection}>
                      Expiry date
                    </Table.Cell>
                    <Table.Cell className={styles.valueSection}>
                      {moment(task?.expireAt).format("DD/MM/YYYY")}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className={styles.headerSection}>
                      Expiry time
                    </Table.Cell>
                    <Table.Cell className={styles.valueSection}>
                      {moment(task?.expireAt).format("HH:mm")}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className={styles.headerSection}>
                      Supervisor
                    </Table.Cell>
                    <Table.Cell className={styles.valueSection}>
                      {filtredSupervisor.length
                        ? filtredSupervisor.map((item, index) => (
                          <p
                            key={index}
                          >{`${item.firstName}${item.lastName}`}</p>
                        ))
                        : "-"}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="Locations"
              className={styles.blockContainer}
            >
              <Table className={styles.sectionTable} definition fixed>
                <Table.Body>
                  {areaType === "ALL" && (
                    <Table.Row>
                      <Table.Cell className={styles.headerSection}>
                        Block
                      </Table.Cell>
                      <Table.Cell className={styles.valueSection}>
                        {activeRow.name}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.Cell className={styles.headerSection}>
                      Patch
                    </Table.Cell>
                    <Table.Cell className={styles.valueSection}>
                      {activeRow?.children?.length
                        ? areaType === "ALL"
                          ? activeRow?.children.map((item) => {
                            return <p>{`${item.name}`}</p>;
                          })
                          : activeRow?.children.map((item) => {
                            const uniqueArray = Array.from(
                              new Set(
                                item.varieties.map((obj) => obj.variety)
                              )
                            );
                            return (
                              <p>
                                {`${item.name} `}
                                {uniqueArray.map((i) => {
                                  return (
                                    <span
                                      style={{ color: "#666666" }}
                                    >{`(${i.crop.name}${i.name})`}</span>
                                  );
                                })}
                              </p>
                            );
                          })
                        : "-"}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className={styles.headerSection}>
                      Size
                    </Table.Cell>
                    <Table.Cell className={styles.valueSection}>
                      {activeRow?.children?.length
                        ? activeRow?.children.reduce(
                          (prev, curr) => prev + curr.size,
                          0
                        )
                        : activeRow.size || "-"}
                    </Table.Cell>
                  </Table.Row>
                  {areaType === "ALL" && (
                    <Table.Row>
                      <Table.Cell className={styles.headerSection}>
                        Crop
                      </Table.Cell>
                      <Table.Cell className={styles.valueSection}>
                        {activeRow?.children?.length
                          ? activeRow?.children.map((item) => {
                            const uniqueArray = Array.from(
                              new Set(
                                item.varieties.map((obj) => obj.variety)
                              )
                            );
                            return (
                              <p>
                                {uniqueArray.map((i) => {
                                  return <span>{`${i.crop.name}`}</span>;
                                })}
                              </p>
                            );
                          })
                          : blockUniqeArray.length
                            ? blockUniqeArray.map((i) => {
                              return <span>{`${i.crop.name}`}</span>;
                            })
                            : "-"}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {areaType === "ALL" && (
                    <Table.Row>
                      <Table.Cell className={styles.headerSection}>
                        Variety
                      </Table.Cell>
                      <Table.Cell className={styles.valueSection}>
                        {activeRow?.children?.length
                          ? activeRow?.children.map((item) => {
                            const uniqueArray = Array.from(
                              new Set(
                                item.varieties.map((obj) => obj.variety)
                              )
                            );
                            return (
                              <p>
                                {uniqueArray.map((i) => {
                                  return <span>{`${i.name}`}</span>;
                                })}
                              </p>
                            );
                          })
                          : blockUniqeArray.length
                            ? blockUniqeArray.map((i) => {
                              return <span>{`${i.name}`}</span>;
                            })
                            : "-"}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </CollapsibleBlock>
            {activeTab === "Completed" && (
              <>
                <CollapsibleBlock
                  title="Assignees"
                  className={styles.blockContainer}
                >
                  <Table className={styles.sectionTable} definition fixed>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell className={styles.headerSection}>
                          Payment method
                        </Table.Cell>
                        <Table.Cell className={styles.valueSection}>
                          {(
                            task?.paymentMethod
                              ?.toLowerCase()
                              .charAt(0)
                              .toUpperCase() +
                            task?.paymentMethod?.toLowerCase().slice(1)
                          ).replace(/_/g, " ")}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className={styles.headerSection}>
                          Contractor
                        </Table.Cell>
                        <Table.Cell className={styles.valueSection}>
                          {filtredContractor?.length
                            ? filtredContractor?.map((item) => (
                              <p>{`${item.name}`}</p>
                            ))
                            : "-"}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell className={styles.headerSection}>
                          Assignees
                        </Table.Cell>
                        <Table.Cell className={styles.valueSection}>
                          {filtredAssignees.length
                            ? filtredAssignees.map((item) => (
                              <p>{`${item.firstName}${item.lastName}`}</p>
                            ))
                            : "-"}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </CollapsibleBlock>
                <CollapsibleBlock
                  title="Machinery"
                  className={styles.blockContainer}
                >
                  <Table className={styles.sectionTable} definition fixed>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell className={styles.headerSection}>
                          Required equipment
                        </Table.Cell>
                        <Table.Cell className={styles.valueSection}>
                          {filtredMachinery.length
                            ? filtredMachinery.map((item) => <p>{item.name}</p>)
                            : "-"}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </CollapsibleBlock>
              </>
            )}
          </div>
          {activeTab === "Completed" && (
            <div className={styles.btnWrpper}>
              <Button
              type="text"
                size="small"
                floated="right"
                onClick={() => { }}
              >
                <div
                  onClick={() => (
                    setActiveArea(
                      activeRow.children.length
                        ? activeRow.children.map((i) => i.id)
                        : activeRow.id
                    ),
                    setOpenConfirm(true)
                  )}
                  className={styles.iconWrapper}
                >
                  <Icon name="undo" />
                  Restore
                </div>
              </Button>
            </div>
          )}
          <Confirm
            className={styles.confirmModal}
            content={`Move the ${Array.isArray(activeArea) ? ` ${activeArea.length} areas` : "area"
              }   back in "To do" list?`}
            open={openCondirm}
            onCancel={() => setOpenConfirm(false)}
            onConfirm={() => handleUpdate()}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SideBar;
