import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Menu, Button, Icon, Label } from "semantic-ui-react";
import HarvestUnitsImageModal from "routes/HarvestUnitsControl/components/Slider";
import ObjectID from "bson-objectid";
import styles from "./Pictures.module.css";
import FileChooser from "components/Attachments/FileChooser";
import { Formik } from "formik";
import { uploadAttachment } from "actions/Chemicals";
import { useDispatch, connect } from "react-redux";
import Loader from "components/Loader";
import { updateQC } from "actions/QualityControl";

const BinPicture = ({
  activeItem,
  activeRounds,
  header,
  setCheckEdit,
  isFetching,
  isFetchingBarcode
}) => {
  const [test, setTest] = useState(false);
  const [urlList, setUrlList] = useState(false);
  const [blockName, setblockName] = useState(false);
  const [imageModalOpen, setimageModalOpen] = useState(false);
  const dispatch = useDispatch();
  const reader = new FileReader();
  const style = {
    imageIconContainer: {
      padding: "0px"
    },
    iconContainer: {
      backgroundColor: "black",
      padding: " 0px",
      width: "25px",
      height: "25px",
      paddingLeft: " 5px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "0"
    },

    closeIcon: {
      color: "white",
      marginRight: "11px",
      cursor: "ponter"
    },

    dotsContainerStyles: {
      display: "flex",
      justifyContent: "center"
    },

    dotStyle: {
      margin: "12px 12px",
      fontSize: "20px",
      marginTop: "15px"
    },

    image: {
      width: "136px",
      height: "136px",
      borderRadius: "8px",
      cursor: "pointer"
    }
  };
  const onSubmit = data => {
    dispatch(updateQC(activeItem.id, data)).then(() => setCheckEdit(false));
  };
  const onDeleteUrl = (changeValue, url, index) => {
    const filtredUrl = url.filter((item, urlIndex) => index !== urlIndex);
    return changeValue("pictures", filtredUrl);
  };
  const onAttachmentSelected = (setFieldValue, values) => files => {
    files.forEach(file => {
      const dataToSend = {
        file,
        idReplace: ObjectID().toHexString(),
        callBack: loadedFile => {
          return values.pictures
            ? setFieldValue("pictures", [
              ...values.pictures,
              {
                id: loadedFile.id,
                name: loadedFile.name,
                presignedUrl: loadedFile.presignedUrl,
                subPath: loadedFile.subPath
              }
            ])
            : setFieldValue("pictures", [
              {
                id: loadedFile.id,
                name: loadedFile.name,
                presignedUrl: loadedFile.presignedUrl,
                subPath: loadedFile.subPath
              }
            ]);
        }
      };
      reader.addEventListener("error", () => {
        console.error(`Error occurred reading file`);
      });
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(uploadAttachment(dataToSend)).catch(() => {
          console.log("Upload Stopped");
        });
      };
    });
  };
  return isFetching || isFetchingBarcode ? (
    <Loader />
  ) : (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={activeRounds || {}}
    >
      {({ values, dirty, isValid, setFieldValue }) => {
        const imagesUrl = values.pictures?.map(i => ({
          url: i.presignedUrl
        }));
        const dataToSend = {
          barcodeId: activeItem?.barcodeId,
          createdAt: activeItem?.createdAt,
          id: activeItem?.id,
          name: activeItem?.name,
          unitToCheckPerRound: activeItem?.unitToCheckPerRound,
          qcRounds: activeItem?.qcRounds.map(item =>
            item.roundNumber === activeRounds?.roundNumber ? values : item
          ),
          rounds: activeItem?.rounds
        };
        return (
          <div className={styles.wrapper}>
            <div className={styles.deleteBtnContainer}>
              <Button
                className={styles.deleteBtn}
                content="Delete All"
                onClick={() => {
                  setFieldValue("pictures", []);
                }}
              />
            </div>
            <Form>
              <HarvestUnitsImageModal
                open={imageModalOpen}
                blockName={blockName}
                urlList={urlList || []}
                onClose={() => {
                  return setimageModalOpen(false);
                }}
              />
              <Form.Group className={styles.imageSegment}>
                {imagesUrl?.map((slide, slideIndex) => (
                  <div style={style.dotsContainerStyles}>
                    <div
                      style={style.dotStyle}
                      key={slideIndex}
                      onClick={() => { }}
                    >
                      <Menu compact>
                        <Menu.Item style={style.imageIconContainer} as="a">
                          <Button
                            className={styles.imageButton}
                            onClick={event => {
                              return (
                                setUrlList(imagesUrl),
                                setblockName(header),
                                setimageModalOpen(true)
                              );
                            }}
                          >
                            <img style={style.image} src={slide.url} />
                          </Button>
                          <Label
                            onClick={() => {
                              return onDeleteUrl(
                                setFieldValue,
                                values.pictures,
                                slideIndex
                              );
                            }}
                            style={style.iconContainer}
                            floating
                          >
                            <Icon
                              size="small"
                              color="white"
                              name="close"
                              style={style.closeIcon}
                            />
                          </Label>
                        </Menu.Item>
                      </Menu>
                    </div>
                  </div>
                ))}
              </Form.Group>
              <FileChooser
                forImage={true}
                className={styles.generalAttachmentsButton}
                onChange={onAttachmentSelected(setFieldValue, values)}
                label={
                  <div className={styles.addImageContainer}>
                    <Icon color="#00A9B5" size="small" name="plus" />
                  </div>
                }
              />
              <div className={styles.btnWrapper}>
                <Button
                  content="Cancel"
                  type='text'
                  onClick={() => {
                    return setCheckEdit(false), setTest(!test);
                  }}
                />
                <Button
                  content={"Save"}
                  primary
                  onClick={() => {
                    return onSubmit(dataToSend), setCheckEdit(false);
                  }}
                  disabled={!isValid || !dirty}
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

BinPicture.propTypes = {
  activeItem: PropTypes.object
};

export default connect((state, props) => {
  return {
    isFetching: state.qualityControl.data.isFetching,
    isFetchingBarcode: state.generalBarcodes.isFetching
  };
})(BinPicture);
