import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import { matterToUnits } from "./constToUnits";
import { Icon, Popup, Grid } from "semantic-ui-react";
import moment from "moment";
import { ROLE_COLORS } from "../constants/InventoryMovements/index";
import { getNameInitials } from "../routes/Labour/utils";
import { matterOptionsSelector } from "selectors/chemicals";
import {
  operationTypeOptionSelector,
  productFilterOptions,
  activeIngredientOptionssSelector,
  blocListOptionsSelector,
  patchListOptionSelector,
  chemicalTypeOptionsSelector,
  shedListOptionSelector
} from "selectors/inventoryMovements";
import {
  fetchActiveIngredients,
  fetchFilterValues
} from "../actions/Chemicals";
import styles from "../routes/Chemicals/InventoryMovements/InventoryMovements.module.css";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { get } from "lodash";
import { Link } from "react-router-dom";
import Numeric from "components/Numeric/Numeric";
import { isEmptyValue } from "./tasksUtils";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";

export const getInventoryMovementsTableColumns = (
  chemicalsList,
  shedList,
  employeesList,
  areas,
  productList,
  purchaseList,
  filters,
  isFetching
) => {
  const columnsConfig = {
    id: {
      title: "ID",
      filterId: "id",
      withSort: false,
      cellWidth: 1.2, // of 16, see Semantic Table
      accessor: ({ id }) => {
        return `IM-${id}`;
      },
      filterId: "activeIngredient",
      filter: {
        title: "Active ingredient",
        type: filterTypes.MultiSelect,
        selector: activeIngredientOptionssSelector,
        withAsyncSearch: true,
        loaderAction: fetchActiveIngredients
      }
    },
    date: {
      title: "Date",
      filterId: "date",
      type: "date",
      withSort: true,
      accessor: ({ createdAt }) => {
        return (
          createdAt && moment.utc(createdAt.toString()).format("DD/MM/YYYY")
        );
      },
      filter: {
        title: "Date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching} />
      },
      cellWidth: 2 // of 16, see Semantic Table
    },
    chemicalIds: {
      title: "Product",
      filterId: "chemicalIds",
      withSort: false,
      accessor: ({ chemicalId }) => {
        const product = chemicalsList.find(p => p.key === chemicalId);
        return product ? (
          <div className={styles.productWrapper}>
            {get(product, "text")}
            <Popup
              position="right center"
              className={styles.popup}
              trigger={
                <Icon className={styles.infoButton} name="info circle" />
              }
              content={productList.map(product => {
                return (
                  chemicalId === product.id && (
                    <div className={styles.messageContainer}>
                      <span>Product type: {product.type || "-"}</span>
                      <span>
                        Matter:{" "}
                        {product.matter
                          .toLowerCase()
                          .charAt(0)
                          .toUpperCase() +
                          product.matter.toLowerCase().slice(1) || "-"}
                      </span>
                      <span>
                        Active ingredient: {product.activeIngredient || "-"}
                      </span>
                    </div>
                  )
                );
              })}
              size="small"
              inverted
            />
          </div>
        ) : (
          "-"
        );
      },

      cellWidth: 2,
      filter: {
        title: "Product",
        type: filterTypes.MultiSelect,
        selector: productFilterOptions
      }
    },
    employeeId: {
      title: "User",
      filterId: "matter",
      withSort: false,
      cellWidth: 1,
      accessor: ({ employeeId }) => {
        const employee = employeesList.find(e => e.key == employeeId);
        let initials =
          employee &&
          employee.firstName &&
          employee.lastName &&
          employee.firstName[0]?.substring(0, 1).toUpperCase() +
            employee.lastName[0]?.substring(0, 1).toUpperCase();
        return employee && employee.firstName && employee.lastName ? (
          <Popup
            position="right center"
            trigger={
              employee?.picture == null ? (
                <div
                  className={styles.avatarsContainer}
                  style={{
                    background: ROLE_COLORS[employee.type]
                      ? ROLE_COLORS[employee.type]
                      : "red"
                  }}
                >
                  {initials}
                </div>
              ) : (
                <span
                  className={styles.userAvatarsContainer}
                  style={{
                    backgroundImage: `url(${employee?.picture?.presignedUrl})`
                  }}
                ></span>
              )
            }
            content={
              <div>
                {}
                {isEmptyValue(employee?.firstName)} {employee?.lastName || ""}
              </div>
            }
            size="small"
            inverted
          />
        ) : employee?.picture == null ? (
          <div className={styles.avatarsContainer}>
            <Icon className={styles.avatarsIcon} name="user" />
          </div>
        ) : (
          <span
            className={styles.userAvatarsContainer}
            style={{
              backgroundImage: `url(${employee?.picture?.presignedUrl})`
            }}
          ></span>
        );
      },
      filter: {
        title: "Matter",
        type: filterTypes.SingleSelect,
        selector: matterOptionsSelector
      }
    },

    activityType: {
      title: "Activity type",
      filterId: "operationTypes",
      withSort: false,
      accessor: ({ operationType, entityId, reason }) => {
        switch (operationType) {
          case "SHED_TRANSFER":
            return (
              (
                operationType
                  .toLowerCase()
                  .charAt(0)
                  .toUpperCase() + operationType.toLowerCase().slice(1)
              ).replace(/_/g, " ") || "-"
            );
          case "STOCK_CORRECTION":
            return (
              (
                operationType
                  .toLowerCase()
                  .charAt(0)
                  .toUpperCase() + operationType.toLowerCase().slice(1)
              ).replace(/_/g, " ") || "-"
            );
          case "STOCK_ADDITION":
            return (
              (
                operationType
                  .toLowerCase()
                  .charAt(0)
                  .toUpperCase() + operationType.toLowerCase().slice(1)
              ).replace(/_/g, " ") || "-"
            );
          case "STOCK_DELETION":
            return (
              (
                operationType
                  .toLowerCase()
                  .charAt(0)
                  .toUpperCase() + operationType.toLowerCase().slice(1)
              ).replace(/_/g, " ") || "-"
            );
          case "OTHER":
            return (
              <Popup
                trigger={
                  <div className={styles.reasonContainer}>
                    {(
                      operationType
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() + operationType.toLowerCase().slice(1)
                    ).replace(/_/g, " ") || "-"}
                    <Grid.Column className={styles.reasonMessages}>
                      {reason}
                    </Grid.Column>
                  </div>
                }
                content={
                  <div className={styles.reasonContainer}> {reason}</div>
                }
                inverted
                position="top left "
                disabled={!reason}
              />
            );

          case "TASK_CONSUMPTION":
            return (
              <div className={styles.linkWrapper}>
                {(
                  operationType
                    .toLowerCase()
                    .charAt(0)
                    .toUpperCase() + operationType.toLowerCase().slice(1)
                ).replace(/_/g, " ") || "-"}
                <Link
                  to={`/spray_diary/id=${entityId}/${entityId}#sort=date,desc`}
                  target="_blank"
                >
                  AT-{entityId}
                </Link>
              </div>
            );
          case "TASK_CORRECTION":
            return (
              <div className={styles.linkWrapper}>
                {(
                  operationType
                    .toLowerCase()
                    .charAt(0)
                    .toUpperCase() + operationType.toLowerCase().slice(1)
                ).replace(/_/g, " ") || "-"}
                <Link
                  to={`/spray_diary/id=${entityId}/${entityId}#sort=date,desc`}
                  target="_blank"
                >
                  AT-{entityId}
                </Link>
              </div>
            );
          case "PURCHASE_ORDER_ARRIVAL":
            return (
              <div className={styles.linkWrapper}>
                {(
                  operationType
                    .toLowerCase()
                    .charAt(0)
                    .toUpperCase() + operationType.toLowerCase().slice(1)
                ).replace(/_/g, " ") || "-"}
                <Link
                  to={`/chemicals/purchaseHistory/#purchaseOrderId=${entityId}`}
                  target="_blank"
                >
                  {purchaseList.find(({ id }) => id == entityId)?.orderNumber}
                </Link>
              </div>
            );
          case "PURCHASE_ORDER_CORRECTION":
            return (
              <div className={styles.linkWrapper}>
                {(
                  operationType
                    .toLowerCase()
                    .charAt(0)
                    .toUpperCase() + operationType.toLowerCase().slice(1)
                ).replace(/_/g, " ") || "-"}
                <Link
                  to={`/chemicals/purchaseHistory/#purchaseOrderId=${entityId}`}
                  target="_blank"
                >
                  {purchaseList.find(({ id }) => id == entityId)?.orderNumber}
                </Link>
              </div>
            );
          /*      default:
            return "/"; */
        }
        return operationType;
      },
      cellWidth: 3, // of 16, see Semantic Table
      filter: {
        title: "Activity type",
        type: filterTypes.MultiSelect,
        selector: operationTypeOptionSelector
      }
    },

    block: {
      filterId: "blockIds",
      withSort: false,
      title: "Block/Patch",
      accessor: ({ areaId, operationType }) => {
        const area = areas.content.find(area => area.id === areaId);
        return area && area.parent
          ? `${area.parent.name}/${area.name}`
          : area
          ? area.name
          : "—";
      },
      cellWidth: 1.5, // of 16, see Semantic Table
      filter: {
        title: "Block",
        type: filterTypes.MultiSelect,
        selector: blocListOptionsSelector
      }
    },
    price: {
      filterId: "patchIds",
      withSort: false,
      cellWidth: 1.7, // of 16, see Semantic Table
      title: "Price ($/unit)",
      accessor: ({ price, chemicalId }) => {
        return (
          <>
            <Numeric
              fractionDigits={price > 1000 ? 0 : 2}
              value={price ? price : 0}
              commaSeparatorOnThousands
              units="$ "
              unitsPosition="left"
            />
            /
            {matterToUnits(
              get(
                chemicalsList.find(p => p.key === chemicalId),
                "matter"
              )
            )}
          </>
        );
      },
      filter: {
        title: "Patch",
        type: filterTypes.MultiSelect,
        selector: patchListOptionSelector
      }
    },
    shedIds: {
      title: "Shed",
      filterId: "shedIds",
      withSort: false,
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ shedId }) => {
        return get(
          shedList.options.find(s => s.key === shedId),
          "label"
        );
      },
      filter: {
        type: filterTypes.MultiSelect,
        selector: shedListOptionSelector
      }
    },
    difference: {
      withSort: true,
      type: "number",
      filterId: "chemicalTypeIds",
      title: "Difference",
      cellWidth: 1.6, // of 16, see Semantic Table
      accessor: ({ quantity, chemicalId }) => {
        const quantityWithoutSign = Math.abs(quantity);
        return (
          <>
            {quantity > 0 ? "+ " : "- "}
            <Numeric
              value={quantityWithoutSign}
              fractionDigits={quantityWithoutSign > 1000 ? 0 : 2}
              commaSeparatorOnThousands
              units={matterToUnits(
                get(
                  chemicalsList.find(p => p.key === chemicalId),
                  "matter"
                )
              )}
              unitsPosition="right"
            />
          </>
        );
      },
      filter: {
        title: "Product type",
        type: filterTypes.MultiSelect,
        selector: chemicalTypeOptionsSelector,
        loaderAction: fetchFilterValues
      }
    },
    empty: {
      title: "",
      filterId: "seasons",
      cellWidth: 1,
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector
      }
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};
