import React, { useState } from "react";
import {
  Button,
  Icon,
  Modal,
  Header,
  List,
  Container
} from "semantic-ui-react";
import styles from "../Sheds.module.css";

const ShedActionButtons = ({
  archiveButtonTitle,
  archiveButtonIcon,
  onArchiveButtonClick,
  withModal,
  selectedProducts,
  setOpenMessages
}) => {
  const [isArchiveConfirmShown, setArchiveConfirmShown] = useState(false);
  const isListOfProducts = Array.isArray(selectedProducts);
  const productCheck = withModal
    ? isListOfProducts
      ? selectedProducts.some(item => item.buckets.length > 0)
      : !!selectedProducts.buckets.length
    : false;

  return (
    <>
      <Modal
        onClose={() => setArchiveConfirmShown(false)}
        open={isArchiveConfirmShown}
        size="mini"
      >
        <Modal.Content>
          <Header size="big" className="bold">
            Archive the{" "}
            {isListOfProducts
              ? "following sheds"
              : `${selectedProducts.name} shed`}
            ?
          </Header>
          {isListOfProducts && (
            <List as="ol">
              {selectedProducts.map(product => (
                <List.Item as="li">{product.name}</List.Item>
              ))}
            </List>
          )}

        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Cancel"
            type="text"
            onClick={() => setArchiveConfirmShown(false)}
          />
          <Button
            content="Archive"
            onClick={onArchiveButtonClick}
            primary
          />
        </Modal.Actions>
      </Modal>

      <Button
        size="small"
        onClick={() =>
          productCheck
            ? setOpenMessages(true)
            : withModal
              ? setArchiveConfirmShown(true)
              : onArchiveButtonClick()
        }
        primary
      >
        <Icon name={archiveButtonIcon} />
        {archiveButtonTitle}
      </Button>
    </>
  );
};

export default ShedActionButtons;
