import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { Icon, Button } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import { history } from "../../../../src/store";
import styles from "./PlantingReport.module.css";
import _, { isArray } from "lodash";
import { selectTableStructuredData } from "selectors/reports/plantingReport";
import moment from "moment";
import filterTypes from "components/Table/filterTypes";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { Chip } from "../Harvest/components/Chip";
import Numeric from "components/Numeric/Numeric";
import { setDateToState } from "actions/Reports/harvest/PlantingReport/plantingReport";
import { farmsOptionsFilterSelector } from "selectors/farms";
import { cropsOptionSelector } from "selectors/areas";
import { excelExport } from "utils/excelExport";

const headerColumn = [
  "Variety",
  "Areas",
  "Planting date",
  "Date of Picking",
  "Area Size",
];

const PlantingReport = ({ location, route, isFetching, navigate }) => {
  const [filters, setFilters] = useState({
    from: "",
    cropIds: [],
    farmIds: [],
  });
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(false);
  const actualDate = !filters.from
    ? moment(new Date()).format("YYYY-MM-DD")
    : moment(filters.from).format("YYYY-MM-DD");
  useEffect(() => {
    dispatch(setDateToState(filters));
  }, [filters]);

  const tableData = useSelector(selectTableStructuredData);
  const columnsConfig = useMemo(() => {
    return [
      {
        accessor: "variety",
        id: "expander",
        Header: "Variety",
        withSort: false,
        cellWidth: 4,
        Footer: <div className={styles.totalRow}>Vacant</div>,
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 1.5}rem`,
                  display: "flex",
                  width: "100%",
                  height: "60px",
                  alignItems: "center",
                },
              })}
            >
              <Icon
                onClick={() => setExpandedRow(row.id)}
                name={`angle ${row.isExpanded ? "up" : "down"}`}
              />
              {value}
            </span>
          ) : row.depth == 1 ? (
            <span style={{ paddingLeft: `${3}rem` }}>{value}</span>
          ) : (
            <span style={{ paddingLeft: `${1.5}rem` }}>{value}</span>
          );
        },
        filter: {
          title: "Date",
          type: filterTypes.DateRangeSelect,
          selector: <DateRangeSelect loading={isFetching} />,
        },
        minWidth: 280,
      },
      {
        Header: "Areas",
        id: "block",
        withSort: false,
        accessor: "block",
        cellWidth: 3,
        Cell: ({ value }) =>
          value ? (
            Array.isArray(value) && value.length > 1 ? (
              <Chip items={value} label="areas" />
            ) : (
              <span style={{ paddingLeft: "10px" }}>{value}</span>
            )
          ) : (
            "-"
          ),
        filterId: "farmIds",
        filter: {
          title: "Farm",
          type: filterTypes.MultiSelect,
          selector: farmsOptionsFilterSelector,
        },
        Footer: (info) => {
          return (
            <div className={styles.totalRow}>
              <span style={{ paddingLeft: "10px" }}>
                {!!info.initialRows.length &&
                  info.initialRows[0].original.vacantLength}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Planting date",
        id: "plantingDate",
        withSort: false,
        accessor: "plantingDate",
        cellWidth: 3,
        filterId: "cropIds",
        Cell: ({ value }) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
        filter: {
          title: "Crop",
          type: filterTypes.MultiSelect,
          selector: cropsOptionSelector,
        },
      },
      {
        accessor: "pickingDate",
        Header: "Date of Picking",
        id: "pickingDate",
        cellWidth: 3,
        withSort: false,
        Cell: ({ value, row }) =>
          value ? moment(value).format("DD/MM/YYYY") : "-",
      },
      {
        accessor: "areaSize",
        Header: "Area Size",
        id: "areaSize",
        cellWidth: 3,
        withSort: false,
        Cell: ({ value, row }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
      },
    ];
  }, [filters]);

  const handleBackClick = () => {
    navigate({
      pathname: "/reports",
      state: {
        activeNode: location?.state?.reportNode,
      },
    });
  };
  const handleExcelExport = useCallback(
    async (data) => {
      const newTableData = data
        .map((item) => {
          return item?.subRows
            ? item.subRows.map((i) => ({
                ...i,
                variety: item.variety,
              }))
            : item;
        })
        .flat();

      const newData = newTableData.map((i, indx) =>
        indx < newTableData.length - 1
          ? {
              variety: i.variety || " ",
              areas: isArray(i.block) ? `${i.block.length}` : i.block,
              pickingDate: i.pickingDate,
              plantingDate: i.plantingDate,
              areaSize: i.areaSize,
            }
          : {
              variety: "Vacant",
              areas: i.vacantLength,
              pickingDate: "",
              plantingDate: "",
              areaSize: "",
            }
      );

      if (newData.length) {
        const excelData = newData.map(
          ({ variety, areas, pickingDate, plantingDate, areaSize }) => {
            return {
              ...{ variety: variety },
              ...{ areas: areas },
              ...{
                plantingDate: plantingDate
                  ? moment(plantingDate).format("DD/MM/YYYY")
                  : "-",
              },
              ...{
                pickingDate: pickingDate
                  ? moment(pickingDate).format("DD/MM/YYYY")
                  : "-",
              },
              ...{
                areaSize: areaSize
                  ? `${areaSize.toFixed(areaSize > 1000 ? 0 : 2)}`
                  : "0.00",
              },
            };
          }
        );
        excelExport(excelData, headerColumn);
      }
    },
    [filters]
  );

  return (
    <TablePageHolder
      navigate={navigate}
      backButton={
        <Button
          floated="right"
          className={styles.backIconWrapper}
          onClick={handleBackClick}
        >
          <Icon className={styles.backIcon} name="angle left" />
        </Button>
      }
      actionsButtons={
        <Button
        primary
          onClick={() => {
            return handleExcelExport(tableData);
          }}
        >
          <div className={styles.iconWrapper}>
            <Icon name="download" />
            Export
          </div>
        </Button>
      }
      rowId={expandedRow}
      dateType={"date"}
      defaultDate={actualDate}
      isFetching={tableData.isFetching}
      location={location}
      onRowClick={() => {}}
      open={open}
      setOpen={setOpen}
      withoutSearch={true}
      withPagination={true}
      setFilters={setFilters}
      pageTitle={route.name}
      tableType="report"
      route={route}
      sectionModal={true}
      tableColumns={columnsConfig}
      withSelection={false}
      tableData={tableData}
      withoutSort={false}
      singleLine
      withBorder={true}
      firstColumnFixed={true}
      fixed={false}
    />
  );
};

PlantingReport.propTypes = {
  route: PropTypes.object,
  location: PropTypes.object,
};

export default connect((state, props) => {
  return {
    isFetching: state.packedBoxes.data.isFetching,
    error: state.packedBoxes.error,
  };
})(PlantingReport);
