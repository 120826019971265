import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import filterTypes from "components/Table/filterTypes";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, Button, Popup } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import { selectTableStructuredData } from "selectors/reports/shed/productReport";
import {
  chemicalsOptionsFilterSelector,
  chemicalTypeOptionsSelector,
  typeOptionsSelector
} from "selectors/chemicals";
import {
  statusOptions,
  idsOptionsSelector
} from "selectors/reports/shed/productReport";
import { farmsOptionsFilterSelector } from "selectors/farms";
import {
  fetchProductUsageData,
  fetchProductUsageDataList
} from "actions/Reports/shedsReport/productReport";
import { blocListSelector, patchListSelector } from "selectors/areas";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { history } from "../../../../store";
import styles from "./ProductUsageTable.module.css";
import { useReactToPrint } from "react-to-print";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
import { getTasksIdsList } from "actions/Spray/tasks";
import moment from "moment-timezone";
import { excelExport } from "utils/excelExport";
const headerColumn = [
  "Product",
  "Matter",
  "Task ID",
  "Task name",
  "Planned Qty (L/Kg)",
  "Actual Qty (L/Kg)",
  "Difference",
  "Total cost"
];
const ProductUsage = ({ location, route, isFetching, navigate }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    type: [],
    matter: "",
    activeIngredient: [],
    blockIds: [],
    patchIds: [],
    chemicalIds: [],
    farmIds: [],
    seasons: [],
    from: "",
    to: ""
  });
  const farmsList = useSelector(farmsOptionsFilterSelector);
  const typeList = useSelector(chemicalTypeOptionsSelector);
  const [open, setOpen] = useState(false);
  const [filtersList, setFiltersList] = useState([]);
  const [expandedRow, setExpandedRow] = useState(false);
  const chemicalsList = useSelector(state => state.chemical.list.content);
  const blockList = useSelector(blocListSelector);
  const patchList = useSelector(patchListSelector);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const getData = useCallback(
    params => {
      setFiltersList(params);
      dispatch(fetchProductUsageData(params));
    },
    [fetchProductUsageData]
  );

  const tableData = useSelector(selectTableStructuredData);
  useEffect(() => {
    return setExpandedRow(false);
  }, [tableData.number]);

  const handleExcelExport = useCallback(
    async (expandedRow, rowFilter) => {
      const data = await dispatch(fetchProductUsageDataList(filtersList));
      const { content } = data;

      if (content.length) {
        const expanded = expandedRow
          ? content.filter((item, index) => index == expandedRow)
          : content;
        const data = expanded
          .map(({ items }) => items)
          .flat()
          .map(
            ({
              chemicalName,
              matter,
              sprayTaskId,
              sprayTaskName,
              plannedQty,
              actualQty,
              diff,
              cost
            }) => ({
              ...{ chemicalName: chemicalName || "-" },
              ...{ matter: matter || "-" },
              ...{ sprayTaskId: `AT-${sprayTaskId}` || "-" },
              ...{ sprayTaskName: sprayTaskName || "-" },
              ...{
                plannedQuantity:
                  +plannedQty.toFixed(plannedQty > 1000 ? 0 : 2) || 0.0
              },
              ...{
                actualQuantity:
                  +actualQty.toFixed(actualQty > 1000 ? 0 : 2) || 0.0
              },
              ...{
                difference: +diff.toFixed(diff > 1000 ? 0 : 2) || 0.0
              },
              ...{
                totalCost: +cost.toFixed(cost > 1000 ? 0 : 2) || 0.0
              }
            })
          );
        excelExport(data, headerColumn);
      }
    },
    [chemicalsList, filtersList]
  );
  const columnsConfig = useMemo(
    () => [
      {
        accessor: "name",
        id: "expander",
        Header: "Product",
        filterId: "date",
        withSort: false,
        cellWidth: 3.2,
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <span {...row.getToggleRowExpandedProps({})}>
              <Icon
                className={styles.chevronIcon}
                onClick={() => setExpandedRow(row.id)}
                name={`angle ${row.isExpanded ? "up" : "down"}`}
              />
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF"
                  }}
                >
                  {value || " - "}
                </div>
              )}
            </span>
          ) : row.depth == 1 ? (
            <span>
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF"
                  }}
                >
                  {value || "-"}
                </div>
              )}
            </span>
          ) : (
            <span>
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF"
                  }}
                >
                  {value || "-"}
                </div>
              )}
            </span>
          );
        },
        filter: {
          title: "Date",
          type: filterTypes.DateRangeSelect,
          selector: <DateRangeSelect loading={isFetching} />
        },
        minWidth: 280
      },
      {
        Header: "Matter",
        id: "matter",
        withSort: false,
        accessor: "matter",
        cellWidth: 3,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "seasons",
        filter: {
          title: "Season",
          type: filterTypes.SingleSelect,
          selector: seasonsOptionsSelector
        }
      },
      {
        Header: "Planned Qty (L/Kg)",
        id: "plannedQuantity",
        withSort: false,
        accessor: "plannedQuantity",
        cellWidth: 3,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "chemicalIds",
        filter: {
          title: "Product",
          type: filterTypes.MultiSelect,
          selector: chemicalsOptionsFilterSelector
        }
      },
      {
        Header: "",
        id: "actualQuantity",
        withSort: false,
        accessor: "actualQuantity",
        cellWidth: 3,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value}
            </div>
          ),
        filterId: "chemicalTypeIds",
        filter: {
          title: "Type",
          type: filterTypes.MultiSelect,
          selector: typeOptionsSelector
        }
      },
      {
        Header: "Total cost ($)",
        id: "totalCost",
        withSort: false,
        accessor: "totalCost",
        cellWidth: 3,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "sprayTaskStatuses",
        filter: {
          title: "Status",
          type: filterTypes.MultiSelect,
          selector: statusOptions
        }
      },
      {
        Header: "",
        id: "",
        accessor: "empty",
        cellWidth: 1,
        filterId: "sprayTaskIds",
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || ""
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || ""}
            </div>
          ),
        filter: {
          title: "Task ID",
          type: filterTypes.MultiSelect,
          selector: idsOptionsSelector,
          loaderAction: getTasksIdsList
        }
      }
    ],
    [filters]
  );

  const handleBackClick = () => {
    navigate({
      pathname: "/reports",
      state: {
        activeNode: location?.state?.reportNode
      }
    });
  };
  return (
    <>
      <TablePageHolder
        navigate={navigate}
        backButton={
          <Button
            floated="right"
            className={styles.backIconWrapper}
            onClick={handleBackClick}
          >
            <Icon className={styles.backIcon} name="angle left" />
          </Button>
        }
        actionsButtons={
          <>
            <Button
              primary
              onClick={() => {
                return setExpandedRow(false), handleExcelExport();
              }}
            >
              <div className={styles.iconWrapperForPage}>
                <Icon name="download" />
                Export
              </div>
            </Button>
          </>
        }
        rowId={expandedRow}
        withMoreButton={true}
        getData={getData}
        isFetching={tableData.isFetching}
        location={location}
        onRowClick={() => {}}
        open={open}
        setOpen={setOpen}
        setFilters={setFilters}
        pageTitle={route.name}
        tableType="report"
        route={route}
        tableColumns={columnsConfig}
        withSelection={false}
        tableData={tableData}
        withoutSort={false}
        firstColumnFixed={false}
        singleLine
        fixed={false}
        withTable={true}
        withHeader={true}
      />
    </>
  );
};

ProductUsage.propTypes = {
  Can: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getTasksIdsList
    },
    dispatch
  )
});

/* 
export default connect(mapStateToProps, mapDispatchToProps)(ProductUsage); */
export default connect((state, props) => {
  return {
    filterValues: state.packedBoxes.filters,
    productsList: state.chemical.list.content,
    isFetching: state.packedBoxes.data.isFetching,
    error: state.packedBoxes.error
  };
}, mapDispatchToProps)(ProductUsage);
