import React from "react";
import PropTypes from "prop-types";
import { Grid, Popup, Button, Icon, Header } from "semantic-ui-react";
import { Formik } from "formik";
import GeneralInfo from "../GeneralInfo";
import TabsAdd from "../../AddSideBar/Tabs/TabsAdd";
import styles from "../../SideBar.module.css";

const RoundsAdd = ({
  activeItem,
  setOpenAddSideBar,
  setOpenWorkerAdd,
  setOpenParametrAdd,
  setOpenSecondSideBar,
  onClose,
  activeRoundsNumber,
  openParametrAdd,
  openWorkerAdd,
  onSubmit
}) => {
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={
        activeItem
          ? {
              ...activeItem,
              qcRounds: [
                ...activeItem?.qcRounds,
                {
                  roundNumber: activeItem?.qcRounds.length + 1,
                  defectReports: [],
                  employeeResults: [],
                  pictures: []
                }
              ]
            }
          : {}
      }
    >
      {({ values, setFieldValue }) => {
        const dataToSend = {
          barcodeId: values?.barcodeId,
          createdAt: values?.createdAt,
          rounds: values?.rounds,
          id: values?.id,
          name: values?.name,
          unitToCheckPerRound: values?.unitToCheckPerRound,
          qcRounds: values.qcRounds
        };
        const validation =
          !values.name ||
          !values.barcodeId ||
          !values.rounds ||
          !values.unitToCheckPerRound ||
          !values.qcRounds.every(item => {
            return item.defectReports.filter(i => i.parameter).length;
          });
        return (
          <>
            <Grid className={styles.sidebarHeader}>
              <Grid.Row columns={"equal"} verticalAlign="middle">
                <Grid.Column className={styles.subSideBarheader}>
                  <Popup
                    inverted
                    position="bottom center"
                    content={"Back"}
                    size="tiny"
                    trigger={
                      <Button
                        onClick={() => (
                          setOpenAddSideBar(false),
                          setOpenWorkerAdd(false),
                          setOpenParametrAdd(false),
                          setOpenSecondSideBar(false)
                        )}
                        className={styles.backButton}
                        floated="right"
                        icon={<Icon className="angle left" />}
                        size="small"
                      />
                    }
                  />
                  <Header className={styles.roundsName} as="h1">
                    {`Round ${activeItem?.qcRounds.length + 1}`}
                  </Header>
                </Grid.Column>
                <Grid.Column width={1} floated="right">
                  <Popup
                    inverted
                    position="bottom center"
                    content={"Close"}
                    size="tiny"
                    trigger={
                      <Button
                        onClick={onClose}
                        className={styles.closeButton}
                        floated="right"
                        icon={<Icon className="tuf-times" />}
                        size="small"
                      />
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <GeneralInfo activeItem={values} />
            <TabsAdd
              activeItem={values}
              activeRoundsNumber={activeRoundsNumber}
              setFieldValue={setFieldValue}
              setOpenParametrAdd={setOpenParametrAdd}
              openParametrAdd={openParametrAdd}
              setOpenWorkerAdd={setOpenWorkerAdd}
              openWorkerAdd={openWorkerAdd}
            />
            <div className={styles.buttonContainer}>
              <Button
                content="Cancel"
              type="text"
                onClick={() => {
                  return (
                    setOpenAddSideBar(false),
                    setOpenWorkerAdd(false),
                    setOpenParametrAdd(false),
                    setOpenSecondSideBar(false)
                  );
                }}
              />
              <Button
                content={"Save"}
                primary
                onClick={() => onSubmit(dataToSend)}
                disabled={validation}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

RoundsAdd.propTypes = {
  activeRounds: PropTypes.object
};

export default RoundsAdd;
