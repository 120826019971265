import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Confirm, Icon } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import styles from "./EditModal.module.css";
import moment from "moment";
import WorkingTimeRow from "./WorkingTimeRow";
import {
  updateWorkingTime,
  deleteGeneralTaskWorkingTime,
  updateGeneralTask,
  fetchGeneralTasksWorkingTimes,
  createWorkingTime
} from "actions/Tasks";

const EditModal = ({
  activeArea,
  activeWorkingTime,
  tableData,
  tableType,
  open,
  onClose,
  task,
  filtredArea,
  employeeList,
  archivedList
}) => {
  const formikRef = useRef(null);
  const [errorChecker, setErrorChecker] = useState(null);
  const [secondOpen, setSecondOpen] = useState(false);
  const [openSections, setOpenSections] = useState({});
  const [error, setError] = useState(null);
  const [initialWtData, setInitialWtData] = useState([]);
  const dispatch = useDispatch();

  const handleTaskSubmit = () => {
    dispatch(updateGeneralTask(task.id, task));
    dispatch(
      fetchGeneralTasksWorkingTimes({
        id: task.id,
        filters: { taskId: task.id }
      })
    );
  };

  const handleSubmit = async (changes, deletions) => {
    const updatePromises = changes.map(async item => {
      return item.id
        ? dispatch(updateWorkingTime(item.id, item))
        : dispatch(createWorkingTime({ formData: item }));
    });

    const deletePromises = deletions.map(async item => {
      return dispatch(deleteGeneralTaskWorkingTime(task.id, item.id));
    });

    const results = await Promise.all([...updatePromises, ...deletePromises]);

    const allSuccessful = results.every(
      result => result.status >= 200 && result.status < 400
    );

    if (allSuccessful) {
      handleTaskSubmit();
      setErrorChecker(null);
    } else {
      console.error("One or more requests failed");
    }
    onClose();
  };

  useEffect(() => {
    if (open) {
      // Store the initial state of wtDataToSend
      const initialValues = getAreaInitialValues();
      setInitialWtData(getWtDataToSend(initialValues));
    }
  }, [open]);

  const toggleSection = (areaIndex, index) => {
    const sectionKey = `${areaIndex}-${index}`;
    setOpenSections(prevOpenSections => ({
      ...prevOpenSections,
      [sectionKey]: !prevOpenSections[sectionKey]
    }));
  };

  const isSectionOpen = (areaIndex, index) => {
    const sectionKey = `${areaIndex}-${index}`;
    return openSections[sectionKey] || false;
  };

  const uniqueById = activeWorkingTime
    .reduce((acc, current) => {
      if (!acc.some(item => item.employeeId === current.employeeId)) {
        acc.push(current);
      }
      return acc;
    }, [])
    .map(awt =>
      awt.employee
        ? awt
        : {
            ...awt,
            employee: [...archivedList, ...employeeList].find(
              item => item.id === awt.employeeId
            )
          }
    );
  const getAreaInitialValues = () => ({
    blocks: filtredArea.map((filtredArea, inindex) => {
      return {
        areaName: filtredArea.name,
        assignee: uniqueById.map((a, assigneIndex) => {
          return {
            employee: a.employee,
            workingTime: activeWorkingTime
              .filter(
                wt =>
                  wt.employeeId === a.employeeId &&
                  wt.areas.some(item => item.areaId === filtredArea.id)
              )
              .map(item => {
                const dateTimeStartString = item.startAt && item.startAt;
                const dateTimeEndString = item.endAt && item.endAt;
                const startTimeOnly =
                  dateTimeStartString &&
                  moment(dateTimeStartString).format("HH:mm");
                const endTimeOnly =
                  dateTimeEndString &&
                  moment(dateTimeEndString).format("HH:mm");
                const employee = employeeList.find(
                  i => i.id === item.employeeId
                );
                return {
                  id: item.id,
                  employee: employee,
                  employeeId: item.employeeId,
                  machineryId: item.machineryId,
                  start: item.startAt,
                  end: item.endAt,
                  wtStart: startTimeOnly,
                  wtEnd: endTimeOnly,
                  price: item.pieceRate,
                  noOfPieces: item.piecesPerEmployee,
                  lunchInterval: item?.lunchInterval
                };
              })
          };
        })
      };
    })
  });

  const getWtDataToSend = values => {
    if (!values || !values.blocks) return [];
    const groupedArray = values.blocks.map((item, index) =>
      item.assignee?.map((assigne, assigneIndex) => {
        return assigne?.workingTime?.map(item => ({
          ...item,
          areas: [{ areaId: filtredArea[index]?.id }]
        }));
      })
    );

    return groupedArray.flat(3)?.map(item => {
      const endDate = item?.end
        ? `${moment(item?.end).format("YYYY-MM-DD")}T${item?.wtEnd ||
            "00:00"}:00Z`
        : null;
      const startDate = item?.start
        ? `${moment(item?.start).format("YYYY-MM-DD")}T${item?.wtStart ||
            "23:59"}:59Z`
        : null;
      return {
        id: item.id,
        areas: item?.areas,
        employeeId: item?.employeeId,
        endAt: endDate,
        generalTaskId: task.id,
        lunchInterval: item?.lunchInterval,
        machineryId: item?.machineryId,
        pieceRate: item?.price,
        piecesPerEmployee: item?.noOfPieces,
        startAt: startDate
      };
    });
  };

  const identifyChanges = newData => {
    const changes = newData.filter(newItem => {
      const originalItem = initialWtData.find(
        item => item.employeeId === newItem.employeeId
      );

      if (!originalItem) return true; // new item

      // Check if any field is different
      return (
        originalItem.endAt !== newItem.endAt ||
        originalItem.startAt !== newItem.startAt ||
        originalItem.lunchInterval !== newItem.lunchInterval ||
        originalItem.machineryId !== newItem.machineryId ||
        originalItem.pieceRate !== newItem.pieceRate ||
        originalItem.piecesPerEmployee !== newItem.piecesPerEmployee
      );
    });

    const deletions = initialWtData.filter(
      originalItem =>
        !newData.some(newItem => newItem.employeeId === originalItem.employeeId)
    );

    return { changes, deletions };
  };
  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
    >
      <Formik
        innerRef={formik => (formikRef.current = formik)}
        initialValues={getAreaInitialValues()}
      >
        {({ isValid, setFieldValue, values, dirty }) => {
          const wtDataToSend = getWtDataToSend(values);
          const { changes, deletions } = identifyChanges(wtDataToSend);
          return (
            <>
              <Modal.Header className={styles.modalHeader}>
                Edit{" "}
                {tableType === "ALL"
                  ? filtredArea[0].type === "BLOCK"
                    ? `Block ${filtredArea[0].name}`
                    : `Patch ${filtredArea[0].name}`
                  : filtredArea[0].type === "BLOCK"
                  ? `Block ${filtredArea[0].name}`
                  : `Block ${filtredArea[0].parent.name}`}
                <Icon
                  name="close"
                  className={styles.closeIcon}
                  onClick={() => {
                    return dirty
                      ? setSecondOpen(true)
                      : (onClose(), setOpenSections({}));
                  }}
                />
              </Modal.Header>
              <Modal.Content scrolling className={styles.modalContentContainer}>
                {
                  <WorkingTimeRow
                    errorChecker={errorChecker}
                    setError={setError}
                    error={error}
                    tableType={tableType}
                    employeeList={employeeList}
                    archivedList={archivedList}
                    formikRef={formikRef}
                    isSectionOpen={isSectionOpen}
                    toggleSection={toggleSection}
                    setOpenSections={setOpenSections}
                    setErrorChecker={setErrorChecker}
                  />
                }
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type="text"
                  onClick={() => {
                    return dirty
                      ? setSecondOpen(true)
                      : (onClose(), setOpenSections({}));
                  }}
                  className={styles.modalBtn}
                >
                  <Icon name="chevron left" />
                  Cancel
                </Button>
                <Button
                  content={"Save"}
                  primary
                  onClick={async () => {
                    handleSubmit(changes, deletions);
                  }}
                  disabled={
                    !!error ||
                    errorChecker ||
                    wtDataToSend.some(
                      item =>
                        !item.endAt ||
                        !item.startAt ||
                        !item.lunchInterval ||
                        !item.employeeId
                    )
                  }
                  className={styles.modalBtn}
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>

      <Confirm
        className={styles.confirmModal}
        content="Discard unsaved changes?"
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={() => onClose()}
      />
    </Modal>
  );
};

export default EditModal;
