import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import filterTypes from "components/Table/filterTypes";
import { connect, useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Icon, Button } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import Numeric from "components/Numeric";
import {
  selectExpotStructuredData,
  selectTableStructuredData,
  formaterData,
} from "../../../../../selectors/reports/harvest/pickTopack/harvestByVariety";
import { cropsOptionSelector, varietiesOptionSelector } from "selectors/areas";
import { boxUnitOptionsSelector } from "../../../../../selectors/boxUnits";
import {
  fetchHarvestByVarietyTableData,
  fetchHarvestByVarietyExportData,
} from "actions/Reports/harvest/pickToPack/harvestByVariety";
import { fetchBoxUnitsList } from "actions/BoxUnits/boxUnits";
import { fetchCropsList } from "actions/Farms/crops";
import { Chip } from "../../components/Chip";
import _, { get, pickBy, pick } from "lodash";
import PrintReports from "./PrintHarvestTable/PrintReports";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { excelExport } from "utils/excelExport";
import { history } from "../../../../../store";
import styles from "./HarvestByVariety.module.css";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
const headerColumn = {
  varietyName: "Variety",
  size: "Size (Ha)",
  binUnits: "Box units",
  binUnitsNumber: "No.units",
  weight: "Weight (T)",
  tonnePerHa: "Tonne/Ha (T/Ha)",
  cost: "Cost ($)",
  costPerTonne: "Cost/Tonne ($/T)",
  costPerBin: "Cost/Unit ($/Unit)",
  avgKgPerTree: "Avg. Kg/Tree",
};

const PickToPackHarvestByVariety = ({
  location,
  route,
  isFetching,
  navigate,
}) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    cropIds: [],
    varietyIds: [],
    blockIds: [],
    patchIds: [],
    harvestUnitIds: [],
    seasons: [],
    from: "",
    to: "",
  });
  const { content } = useSelector(selectExpotStructuredData);
  const varietiesList = useSelector((state) =>
    varietiesOptionSelector(filters)(state)
  );
  const cropsList = useSelector(cropsOptionSelector);
  const binUnitsList = useSelector(boxUnitOptionsSelector);
  const [filtersList, setFiltersList] = useState([]);

  const [selectItem, setSelectItem] = useState();
  const [printExpandedData, setPrintExpandedData] = useState(false);
  const [open, setOpen] = useState(false);
  const componentRef = useRef();
  const [excel, setExcel] = useState();
  const [expandedRow, setExpandedRow] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const areasList = useSelector((state) => state.areas.list.content);

  const getData = useCallback(
    (params) => {
      setFiltersList(params);
      dispatch(fetchHarvestByVarietyTableData(params));
    },
    [fetchHarvestByVarietyTableData]
  );
  const columns = [
    {
      name: "Variety",
      id: 1,
    },
    {
      name: "Size (Ha)",
      id: 2,
    },
    {
      name: "Box units",
      id: 3,
    },
    {
      name: "No.units",
      id: 4,
    },
    {
      name: "Weight (T)",
      id: 5,
    },
    {
      name: "Tonne/Ha (T/Ha)",
      id: 6,
    },
    {
      name: "Cost ($)",
      id: 7,
    },
    {
      name: "Cost/Tonne ($/T)",
      id: 8,
    },
    {
      name: "Cost/Unit ($/Unit)",
      id: 9,
    },
    {
      name: "Avg. Kg/Tree",
      id: 10,
    },
  ];

  const fetchPrintoutAllInformation = () => {
    return dispatch(fetchHarvestByVarietyExportData(filtersList));
  };

  const fetchExportAllInformation = () => {
    return dispatch(fetchHarvestByVarietyExportData(filtersList));
  };

  const handleExcelExport = useCallback(
    async (selectedItem) => {
      const data = await dispatch(fetchHarvestByVarietyExportData(filtersList));
      const { content } = formaterData(data, varietiesList, areasList);
      if (content.length) {
        const headerNewData = pickBy(headerColumn, function (value, key) {
          return selectedItem && selectedItem.find((item) => value === item);
        });
        const expanded = content
          .reduce((prev, block) => {
            return [
              ...prev,
              pick(block, Object.keys(headerNewData)),
              ...(block.subRows
                ? block.subRows.reduce(
                  (prev, { subRows, isSubRows, ...rest }) => {
                    const temp = [...prev];
                    temp.push({
                      ...pick(rest, Object.keys(headerNewData)),
                    });
                    return temp;
                  },
                  []
                )
                : []),
            ];
          }, [])
          .map(
            ({
              varietyName,
              size,
              binUnits,
              binUnitsNumber,
              weight,
              tonnePerHa,
              cost,
              costPerTonne,
              costPerBin,
              avgKgPerTree,
            }) => ({
              ...(varietyName && { varietyName }),
              ...(size && {
                size: isFinite(size)
                  ? parseFloat(size).toFixed(size > 1000 ? 0 : 2)
                  : "-",
              }),
              ...(binUnits &&
                binUnits.length && {
                binUnits:
                  binUnits.length > 1
                    ? `${binUnits.length} Types`
                    : binUnits[0],
              }),
              ...(binUnitsNumber && {
                binUnitsNumber: isFinite(binUnitsNumber) ? binUnitsNumber : "-",
              }),
              ...(weight && {
                weight: isFinite(weight)
                  ? parseFloat(weight).toFixed(weight > 1000 ? 0 : 2)
                  : "-",
              }),
              ...(tonnePerHa && {
                tonnePerHa: isFinite(tonnePerHa)
                  ? parseFloat(tonnePerHa).toFixed(tonnePerHa > 1000 ? 0 : 2)
                  : "-",
              }),
              ...(cost && {
                cost: isFinite(cost)
                  ? parseFloat(cost).toFixed(cost > 1000 ? 0 : 2)
                  : "-",
              }),
              ...(costPerTonne && {
                costPerTonne: isFinite(costPerTonne)
                  ? parseFloat(costPerTonne).toFixed(
                    costPerTonne > 1000 ? 0 : 2
                  )
                  : "-",
              }),
              ...(costPerBin && {
                costPerBin: isFinite(costPerBin)
                  ? parseFloat(costPerBin).toFixed(costPerBin > 1000 ? 0 : 2)
                  : "-",
              }),
              ...(avgKgPerTree && {
                avgKgPerTree: Number.isFinite(avgKgPerTree)
                  ? parseFloat(avgKgPerTree).toFixed(
                    avgKgPerTree > 1000 ? 0 : 2
                  )
                  : "-",
              }),
            })
          );
        excelExport(expanded, selectedItem);
      }
    },
    [areasList, filtersList]
  );

  const tableData = useSelector(selectTableStructuredData);
  const printData = useSelector(selectExpotStructuredData);
  useEffect(() => {
    return setExpandedRow(false);
  }, [tableData.number]);
  const columnsConfig = useMemo(
    () => [
      {
        accessor: "varietyName",
        id: "expander",
        filterId: "date",
        withSort: false,
        Header: "Variety",
        Footer: <div className={styles.totalRow}>Total</div>,
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 1.5}rem`,
                },
              })}
            >
              <Icon
                onClick={() => setExpandedRow(row.id)}
                name={`angle ${row.isExpanded ? "up" : "down"}`}
              />
              {value}
            </span>
          ) : (
            <span style={{ paddingLeft: `${1.5}rem` }}>{value}</span>
          );
        },
        filter: {
          title: "Date",
          type: filterTypes.DateRangeSelect,
          selector: <DateRangeSelect loading={isFetching} />,
        },
        minWidth: 280,
      },
      {
        Header: "Size (Ha)",
        id: "size",
        filterId: "harvestUnitIds",
        withSort: false,
        accessor: "size",
        filter: {
          title: "Box units",
          type: filterTypes.MultiSelect,
          selector: boxUnitOptionsSelector,
          loaderAction: fetchBoxUnitsList,
        },
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),

        Footer: (info) => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce(
                (sum, row) => (row.original.size || 0) + sum,
                0
              ),
            [info.rows]
          );

          return (
            <div className={styles.totalRow}>
              {" "}
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          );
        },
      },
      {
        Header: "Box units",
        withSort: false,
        filterId: "cropIds",
        id: "harvestUnitIds",

        accessor: "binUnits",
        Cell: ({ value }) =>
          value ? (
            Array.isArray(value) && value.length > 1 ? (
              <Chip items={value} label="Types" />
            ) : (
              value
            )
          ) : (
            "-"
          ),
        filter: {
          title: "Crop",
          type: filterTypes.MultiSelect,
          selector: cropsOptionSelector,
          loaderAction: fetchCropsList,
        },
      },
      {
        Header: "No. units",
        filterId: "varietyIds",
        id: "noUnits",
        withSort: true,
        type: "number",
        filter: {
          title: "Variety",
          type: filterTypes.MultiSelect,
          selector: (state) => varietiesOptionSelector(filters)(state),
        },
        accessor: "binUnitsNumber",
        Footer: (info) => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce(
                (sum, row) => row.original.binUnitsNumber + sum,
                0
              ),
            [info.rows]
          );

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={0}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          );
        },
      },
      {
        accessor: "weight",
        withSort: true,
        type: "number",
        id: "weight",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            defaultValue="-"
          />
        ),
        Header: "Weight (T)",
        filterId: "seasons",
        filter: {
          title: "Season",
          type: filterTypes.SingleSelect,
          selector: seasonsOptionsSelector,
        },
        Footer: (info) => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce(
                (sum, row) => row.original.weight + sum,
                0
              ),
            [info.rows]
          );

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          );
        },
      },
      {
        accessor: "tonnePerHa",
        withSort: true,
        type: "number",
        id: "tonnePerHa",
        Cell: ({ value }) =>
          value && isFinite(value) ? (
            <Numeric
              fractionDigits={value > 1000 ? 0 : 2}
              value={value}
              commaSeparatorOnThousands
              defaultValue="-"
            />
          ) : (
            "-"
          ),
        Header: "Tonne/Ha (T/Ha)",
        Footer: (info) => {
          const totalSize = info.sortedRows.reduce(
            (sum, row) => row.original.size + sum,
            0
          );
          const totalWeight = info.sortedRows.reduce(
            (sum, row) => row.original.weight + sum,
            0
          );
          const total = useMemo(() => totalWeight / totalSize);

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          );
        },
      },
      {
        accessor: "cost",
        withSort: true,
        type: "number",
        id: "cost",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            units="$"
            unitsPosition="left"
            defaultValue="-"
          />
        ),
        Header: "Cost ($)",
        Footer: (info) => {
          const total = useMemo(
            () =>
              info.sortedRows.reduce((sum, row) => row.original.cost + sum, 0),
            [info.rows]
          );

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                units="$"
                unitsPosition="left"
                defaultValue="-"
              />
            </div>
          );
        },
      },
      {
        accessor: "costPerTonne",
        id: "costPerTonne",
        withSort: true,
        type: "number",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            units="$"
            unitsPosition="left"
            defaultValue="-"
          />
        ),
        Header: "Cost/Tonne ($/T)",
        Footer: (info) => {
          const totalCost = info.sortedRows.reduce(
            (sum, row) => row.original.cost + sum,
            0
          );
          const totalWeight = info.sortedRows.reduce(
            (sum, row) => row.original.weight + sum,
            0
          );
          const total = useMemo(() => totalCost / totalWeight);

          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                units="$"
                unitsPosition="left"
                defaultValue="-"
              />
            </div>
          );
        },
      },
      {
        id: "costPerUnit",
        accessor: "costPerBin",
        withSort: true,
        type: "number",
        Cell: ({ value }) => (
          <Numeric
            fractionDigits={value > 1000 ? 0 : 2}
            value={value}
            commaSeparatorOnThousands
            units="$"
            unitsPosition="left"
            defaultValue="-"
          />
        ),
        Header: "Cost/Unit ($/Unit)",
        Footer: (info) => {
          const totalCost = info.sortedRows.reduce(
            (sum, row) => row.original.cost + sum,
            0
          );
          const totalUnit = info.sortedRows.reduce(
            (sum, row) => row.original.binUnitsNumber + sum,
            0
          );

          const total = useMemo(() => totalCost / totalUnit);
          return (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                units="$"
                unitsPosition="left"
                defaultValue="-"
              />
            </div>
          );
        },
      },
      {
        accessor: "avgKgPerTree",
        id: "weightPerTree",
        withSort: true,
        type: "number",
        Cell: ({ value }) =>
          value && isFinite(value) ? (
            <Numeric
              fractionDigits={value > 1000 ? 0 : 2}
              value={value}
              commaSeparatorOnThousands
              defaultValue="-"
            />
          ) : (
            "-"
          ),
        Header: "Avg.Kg/Tree",
        Footer: (info) => {
          const totalPlants = info.sortedRows.reduce(
            (sum, row) => row.original.numberOfPlants + sum,
            0
          );
          const totalWeight = info.sortedRows.reduce(
            (sum, row) => row.original.weight + sum,
            0
          );
          const total = useMemo(() => (totalWeight * 1000) / totalPlants);
          return total && isFinite(total) ? (
            <div className={styles.totalRow}>
              <Numeric
                fractionDigits={total > 1000 ? 0 : 2}
                value={total}
                commaSeparatorOnThousands
                defaultValue="-"
              />
            </div>
          ) : (
            "-"
          );
        },
      },
    ],
    [filters]
  );
  const printButton = (selectedItem) => {
    setSelectItem(selectedItem);
    fetchPrintoutAllInformation().then(() => {
      setPrintExpandedData(true);
      handlePrint();
    });
  };
  const handleBackClick = () => {
    navigate({
      pathname: "/reports",
      state: {
        activeNode: _.get(location, "state.reportNode"),
      },
    });
  };
  return (
    <>
      <div style={{ display: "none" }}>
        <PrintReports
          title="Harvest by Variety"
          showExpandedData={printExpandedData}
          cropsList={cropsList}
          varietiesList={varietiesList}
          binUnitsList={binUnitsList}
          cropIds={filters.cropIds}
          varietyIds={filters.varietyIds}
          binUnitsIds={filters.harvestUnitIds}
          tableData={content}
          selectedItem={selectItem}
          data={printData}
          from={filters.from}
          to={filters.to}
          ref={componentRef}
        />
      </div>
      <TablePageHolder
        navigate={navigate}
        backButton={
          <Button
            floated="right"
            className={styles.backIconWrapper}
            onClick={handleBackClick}
          >
            <Icon className={styles.backIcon} name="angle left" />
          </Button>
        }
        actionsButtons={
          <>
            <Button
              primary
              onClick={() => {
                return setExcel(true), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="download" />
                Export
              </div>
            </Button>
            <Button
              primary
              onClick={() => {
                return setExcel(false), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="print" />
                Print
              </div>
            </Button>
          </>
        }
        rowId={expandedRow}
        getData={getData}
        isFetching={tableData.isFetching}
        location={location}
        onRowClick={() => { }}
        open={open}
        setFilters={setFilters}
        printColumns={columns}
        setOpen={setOpen}
        handleExcelExport={handleExcelExport}
        printButton={printButton}
        printData={printData}
        excel={excel}
        pageTitle={route.name}
        route={route}
        tableColumns={columnsConfig}
        withSelection={false}
        withBorder={true}
        tableData={tableData}
        singleLine
        withoutSort={false}
        firstColumnFixed={true}
        fixed={false}
      />
    </>
  );
};

PickToPackHarvestByVariety.propTypes = {
  Can: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object,
};

export default connect((state, props) => {
  return {
    filterValues: state.packedBoxes.filters,
    isFetching: state.packedBoxes.data.isFetching,
    error: state.packedBoxes.error,
  };
})(PickToPackHarvestByVariety);
