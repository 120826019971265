import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Grid, Header, Button, Icon } from "semantic-ui-react";
import Attachments from "../../../components/Attachments";
import Numeric from "../../../components/Numeric";
import TaskRow from "../../SprayDiary/TaskDetailsMobile/TaskRow";
import { isEmptyValue } from "../../../utils/tasksUtils";
import styles from "./Sidebar.module.css";
import ShowMore from "../../../components/ShowMore";
import TrackerViewer from "./TrackerViewer";
import { Link } from "react-router-dom";
class MachinerySidebar extends PureComponent {
  render() {
    const { machinery, navigate,id, setActiveItem } = this.props;
    return machinery ? (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column className={styles.wrapper} width={16}>
              <Header as="h1" className={styles.name}>
                {machinery.name}
              </Header>
              <div className={styles.btnWrapper}>
                <Button
                  as={Link}
                  primary
                  to={`/machinery/add_new_machinery/${id}`}
                >
                  Edit
                </Button>
                <Icon
                  className={styles.closeIcon}
                  name="close"
                  onClick={() => setActiveItem(null)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <TaskRow
            label="Make"
            name="make"
            value={<span>{isEmptyValue(machinery.make)}</span>}
          />
          <TaskRow
            label="Model"
            name="model"
            value={<span>{isEmptyValue(machinery.model)}</span>}
          />
          <TaskRow
            label="Herbicide unit"
            name="herbicide"
            value={<span>{machinery.herbicide ? "Yes" : "No"}</span>}
          />
          <TaskRow
            label="Code"
            name="code"
            value={<span>{isEmptyValue(machinery.code)}</span>}
          />
          <TaskRow
            label="Size"
            name="size"
            value={
              <Numeric
                value={machinery.size}
                fractionDigits={0}
                units={machinery.sizeUnit === "KILOGRAM" ? "Kg" : "L"}
              />
            }
          />
          <TaskRow
            label="Rate per Hour"
            name="ratePerHour"
            value={
              <Numeric
                value={machinery.ratePerHour}
                fractionDigits={2}
                units="$"
                unitsPosition={"left"}
                needSpace={false}
              />
            }
          />
          {machinery.attachments ? (
            <TaskRow
              label="Attachments"
              name="attachments"
              value={
                <Attachments
                  attachments={machinery.attachments}
                  canEdit={false}
                />
              }
              wide
            />
          ) : null}
          <TaskRow
            label="Notes"
            name="notes"
            value={
              <span>
                <ShowMore length={120}>
                  {isEmptyValue(machinery.notes)}
                </ShowMore>
              </span>
            }
            wide
          />
          {machinery.trackerId && (
            <>
              <Header>
                Tracker <span>{machinery.trackerId}</span>
              </Header>
              <TrackerViewer navigate={navigate} machineryId={machinery.id} />
            </>
          )}
        </Grid>
      </>
    ) : (
      <div>No Data</div>
    );
  }
}

MachinerySidebar.propTypes = {
  machinery: PropTypes.object,
};

export default MachinerySidebar;
