import React from "react";
import ReactDOMServer from "react-dom/server";
import _ from "lodash";
import moment from "moment";

import rateTypes from "../CreateSprayTask/SprayForm/SelectChemicals/rateTypes";

import styles from "./printPageStyles";
import Numeric from "../../../components/Numeric";
import { matterToUnits } from "../../../utils/constToUnits";
import { calcTotalHectare } from "utils/tasksUtils";
import { calculateChemicalQuantity } from "utils/chemicalBreakdownUtils";
import Logo from "components/Logo";

const windStrength = [
  "Calm",
  "Light Air",
  "Light Breeze",
  "Gentle Breeze",
  "Moderation Breeze",
  "Fresh Breeze"
];
const multiplier = item => {
  const foundElement = [
    { id: "gram", matter: "SOLID", multiplicationFactor: 0.001 },
    { id: "kilogram", matter: "SOLID", multiplicationFactor: 1 },
    { id: "liter", matter: "LIQUID", multiplicationFactor: 1 },
    { id: "milliliter", matter: "LIQUID", multiplicationFactor: 0.001 }
  ].find(({ id }) => id === item);

  if (foundElement) {
    return 1 / foundElement.multiplicationFactor;
  }
  return 1;
};

const getName = user => {
  if (_.isEmpty(user)) {
    return "—";
  }
  return _.get(user, "firstName") + " " + _.get(user, "lastName");
};

const getDate = date => {
  return moment(date).format("DD/MM/YYYY");
};

const completed = "COMPLETED";
const calculateChemicalTotal = (
  item,
  selectedAreas,
  rowsToSpray,
  widthPerRow,
  literPerHectare,
  conc
) => {
  const totalHectares = calcTotalHectare(selectedAreas);
  return calculateChemicalQuantity(
    item,
    totalHectares,
    rowsToSpray,
    widthPerRow,
    literPerHectare,
    conc
  );
};

const calculateActualRate = (
  rate,
  totalChemical,
  selectedChemical,
  selectedAreas,
  rowsToSpray,
  widthPerRow,
  literPerHectare,
  conc
) => {
  return (
    (rate * (totalChemical.quantity * multiplier(totalChemical.units))) /
    (calculateChemicalTotal(
      selectedChemical,
      selectedAreas,
      rowsToSpray,
      widthPerRow,
      literPerHectare,
      conc
    ).quantity *
      multiplier(totalChemical.units))
  );
};
export default (
  task,
  blocksData,
  sprayConditions,
  interventionTypes,
  showParentBlocks,
  totalChemicals,
  selectedChemicals,
  selectedAreas,
  rowsToSpray,
  widthPerRow,
  literPerHectare
) => {
  const totalUsedTanksDecimal = blocksData.actualTanks
    ? blocksData.actualTanks.toFixed(2) % 1
    : 0;

  const areaSize = task.areas.reduce(
    (accum, item) => accum + item.area.size,
    0
  );
  const rowToSpray = task.rowsToSpray !== "ALL" ? 1 / 2 : 1;
  const rowWidth = task.rowWidth / 100;
  const totalAreaSize = areaSize * rowToSpray * rowWidth;
  const chemRate = _.get(
    task.chemicals.find(item => item),
    "dilutionRate"
  );
  const units = matterToUnits(
    _.get(
      task.chemicals.find(item => item),
      "chemical.matter"
    )
  );
  const perHectare = task.literPerHectare;
  const conc = task.conc;

  const machineryLitersSize = task.machinery ? task.machinery.size : 0;
  const multiplier = item => {
    const foundElement = [
      { id: "gram", matter: "SOLID", multiplicationFactor: 0.001 },
      { id: "kilogram", matter: "SOLID", multiplicationFactor: 1 },
      { id: "liter", matter: "LIQUID", multiplicationFactor: 1 },
      { id: "milliliter", matter: "LIQUID", multiplicationFactor: 0.001 }
    ].find(({ id }) => id === item);

    if (foundElement) {
      return 1 / foundElement.multiplicationFactor;
    }
    return 1;
  };

  const unitFormater = item => {
    let unit;
    if (item === "liter") {
      unit = "L";
    } else if (item === "kilogram") {
      unit = "kg";
    } else if (item === "milliliter") {
      unit = "mL";
    } else {
      unit = "g";
    }
    return unit;
  };
  function groupByParentId(data) {
    return data.reduce((result, item) => {
      const parentId = item.parent ? item.parent.id : "noParent";
      if (!result[parentId]) {
        result[parentId] = [];
      }
      result[parentId].push(item);
      return result;
    }, {});
  }
  const groupedData = groupByParentId(blocksData.blocksNames);
  function transformObjectToGroupedArray(data) {
    const result = [];
    Object.keys(data).forEach(key => {
      if (key !== "noParent") {
        result.push({
          name: data[key][0]?.parent?.name,
          blockType: "block",
          done: data[key]?.every(item => item.done),
          hectares: data[key]?.reduce((prev, acc) => prev + acc.hectares, 0),
          plannedLiters: data[key]?.reduce(
            (prev, acc) => prev + acc.plannedLiters,
            0
          ),
          plannedTanks: data[key]?.reduce(
            (prev, acc) => prev + acc.plannedTanks,
            0
          ),
          actualTanks: data[key]?.reduce(
            (prev, acc) => prev + acc.actualTanks,
            0
          ),
          id: data[key][0]?.parent?.id,
          groupedObjects: data[key],
          variety: data[key].length
        });
      }
    });
    if (data["noParent"]) {
      result.push(...data["noParent"]);
    }

    return result;
  }
  const transformed = transformObjectToGroupedArray(groupedData);
  const html = (
    <div style={styles.block}>
      <style>{`@page { size: auto;  margin: 0mm;}`}</style>
      <div style={styles.page}>
        <div style={{ width: "100%", backgroundColor: "#004F5D" }}>
          <Logo />
        </div>
        <div>
          <table width="100%">
            <tr>
              <td style={styles.width35mmAndTopAlign} />
              <td style={styles.boldText}>AT-{task.id}</td>
            </tr>
            <tr>
              <td style={styles.width35mmAndTopAlign}>Planned date</td>
              <td style={styles.boldText}>{getDate(task.plannedDate)}</td>
            </tr>
            <tr>
              <td style={styles.width35mmAndTopAlign}>Expiry date</td>
              <td style={styles.boldText}>
                {task.expirationDate ? getDate(task.expirationDate) : "-"}
              </td>
            </tr>
            <tr>
              <td style={styles.width35mmAndTopAlign}>Supervisors</td>
              <td style={styles.boldText}>
                {task.supervisors && task.supervisors.length > 0 ? (
                  task.supervisors.map(supervisor => (
                    <div key={`supervisor${supervisor.id}`}>
                      {getName(supervisor.employee)}
                    </div>
                  ))
                ) : (
                  <div>-</div>
                )}
              </td>
              <td style={styles.width35mmAndTopAlign}>Operators</td>
              <td style={styles.boldText}>
                {task.assignees && task.assignees.length > 0 ? (
                  task.assignees.map(assignee => (
                    <div key={`assignee${assignee.id}`}>
                      {getName(assignee.employee)}
                    </div>
                  ))
                ) : (
                  <div>-</div>
                )}
              </td>
            </tr>
            <tr>
              <td style={styles.width35mmAndTopAlign}>Intervention type</td>
              <td style={styles.boldText}>
                {interventionTypes[_.get(task, "interventionType", "—")]}
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td style={styles.width35mmAndTopAlign}>Comments</td>
              <td style={styles.boldTextPreWrap}>
                {_.get(task, "comment", "—")}
              </td>
            </tr>
          </table>
        </div>
        <hr />
        <div>
          <h3 style={styles.margin10}>Areas To Apply </h3>
          <h5 style={styles.margin10}>
            {showParentBlocks
              ? "(Showing all patches)"
              : "(Showing parent blocks only)"}
          </h5>
          <div>
            <table width="100%" rules="groups" cellSpacing="5" cellPadding="5">
              <thead>
                <tr>
                  <th style={styles.normalText}>Area</th>
                  <th style={styles.normalText}>Product</th>
                  <th style={styles.normalText}>Variety</th>
                  <th style={styles.normalText}>Size</th>
                  {task.machinery && (
                    <th style={styles.normalText}>Volume (L)</th>
                  )}
                  {task.machinery && <th style={styles.normalText}>Tanks</th>}
                  {task.machinery && <th>Used Volume (L)</th>}
                  {task.machinery && <th>Used Tanks</th>}
                  <th style={styles.normalText}>Completed</th>
                </tr>
              </thead>
              <tbody>
                {transformed.map((block, index) => {
                  return (
                    <>
                      <tr
                        key={`block${block.id}`}
                        style={!block.parent ? styles.filledRow : {}}
                      >
                        <td style={styles.boldText}>{block.name}</td>
                        <td style={styles.center}>
                          {!!block?.groupedObjects?.length
                            ? block.variety
                            : `${block?.variety?.variety?.crop?.name ||
                                "-"}/ ${block?.variety?.variety?.name || "-"}`}
                        </td>
                        <td style={styles.center}>
                          {block?.variety?.crop?.name} -{" "}
                          {block?.variety?.variety?.name}
                        </td>
                        <td style={styles.center}>
                          <Numeric
                            value={block.hectares}
                            fractionDigits={2}
                            units="Ha"
                          />
                        </td>
                        {task.machinery && (
                          <td style={styles.center}>
                            <Numeric
                              value={block.plannedLiters}
                              fractionDigits={2}
                              units="L"
                            />
                          </td>
                        )}
                        {task.machinery && (
                          <td style={styles.center}>
                            <Numeric
                              value={block.plannedTanks}
                              fractionDigits={2}
                            />
                          </td>
                        )}
                        {task.machinery && (
                          <td style={styles.center}>
                            <Numeric
                              value={block.actualTanks * task.machinery.size}
                              fractionDigits={2}
                            />
                          </td>
                        )}
                        {task.machinery && (
                          <td style={styles.center}>
                            <Numeric
                              value={block.actualTanks}
                              fractionDigits={2}
                            />
                          </td>
                        )}
                        <td style={styles.center}>
                          <input
                            type="checkbox"
                            checked={block.done}
                            disabled
                          />
                        </td>
                      </tr>
                      {!!block?.groupedObjects?.length &&
                        block.groupedObjects.map((patch, index) => {
                          return (
                            <tr key={`block${patch.id}`}>
                              <td>{patch.name}</td>
                              <td style={styles.center}>
                                {`${patch?.variety?.variety?.crop?.name ||
                                  "-"}/ ${patch?.variety?.variety?.name ||
                                  "-"}`}
                              </td>
                              <td style={styles.center}>
                                <Numeric
                                  value={patch.hectares}
                                  fractionDigits={2}
                                  units="Ha"
                                />
                              </td>
                              {task.machinery && (
                                <td style={styles.center}>
                                  <Numeric
                                    value={patch.plannedLiters}
                                    fractionDigits={2}
                                    units="L"
                                  />
                                </td>
                              )}
                              {task.machinery && (
                                <td style={styles.center}>
                                  <Numeric
                                    value={patch.plannedTanks}
                                    fractionDigits={2}
                                  />
                                </td>
                              )}
                              {task.machinery && (
                                <td style={styles.center}>
                                  <Numeric
                                    value={
                                      patch.actualTanks * task.machinery.size
                                    }
                                    fractionDigits={2}
                                  />
                                </td>
                              )}
                              {task.machinery && (
                                <td style={styles.center}>
                                  <Numeric
                                    value={patch.actualTanks}
                                    fractionDigits={2}
                                  />
                                </td>
                              )}
                              <td style={styles.center}>
                                <input
                                  type="checkbox"
                                  checked={patch.done}
                                  disabled
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  );
                })}
              </tbody>
              <tfoot>
                <tr style={styles.filledRow}>
                  <td>{blocksData.blocksNames.length} Fields</td>
                  <td></td>
                  <td style={styles.center}>
                    <Numeric
                      value={blocksData.hectares}
                      fractionDigits={2}
                      units="Ha"
                    />
                  </td>
                  {task.machinery && (
                    <td style={styles.center}>
                      <Numeric
                        value={blocksData.plannedLiters}
                        fractionDigits={2}
                        units="L"
                      />
                    </td>
                  )}
                  {task.machinery && (
                    <td style={styles.center}>
                      <Numeric
                        value={blocksData.plannedTanks}
                        fractionDigits={2}
                      />
                    </td>
                  )}
                  {task.machinery && (
                    <td style={styles.center}>
                      {blocksData.actualTanks >= 0 ? (
                        <Numeric
                          value={blocksData.actualTanks * task.machinery.size}
                          fractionDigits={2}
                        />
                      ) : (
                        "________"
                      )}
                    </td>
                  )}
                  {task.machinery && (
                    <td style={styles.center}>
                      {blocksData.actualTanks >= 0 ? (
                        <Numeric
                          value={blocksData.actualTanks}
                          fractionDigits={2}
                        />
                      ) : (
                        "________"
                      )}
                    </td>
                  )}
                  <td />
                </tr>
              </tfoot>
            </table>
            {task.machinery && (
              <>
                <div style={{ marginTop: "10px" }}>
                  {`1 Tank = `}
                  <Numeric
                    value={machineryLitersSize}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units={units}
                  />
                </div>
                {totalUsedTanksDecimal !== 0 && (
                  <div style={{ marginTop: "10px" }}>
                    <Numeric
                      value={totalUsedTanksDecimal}
                      fractionDigits={2}
                      commaSeparatorOnThousands
                      units="Tank"
                    />
                    {` = `}
                    <Numeric
                      value={
                        (blocksData.actualTanks * machineryLitersSize) %
                        machineryLitersSize
                      }
                      fractionDigits={2}
                      commaSeparatorOnThousands
                      units={units}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <br />
          <div>
            <table width="100%" cellSpacing="3" cellPadding="3">
              <thead style={{ textAlign: "left" }}>
                <tr>
                  <th style={styles.normalText}>Rows to Apply</th>
                  <th style={styles.normalText}>Rows Width</th>
                  <th style={styles.normalText}>L per Ha</th>
                  <th style={styles.normalText}>Growth Stage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={styles.boldText}>{task.rowsToSpray || "—"}</td>
                  <td style={styles.boldText}>
                    <Numeric
                      value={task.rowWidth}
                      fractionDigits={2}
                      units="%"
                    />
                  </td>
                  <td style={styles.boldText}>
                    <Numeric
                      value={
                        task.status === "COMPLETED" && task.machinery
                          ? (task.literPerHectare *
                              blocksData.actualTanks *
                              task.machinery?.size) /
                            blocksData.plannedLiters
                          : task.literPerHectare
                      }
                      fractionDigits={2}
                      units="L"
                    />
                  </td>
                  <td style={styles.boldText}>{task.growthStage || "—"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        {task.machinery && (
          <div>
            <h3 style={styles.margin10}>Operations</h3>
            <div>
              <table width="100%" cellSpacing="3" cellPadding="3">
                <thead style={{ textAlign: "left" }}>
                  <tr>
                    <th style={styles.normalText}>Machinery</th>
                    <th style={styles.normalText}>Size</th>
                    <th style={styles.normalText}>Nozzle</th>
                    <th style={styles.normalText}>Nozzles per side</th>
                    <th style={styles.normalText}>Gear</th>
                    <th style={styles.normalText}>RPM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={styles.boldText}>
                      {task.machinery.name || "—"}
                    </td>
                    <td style={styles.boldText}>
                      <Numeric
                        value={_.get(
                          task,
                          "machinerySize",
                          _.get(task, "machinery.size")
                        )}
                        units="L"
                      />
                    </td>
                    <td style={styles.boldText}>{task.nozzle || "—"}</td>
                    <td style={styles.boldText}>
                      {task.nozzlesPerSide || "All"}
                    </td>
                    <td style={styles.boldText}>{task.gear || "All"}</td>
                    <td style={styles.boldText}>{task.rpm || "All"}</td>
                  </tr>
                </tbody>
                <thead style={{ textAlign: "left" }}>
                  <tr>
                    <th style={styles.normalText}>Pressure</th>
                    <th style={styles.normalText}>Speed</th>
                    <th style={styles.normalText}>PTO</th>
                    <th style={styles.normalText}>Concentration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={styles.boldText}>
                      {task.pressure ? task.pressure + " psi" : "—"}
                    </td>
                    <td style={styles.boldText}>
                      {task.machinerySpeed || "—"}
                    </td>
                    <td style={styles.boldText}>{task.pto || "—"}</td>
                    <td style={styles.boldText}>{task.conc || "—"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <br />
        <div>
          <h3 style={styles.margin10}>Chemicals</h3>
          <div>
            <table width="100%" rules="groups" cellSpacing="5" cellPadding="5">
              <thead>
                <tr>
                  <th style={styles.normalTextLeft}>Name</th>
                  <th style={styles.normalTextLeft}>Type</th>
                  <th style={styles.normalTextLeft}>Target</th>
                  <th style={styles.normalText}>Rate</th>
                  {task.status === "COMPLETED" && (
                    <th style={styles.normalText}>Actual rate</th>
                  )}
                  {task.status === "COMPLETED" && (
                    <th style={styles.normalText}>Actual Qty</th>
                  )}
                  <th style={styles.normalText}>
                    {task.machinery ? "Qty/Full Tank" : "Full Qty"}
                  </th>
                  {task.machinery && (
                    <th style={styles.normalText}>Qty/Part Tank</th>
                  )}
                  <th style={styles.normalText}>Predicted Total Qty</th>
                </tr>
              </thead>
              <tbody>
                {task.chemicals.map((chemical, index) => (
                  <>
                    <tr
                      style={index % 2 === 0 ? styles.filledRow : {}}
                      key={_.get(
                        chemical,
                        "chemical.id",
                        _.get(chemical, "id")
                      )}
                    >
                      <td style={styles.boldText}>
                        {_.get(
                          chemical,
                          "chemical.name",
                          _.get(chemical, "name", "—")
                        )}
                      </td>
                      <td style={styles.boldText}>
                        {_.get(
                          chemical,
                          "chemical.type.type",
                          _.get(chemical, "chemical.type.type", "—")
                        )}
                      </td>
                      <td style={styles.boldText}>
                        {[
                          _.get(
                            chemical,
                            "chemical.chemicalRate.crop.name",
                            _.get(chemical, "chemicalRate.crop.name", null)
                          ),
                          _.get(
                            chemical,
                            "chemical.chemicalRate.purpose",
                            _.get(chemical, "chemicalRate.purpose", null)
                          )
                        ]
                          .filter(item => item)
                          .join(" - ") || "—"}
                      </td>
                      <td style={styles.center}>
                        {chemical.dilutionRate
                          ? chemical?.dilutionRate *
                            multiplier(chemical?.chemicalRate?.unit)
                          : chemical?.rate?.minRate *
                              multiplier(chemical?.chemicalRate?.unit) || "-"}
                        {unitFormater(chemical.chemicalRate.unit) || "-"}/
                        {_.get(
                          chemical,
                          "dilutionRateType",
                          _.get(chemical, "rate.rateType")
                        )
                          ? rateTypes[
                              _.get(
                                chemical,
                                "dilutionRateType",
                                _.get(chemical, "rate.rateType")
                              )
                            ]
                          : "—"}
                      </td>
                      {task.status === "COMPLETED" && (
                        <td style={styles.center}>
                          {`${
                            chemical.dilutionRateType === "PER_100_LITERS"
                              ? chemical.dilutionRate
                              : calculateActualRate(
                                  chemical.dilutionRate,
                                  totalChemicals[index],
                                  selectedChemicals[index],
                                  selectedAreas,
                                  rowsToSpray,
                                  widthPerRow,
                                  literPerHectare,
                                  conc
                                ).toFixed(2)
                          }
                            ${unitFormater(chemical.chemicalRate.unit)}/${
                            _.get(
                              chemical,
                              "dilutionRateType",
                              _.get(chemical, "rate.rateType")
                            )
                              ? rateTypes[
                                  _.get(
                                    chemical,
                                    "dilutionRateType",
                                    _.get(chemical, "rate.rateType")
                                  )
                                ]
                              : "—"
                          }
                        `}
                        </td>
                      )}
                      {task.status === "COMPLETED" &&
                        selectedChemicals.length > 0 && (
                          <td>
                            <Numeric
                              fractionDigits={2}
                              value={
                                isFinite(totalChemicals[index].quantity)
                                  ? totalChemicals[index].quantity *
                                    multiplier(totalChemicals[index].units)
                                  : "-"
                              }
                              commaSeparatorOnThousands
                              units={unitFormater(totalChemicals[index].units)}
                            />
                          </td>
                        )}
                      <td style={{ ...styles.boldText, ...styles.center }}>
                        {blocksData.plannedTanks > 1 ? (
                          <Numeric
                            value={
                              chemical.qtyPerFullVat
                                ? chemical?.qtyPerFullVat *
                                  multiplier(chemical?.chemicalRate?.unit)
                                : chemical?.fullTanks *
                                    multiplier(chemical?.chemicalRate?.unit) ||
                                  "-"
                            }
                          />
                        ) : (
                          0
                        )}{" "}
                        {unitFormater(chemical.chemicalRate.unit) || "-"}
                      </td>
                      {task.machinery && (
                        <td style={{ ...styles.boldText, ...styles.center }}>
                          <Numeric
                            value={
                              chemical.qtyPerPartTank
                                ? chemical?.qtyPerPartTank *
                                  multiplier(chemical?.chemicalRate?.unit)
                                : chemical?.partTanks *
                                    multiplier(chemical?.chemicalRate?.unit) ||
                                  "-"
                            }
                          />
                          {unitFormater(chemical.chemicalRate.unit) || "-"}
                        </td>
                      )}
                      <td style={{ ...styles.boldText, ...styles.center }}>
                        <Numeric
                          value={
                            chemical.dilutionRate &&
                            chemical?.dilutionRate *
                              multiplier(chemical?.chemicalRate?.unit) *
                              totalAreaSize *
                              (chemical.dilutionRateType === "PER_HECTARE"
                                ? 1
                                : (perHectare * conc) / 100)
                          }
                          fractionDigits={2}
                        />
                        {unitFormater(chemical.chemicalRate.unit) || "-"}
                      </td>
                    </tr>
                    <tr style={index % 2 === 0 ? styles.filledRow : {}}>
                      <td style={{ ...styles.boldText }}>Batch No.</td>
                      <td style={{ ...styles.boldText, width: "150px" }}>
                        Date of Manufacture{" "}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {task.status === "COMPLETED" && <td></td>}
                      {task.status === "COMPLETED" && <td></td>}
                    </tr>
                    <tr style={index % 2 === 0 ? styles.filledRow : {}}>
                      <td style={{ ...styles.batchNumerColumn }}>
                        {_.get(chemical, "batchNumbers")?.map(i => (
                          <span>{i}</span>
                        ))}
                      </td>
                      <td style={{ ...styles.dateColumn }}>
                        {chemical.dateOfManufacture
                          ? _.get(chemical, "batchNumbers")?.map(i => (
                              <div>
                                {moment(chemical.dateOfManufacture).format(
                                  "DD/MM/YYYY"
                                )}
                              </div>
                            ))
                          : "-"}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {task.status === "COMPLETED" && <td></td>}
                      {task.status === "COMPLETED" && <td></td>}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div>
          <h3 style={styles.margin10}>Weather Conditions</h3>
          <table width="100%" cellSpacing="5" cellPadding="5">
            <thead>
              <tr>
                <th colSpan="6" align="left">
                  Wind strength:
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={styles.center}>
                {sprayConditions.strenghts.map((strength, index) => (
                  <td key={index}>
                    <input
                      type="checkbox"
                      disabled
                      checked={sprayConditions.windStrength === strength.value}
                    />
                    <div>{windStrength[index]}</div>
                    <div>
                      {strength.text}
                      km/h
                    </div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <div>
            <span style={{ ...styles.fixedWidth, ...styles.boldText }}>
              Wind direction:
            </span>
            <span style={styles.textDecoration}>
              {sprayConditions.windDirection}
            </span>
          </div>
          <div>
            <span style={{ ...styles.fixedWidth, ...styles.boldText }}>
              Temperature:
            </span>
            <span style={styles.textDecoration}>
              {sprayConditions.temperature}
            </span>
          </div>
          <div>
            <span style={{ ...styles.fixedWidth, ...styles.boldText }}>
              Delta T:
            </span>
            <span style={styles.textDecoration}>
              {sprayConditions.deltaT ? sprayConditions.deltaT : "-"}
            </span>
          </div>
        </div>
        <br />
        <div>
          <h3 style={styles.margin10}>Personal Protection Equipment:</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ...styles.margin10
            }}
          >
            <span>
              Was Personal Protection Equipment (P.P.E.) worn while undertaking
              this task?
            </span>
            {task.status === completed ? (
              <span>{task.ppeWorn ? "Yes" : "No"}</span>
            ) : (
              <input type="checkbox" disabled />
            )}
          </div>
        </div>
        {task.status === completed ? (
          <div style={styles.footerBlock}>
            <div style={styles.workingTimesLabel}>Working time</div>
            <div style={styles.workingTimes}>
              {task.workingTimes.map(workingTime => (
                <div style={styles.underline} key={workingTime.date}>
                  {workingTime.date
                    ? moment(workingTime.date).format("DD/MM/YYYY")
                    : "—"}
                  &nbsp;&nbsp;&nbsp;
                  {workingTime.workingFrom
                    ? workingTime.workingFrom.substr(0, 5)
                    : "—"}
                  {" - "}
                  {workingTime.workingTo
                    ? workingTime.workingTo.substr(0, 5)
                    : "—"}{" "}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div style={styles.footerBlock}>
            <div>
              <span style={styles.fixedWidth}>Date Completed</span>
              <span style={styles.textDecoration}>
                {task.status === completed ? getDate(task.closingDate) : ""}
              </span>
            </div>
            <div>
              <span style={styles.fixedWidth}>Starting Time</span>
              <span style={styles.textDecoration}>
                {task.status === completed ? task.workingFrom : ""}
              </span>
            </div>
            <div>
              <span style={styles.fixedWidth}>End Time</span>
              <span style={styles.textDecoration}>
                {task.status === completed ? task.workingTo : ""}
              </span>
            </div>
          </div>
        )}
        <div style={{ textAlign: "right" }}>
          <span
            style={{
              ...styles.fixedWidth,
              ...styles.boldText,
              textAlign: "center"
            }}
          >
            Signature
          </span>
          <span>_____________________________</span>
        </div>
      </div>
    </div>
  );
  return ReactDOMServer.renderToString(html);
};
