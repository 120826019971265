import React from "react";
import { Field } from "formik";
import { Icon, Form, Input, Segment } from "semantic-ui-react";

import { calculateTimeDifference } from "routes/Tasks/components/helper";
import DateRangePickerComponent from "./DateRangePicker";

import styles from "./CompletionModal.module.css";

const SecondStepForm = ({ wtData, disabled, errors, handleOnChange }) => {
  const { minutesDifference, hoursDifference } = calculateTimeDifference(
    wtData.timeStart,
    wtData.timeEnd,
    wtData.lunchInterval
  );

  return (
    <div className={styles.formWrapper}>
      <Form>
        <Segment className={styles.editModalSegment}>
          <Form.Group style={{ gap: "40px" }}>
            <Form.Field
              disabled={disabled}
              width={8}
              className={styles.formField}
              required
            >
              <label>Date range </label>
              <DateRangePickerComponent
                disabled={disabled}
                value={[wtData.dateStart, wtData.dateEnd]}
                setFieldValue={([dateStart, dateEnd]) => {
                  handleOnChange("dateStart", dateStart);
                  handleOnChange("dateEnd", dateEnd);
                }}
              />
            </Form.Field>
            <Form.Field
              disabled={disabled}
              width={8}
              className={styles.formField}
            >
              <label>Working time</label>
              <Field
                as={Input}
                disabled={true}
                icon={<Icon name="clock outline" />}
                className={styles.disabledField}
                name="row"
                value={`${hoursDifference}h ${minutesDifference}mm`}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ gap: "40px" }}>
            <span style={{ width: "50%", display: "flex" }}>
              <Form.Field
                disabled={disabled}
                width={8}
                className={styles.formField}
              >
                <label>Start</label>
                <Field
                  disabled={disabled}
                  as={Input}
                  name="timeStart"
                  type="time"
                  value={wtData?.timeStart}
                  onChange={(_, { value }) => {
                    handleOnChange("timeStart", value);
                  }}
                />
              </Form.Field>
              <Form.Field
                disabled={disabled}
                width={8}
                className={styles.formField}
              >
                <label>Finish</label>
                <Field
                  disabled={disabled}
                  as={Input}
                  name="timeEnd"
                  type="time"
                  value={wtData?.timeEnd}
                  onChange={(_, { value }) => {
                    handleOnChange("timeEnd", value);
                  }}
                />
              </Form.Field>
            </span>
            <Form.Field
              disabled={disabled}
              width={8}
              className={styles.formField}
            >
              <label>Piece rate</label>
              <Field
                as={Input}
                disabled={disabled}
                name="pieceRate"
                value={wtData?.pieceRate}
                onChange={(_, { value }) => {
                  handleOnChange("pieceRate", value);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ gap: "40px" }}>
            <span style={{ width: "50%", display: "flex" }}>
              <Form.Field
                disabled={disabled}
                required
                width={16}
                className={styles.formField}
              >
                <label>Lunch interval (min)</label>
                <Field
                  disabled={disabled}
                  as={Input}
                  icon={<Icon name="clock outline" />}
                  className={styles.disabledField}
                  name="lunchInterval"
                  value={wtData?.lunchInterval}
                  onChange={(_, { value }) => {
                    handleOnChange("lunchInterval", value);
                  }}
                />
              </Form.Field>
            </span>
            <span style={{ width: "50%", display: "flex" }}>
              <Form.Field
                disabled={disabled}
                width={8}
                type="number"
                className={styles.formField}
              >
                <label>No. of pieces</label>
                <Field
                  disabled={disabled}
                  as={Input}
                  name="piecesCount"
                  value={wtData?.piecesCount}
                  onChange={(_, { value }) => {
                    handleOnChange("piecesCount", value);
                  }}
                />
              </Form.Field>
              <Form.Field
                disabled={disabled}
                width={8}
                className={styles.formField}
              >
                <label>Total ($)</label>
                <Field
                  as={Input}
                  disabled={true}
                  className={styles.disabledField}
                  name="number"
                  value={
                    wtData?.pieceRate && wtData?.piecesCount
                      ? !isNaN(+wtData?.pieceRate * +wtData?.piecesCount)
                        ? +wtData?.pieceRate * +wtData?.piecesCount
                        : "0.00"
                      : "0.00"
                  }
                />
              </Form.Field>
            </span>
          </Form.Group>
        </Segment>
        {!!errors &&
          errors.map(err => (
            <div
              style={{
                width: "100%",
                marginLeft: "14px",
                color: "#D92D20"
              }}
            >
              {err}
            </div>
          ))}
      </Form>
    </div>
  );
};

export default SecondStepForm;
