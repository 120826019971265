import React, { useState } from "react";
import { Popup, Button, Icon, Grid, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { useFormikContext } from "formik";
import styles from "./EditModal.module.css";
import EditForm from "./EditForm";
import moment from "moment";
import { employeeViewer } from "routes/Tasks/components/helper";

const WorkingTimeRow = ({
  isSectionOpen,
  setError,
  error,
  errorChecker,
  toggleSection,
  setOpenSections,
  setErrorChecker,
  employeeList,
  archivedList,
  tableType
}) => {
  const [openAreaSections, setOpenAreaSections] = useState({});
  const { values, setFieldValue } = useFormikContext();
  const handleToggleAreaSection = index => {
    setOpenAreaSections(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const renderAssignee = (assignee, areaIndex, index, item) => {
    const employee = assignee.employee;
    const isOpen = isSectionOpen(areaIndex, index);
    const employeeName =
      employee?.firstName && employee?.lastName
        ? `${employee?.firstName} ${employee?.lastName}`
        : "New Assignee";

    return (
      <>
        <Grid container as={Segment} className={styles.wrapper}>
          <Grid.Row className={styles.wrapperRow}>
            <div className={styles.iconContainer}>
              <Grid.Column className={styles.chevronContainer}>
                <div
                  onClick={() => toggleSection(areaIndex, index)}
                  className={`${styles.toggleIcon} ${isOpen ? styles.openTable : ""
                    }`}
                >
                  <Icon
                    className={
                      isOpen ? "tuf-chevron-down" : "tuf-chevron-right"
                    }
                  />
                </div>
              </Grid.Column>
            </div>
            <div className={styles.columnContainer}>
              <Grid.Column className={styles.nameColumn}>
                <label className={styles.productHeader}>
                  {employee?.firstName &&
                    employee?.lastName &&
                    employeeViewer(
                      [{ employeeId: employee?.id }],
                      [...employeeList, ...archivedList]
                    )}
                  {employeeName}
                </label>
              </Grid.Column>
              <Grid.Column >
                <Popup
                  inverted
                  position="bottom center"
                  content={"Delete"}
                  size="tiny"
                  trigger={
                    <div className={styles.deleteButton}>
                      <Icon
                       disabled={item.assignee.length < 2}
                        name="trash alternate"
                        onClick={() =>
                          setFieldValue(
                            `blocks[${areaIndex}]assignee`,
                            values.blocks[areaIndex].assignee.filter(
                              (_, employeeIndex) => index !== employeeIndex
                            )
                          )
                        }
                      />
                    </div>
                  }
                />
              </Grid.Column>
            </div>
          </Grid.Row>
          {isOpen &&
            assignee.workingTime.map((wtItem, wtIndex) => {
              const handleUpdateValue = (activeField, updatedValues) =>
                setFieldValue(
                  `blocks[${areaIndex}]assignee[${index}]workingTime[${wtIndex}]${activeField}`,
                  updatedValues
                );
              return (
                <EditForm
                  assignee={item}
                  setError={setError}
                  setErrorChecker={setErrorChecker}
                  handleUpdateValue={handleUpdateValue}
                  item={wtItem}
                  wtIndex={wtIndex}
                  employeeList={employeeList}
                  addChecker={!Object.keys(employee).length}
                  areaIndex={areaIndex}
                  index={index}
                />
              );
            })}
          {isOpen && (
            <div className={styles.wtAddBtnWrapper}>
              <Button
                type="text"
                disabled={
                  assignee.workingTime.some(
                    item =>
                      !item.start ||
                      !item.end ||
                      !item.lunchInterval ||
                      !item.machineryId
                  ) ||
                  errorChecker ||
                  error
                }
                onClick={() =>
                  setFieldValue(
                    `blocks[${areaIndex}]assignee[${index}]workingTime`,
                    [
                      ...assignee.workingTime,
                      {
                        employee: assignee.employee,
                        employeeId: assignee.employee.id,
                        start: null,
                        end: null,
                        wtStart: null,
                        machineryId: null,
                        wtEnd: null,
                        price: 0,
                        noOfPieces: 0,
                        lunchInterval: 0
                      }
                    ]
                  )
                }
              >
                + Add working time
              </Button>
            </div>
          )}
        </Grid>
      </>
    );
  };

  const renderAssignees = (item, areaIndex) =>
    item.assignee.map((assignee, index) =>
      renderAssignee(assignee, areaIndex, index, item)
    );

  return (
    <>
      {values.blocks.map((item, areaIndex) => {
        return (
          <div key={areaIndex}>
            {tableType === "ALL" ? (
              <>
                {renderAssignees(item, areaIndex)}
                <div className={styles.addBtnWrapper}>
                  {error && (
                    <div
                      style={{
                        width: "80%",
                        marginTop: "16px",
                        color: "#D92D20"
                      }}
                    >
                      This assignee has already been added
                    </div>
                  )}
                  <Button
                    disabled={
                      values.blocks[areaIndex].assignee
                        .map(item => item.workingTime)
                        .flat()
                        .some(
                          item =>
                            !item.start ||
                            !item.end ||
                            !item.lunchInterval ||
                            !item.employeeId ||
                            !item.machineryId
                        ) ||
                      errorChecker ||
                      error
                    }
                    type="text"
                    onClick={() => {
                      setFieldValue(`blocks[${areaIndex}]assignee`, [
                        ...item.assignee,
                        {
                          employee: {},
                          workingTime: [
                            {
                              employeeId: null,
                              employee: null,
                              start: null,
                              end: null,
                              machineryId: null,
                              wtStart: null,
                              wtEnd: null,
                              price: 0,
                              noOfPieces: 0,
                              lunchInterval: 0
                            }
                          ]
                        }
                      ]);
                      setOpenSections({});
                      toggleSection(areaIndex, item.assignee.length);
                    }}
                  >
                    <Icon name="plus" />
                    Add employee
                  </Button>
                </div>
              </>
            ) : (
              <Grid container as={Segment} className={styles.blockWrapper}>
                <Grid.Row className={styles.wrapperRow}>
                  <Grid.Column className={styles.nameWrapepr}>
                    {item.areaName}
                  </Grid.Column>
                  <div className={styles.parentTableIconContainer}>
                    <Grid.Column className={styles.chevronContainer}>
                      <div
                        onClick={() => handleToggleAreaSection(areaIndex)}
                        className={`${styles.toggleIcon} ${openAreaSections[areaIndex] ? styles.openTable : ""
                          }`}
                      >
                        <Icon
                          className={
                            openAreaSections[areaIndex]
                              ? "tuf-chevron-down"
                              : "tuf-chevron-right"
                          }
                        />
                      </div>
                    </Grid.Column>
                  </div>
                </Grid.Row>
                {openAreaSections[areaIndex] && (
                  <div style={{ width: "100%" }}>
                    {renderAssignees(item, areaIndex)}
                    <div className={styles.addBtnWrapper}>
                      {error && (
                        <div
                          style={{
                            width: "80%",
                            marginTop: "16px",
                            color: "#D92D20"
                          }}
                        >
                          This assignee has already been added
                        </div>
                      )}
                      <Button
                        disabled={
                          values.blocks[areaIndex].assignee
                            .map(item => item.workingTime)
                            .flat()
                            .some(
                              item =>
                                !item.start ||
                                !item.end ||
                                !item.lunchInterval ||
                                !item.employeeId ||
                                !item.machineryId
                            ) ||
                          errorChecker ||
                          error
                        }
                        className={styles.addBtn}
                        onClick={() => {
                          setFieldValue(`blocks[${areaIndex}]assignee`, [
                            ...item.assignee,
                            {
                              employee: {},
                              workingTime: [
                                {
                                  employeeId: null,
                                  employee: null,
                                  start: null,
                                  end: null,
                                  machineryId: null,
                                  wtStart: null,
                                  wtEnd: null,
                                  price: 0,
                                  noOfPieces: 0,
                                  lunchInterval: 0
                                }
                              ]
                            }
                          ]);
                          setOpenSections({});
                          toggleSection(areaIndex, item.assignee.length);
                        }}
                      >
                        <Icon name="plus" />
                        Add employee
                      </Button>
                    </div>
                  </div>
                )}
              </Grid>
            )}
          </div>
        );
      })}
    </>
  );
};

export default connect(state => ({
  tableData: state.newTasks.data || []
}))(WorkingTimeRow);
