import React, { useEffect, useState, useMemo, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Header, Icon, Segment, Table } from "semantic-ui-react";
import styles from "./PieceRatePayroll.module.css";
import { sortConfig, filtersOptions } from "./constants";
import TableRow from "./components/TableRow";
import PrintReport from "./components/PrintReport";
import { debounce } from "lodash";
import {
  fetchPieceRatePayrollsAllInformation,
  fetchPieceRatePayrollsSummary,
  fetchPieceRatePayrollsExcelData
} from "../../../actions/PieceRatePayroll/pieceRatePayroll";
import Loader from "../../../components/Loader";
import SortDropdown from "../../../components/SortDropdown";
import Filters from "../../../components/Filters";
import PrintoutButton from "../../../components/PrintoutButton";
import Layout from "../../../components/Layout/Layout";
import Pagination from "../../../components/ListTable/Pagination";
import Numeric from "../../../components/Numeric";
import { hashToObject, objectToHash } from "../../../utils/hashToObject";
import { getFiltersFromLocations } from "../../../utils/routeHelpers";
import { minutesToHM } from "../../../utils/timeUtils";
import { excelExport } from "utils/excelExport";
import { usePrevious } from "utils/hooks";
import moment from "moment";

const PieceRatePayroll = ({ route, location, navigate }) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [printExpandedData, setPrintExpandedData] = useState(false);
  const prevLocation = usePrevious(location);
  const prevPageSize = usePrevious(pageSize);
  const prevPageNumber = usePrevious(pageNumber);
  const {
    data: { content: payrolls, page },
    payrollsAllInformation,
    isFetching
  } = useSelector(state => state.pieceRatePayroll);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const rawFilters = getFiltersFromLocations(filtersOptions, location);
  const { sort } = hashToObject(location.hash);
  const [sortValue, sortOrder] = sort
    ? sort.split(",")
    : ["employeeName", "asc"];

  const { filters, dateAfter, dateBefore } = useMemo(() => {
    const { dateFrom, dateTo, ...filters } = rawFilters;
    const dateAfter = rawFilters.dateFrom ? rawFilters.dateFrom[0] : null;
    const dateBefore = rawFilters.dateTo ? rawFilters.dateTo[0] : null;

    return {
      filters,
      dateAfter,
      dateBefore
    };
  }, [location.hash]);

  const disableChecker = !!filters.seasons
    ? "seasons"
    : !!dateAfter || !!dateBefore
    ? "date"
    : null;

  const payrollTotals = useMemo(
    () =>
      payrolls.reduce(
        (prev, item) => ({
          workingSeconds: prev.workingSeconds + item.workingSeconds,
          numberOfPieces: prev.numberOfPieces + item.numberOfPieces,
          totalPay: prev.totalPay + item.totalPay
        }),
        {
          workingSeconds: 0,
          numberOfPieces: 0,
          totalPay: 0
        }
      ),
    [payrolls]
  );

  const itemsCount = useMemo(
    () => ({
      itemFrom: page.number * pageSize + 1,
      itemTo: Math.min(
        page.number * pageSize + 1 + pageSize - 1,
        page.totalElements
      )
    }),
    [page.number, pageSize, page.totalElements]
  );

  useEffect(() => {
    setPageNumber(0);
  }, [location.hash]);

  const fetchPieceRatePayrolls = useRef(
    debounce((...params) => {
      dispatch(fetchPieceRatePayrollsSummary(...params));
    }, 100)
  );

  useEffect(() => {
    if (
      prevLocation ||
      prevPageNumber !== pageNumber ||
      prevPageSize !== pageSize ||
      prevLocation.hash !== location.hash
    ) {
      fetchPieceRatePayrolls.current(
        pageNumber,
        pageSize,
        filters,
        [`${sortValue},${sortOrder}`],
        dateAfter,
        dateBefore
      );
    }
  }, [pageNumber, pageSize, location]);
  //For payroll pages, the default filter should be by date.
  useEffect(() => {
    if (!location.hash) {
      const hash = objectToHash({
        sort: "employeeName,asc",
        date: [
          moment()
            .subtract(1, "week")
            .format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD")
        ]
      });

      navigate(
        {
          pathname: location.pathname,
          hash,
          state: location.state
        },
        { replace: true }
      );
    }
  }, []);

  const fetchPrintoutAllInformation = () => {
    return dispatch(
      fetchPieceRatePayrollsAllInformation(dateAfter, dateBefore, filters)
    );
  };

  const handleBackClick = () => {
    navigate({
      pathname: "/reports",
      state: {
        activeNode: location?.state?.reportNode
      }
    });
  };

  const handleExcelExport = async () => {
    const { values, columns, fileName } = await dispatch(
      fetchPieceRatePayrollsExcelData(dateAfter, dateBefore, filters)
    );

    excelExport(values, columns, fileName);
  };

  return (
    <Layout route={route} location={location} classForMain={styles.mainHolder}>
      <Header as="h2">
        <span className={styles.backIconWrapper} onClick={handleBackClick}>
          <Icon name="angle left" className={styles.backIcon} />
        </span>
        {route.name}
      </Header>
      <div style={{ display: "none" }}>
        <PrintReport
          payrolls={printExpandedData ? payrollsAllInformation : payrolls}
          payrollTotals={payrollTotals}
          title="Payroll — Piece Rate"
          showExpandedData={printExpandedData}
          startDate={dateAfter}
          endDate={dateBefore}
          ref={componentRef}
        />
      </div>
      <Segment>
        <Grid className={styles.filtersGrid} verticalAlign="middle">
          <Grid.Row verticalAlign="middle">
            <Grid.Column mobile={16} tablet={7} computer={7} largeScreen={7}>
              <Filters
                navigate={navigate}
                disableChecker={disableChecker}
                options={filtersOptions}
                location={location}
              />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={9}
              computer={9}
              largeScreen={9}
              floated="right"
              textAlign="right"
              style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'end',
                alignItem:'center'
              }}
            >
              <PrintoutButton type="excel" onClick={handleExcelExport} />
              <PrintoutButton
                type="pdf"
                printoutAdditionalOptions={[
                  {
                    text: "Print Summary Payroll",
                    onClick: () => {
                      setPrintExpandedData(false);
                      handlePrint();
                    }
                  },
                  {
                    text: "Print All Information",
                    onClick: () => {
                      fetchPrintoutAllInformation().then(() => {
                        setPrintExpandedData(true);
                        handlePrint();
                      });
                    }
                  }
                ]}
              />
              <SortDropdown
                navigate={navigate}
                {...sortConfig}
                sortValue={sortValue}
                sortOrder={sortOrder}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {isFetching && payrolls.length === 0 ? (
          <Loader className={styles.loader} />
        ) : payrolls.length > 0 ? (
          <>
            <Table
              basic="very"
              unstackable
              selectable
              className={styles.payrollsTable}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>Employee Name</Table.HeaderCell>
                  <Table.HeaderCell>Contractor</Table.HeaderCell>
                  <Table.HeaderCell>Working time</Table.HeaderCell>
                  <Table.HeaderCell>Number of Pieces (No)</Table.HeaderCell>
                  <Table.HeaderCell>Total Pay</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {payrolls.map((payroll, index) => (
                  <TableRow
                    key={`payroll${payroll.employeeId}`}
                    isStriped={index % 2 === 1}
                    location={location}
                    payroll={payroll}
                    dateFrom={dateAfter}
                    dateTo={dateBefore}
                  />
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row className={styles.totalsRow}>
                  <Table.Cell colSpan={3}>Total</Table.Cell>
                  <Table.Cell>
                    {minutesToHM(payrollTotals.workingSeconds / 60)}
                  </Table.Cell>
                  <Table.Cell>
                    <Numeric
                      value={payrollTotals.numberOfPieces}
                      fractionDigits={2}
                      commaSeparatorOnThousands
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Numeric
                      value={payrollTotals.totalPay}
                      fractionDigits={2}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <Pagination
              currentPage={pageNumber}
              totalElements={page.totalElements}
              pageSize={pageSize}
              itemsCount={itemsCount}
              onPageChangeOwn={pageNumber => setPageNumber(pageNumber)}
              pages={page.totalPages}
              updatePageSize={(_, data) => {
                setPageSize(data.value);
                setPageNumber(0);
              }}
            />
          </>
        ) : (
          <Header as="h4">Nothing found</Header>
        )}
      </Segment>
    </Layout>
  );
};
export default PieceRatePayroll;
