import React, { useEffect, useMemo } from "react";
import { Button, Form, Modal, Icon } from "semantic-ui-react";
import { connect, useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Formik, Field } from "formik";

import styles from "../AddUpdateGroupModal/AddUpdateGroupModal.module.css";
import { abilitiesSelector } from "../../../../../selectors/user";
import { bindActionCreators } from "redux";
import {
  addNewLaborGroup,
  clearFormData,
  setFormData,
  updateLaborGroup,
  fetchLaborGroups
} from "../../../../../actions/LaborGroups/laborGroups";
import { getEmployeeList } from "../../../../../actions/Contractors/contractors";
import { fetchEmployees } from "../../../../../actions/Employee/employees";
import { employeeOptionsSelector } from "../../../../../selectors/employee";
import MultiSelect from "../../../../../components/MultiSelect/MultiSelect";

const REQUIRED_FIELDS = ["group", "employees"];

const AssignWorkersModal = ({ update, open, onClose, laborGroups }) => {
  const dispatch = useDispatch();

  const { options } = useSelector(employeeOptionsSelector);

  const getWorkers = () => {
    dispatch(getEmployeeList());
  };

  useEffect(() => {
    getWorkers();
  }, []);

  useEffect(() => {
    dispatch(fetchLaborGroups({ archived: false, unpaged: true }));
  }, [fetchLaborGroups]);

  const groupOptions = useMemo(
    () =>
      laborGroups.map(content => ({
        key: content.id,
        text: content.name,
        value: content.id
      })),
    [laborGroups]
  ).sort((a, b) => a.text.localeCompare(b.text));

  const validateValues = async values => {
    const errors = {};
    const { group, employees } = values;

    REQUIRED_FIELDS.forEach(fieldName => {
      if (!get(values, fieldName)) {
        errors[fieldName] = "Required field";
      }
    });

    if (!group) {
      errors.group = true;
    }

    if (!employees.length) {
      errors.employees = true;
    }

    return errors;
  };

  function onSubmit(values) {
    const groupName = laborGroups.find(group => group.id === values.group).name;
    dispatch(
      updateLaborGroup(values.group, {
        name: groupName,
        employeeIds: values.employees
      })
    )
      .then(onClose)
      .then(update);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="mini"
      onClick={e => e.stopPropagation()}
      className={styles.root}
      closeOnDimmerClick={false}
    >
      <div className={styles.modalHeader}>Assign workers</div>
      <Formik
        validate={validateValues}
        enableReinitialize
        validateOnChange={true}
        onSubmit={onSubmit}
        initialValues={{ group: "", employees: [] }}
      >
        {props => {
          return (
            <>
              <Modal.Content className={styles.modalContentContainer}>
                <Form>
                  <Form.Group>
                    <Field
                      required
                      as={Form.Select}
                      width={16}
                      search
                      label="Assign to group"
                      options={groupOptions}
                      value={get(props.values, "group")}
                      name="group"
                      onChange={(_, { value }) => {
                        props.setFieldValue("group", value);
                        const employeesToSet = laborGroups.find(
                          group => group.id === value
                        ).employeeIds;
                        props.setFieldValue("employees", employeesToSet);
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Field required width={16}>
                      <label>Select workers</label>
                      <Field
                        required
                        search={true}
                        as={MultiSelect}
                        width={16}
                        text={
                          props.values.employees.length
                            ? `${props.values.employees.length} workers`
                            : ""
                        }
                        selector={employeeOptionsSelector}
                        value={props.values.employees}
                        name="employees"
                        loaderAction={getEmployeeList}
                        onOptionChange={props.setFieldValue}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <div className={styles.workerElementsContainer}>
                      {props.values.employees.map(employee => (
                        <div key={employee} className={styles.workerElement}>
                          <span>
                            {
                              options.find(option => option.value === employee)
                                .label
                            }
                          </span>
                          <Icon
                            name="close"
                            onClick={() => {
                              props.setFieldValue(
                                "employees",
                                props.values.employees.filter(
                                  id => id !== employee
                                )
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Cancel"
                  type="text"
                  onClick={onClose}
                />
                <Button
                  content={"Save"}
                  primary
                  onClick={props.submitForm}
                  disabled={
                    !props.isValid || !props.dirty || props.isValidating
                  }
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = state => {
  const {
    laborGroups: {
      isFetching,
      data,
      data: { content }
    }
  } = state;

  return {
    data,
    isFetching,
    Can: abilitiesSelector(state),
    laborGroups: content,
    laborGroupForm: state.laborGroups.laborForm,
    employeesList: state.employee.data.content,
    employeesFetching: state.employee.isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchLaborGroups,
      setFormData,
      clearFormData,
      addNewLaborGroup,
      updateLaborGroup,
      fetchEmployees
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignWorkersModal);
