import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  deleteMachineryAction,
  fetchMachineryAction,
} from "actions/Machinery/machineries";
import { abilitiesSelector } from "selectors/user";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import { chemicalShape } from "constants/Chemicals/types";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import { getMachineryTableColumns } from "utils/machineryNew";
import { Button, Confirm } from "semantic-ui-react";
import FlattenRecordsView from "components/FlattenRecordsView/FlattenRecordsView";

const Machinery = ({
  Can,
  location,
  route,
  isFetching,
  tableData,
  filterData,
  navigate,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [flatten, setFlatte] = useState(false);
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const dispatch = useDispatch();
  const getData = useCallback(
    (params) => {
      setSelectedItems([]);
      dispatch(fetchMachineryAction({ ...params }));
    },
    [fetchMachineryAction, filterData]
  );
  const onRowClick = useCallback(
    (event, row) => {
      if (!activeItem && row) {
        setActiveItem(row.original);
      }
    },
    [activeItem]
  );
  const onDelete = (id) => {
    dispatch(deleteMachineryAction(id));
  };
  const tableColumns = getMachineryTableColumns(
    isFetching,
    navigate,
    setOpenModal,
    setActiveId
  );

  return (
    <>
      <Confirm
        // className={styles.confirmModal}
        content="Are you sure?"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={() => {
          onDelete(activeId);
          setOpenModal(false);
        }}
      />
      {
        <TablePageHolder
          mainButton={
            <Button as={Link} primary to={"/machinery/add_new_machinery"}>
              Add
            </Button>
          }
          getData={getData}
          navigate={navigate}
          isFetching={isFetching}
          location={location}
          withoutSearch={false}
          onRowClick={onRowClick}
          onSidebarHidden={() => setActiveItem(null)}
          sidebarShown={!!activeItem}
          pageTitle={route.name}
          route={route}
          withSelection={false}
          selectedItems={selectedItemsIds}
          setSelectedItems={setSelectedItems}
          flatten={flatten}
          flattenComponent={
            <FlattenRecordsView
              name="Stock"
              data={tableData.content}
              options={{}}
              getData={getData}
              isFetching={isFetching}
            />
          }
          setFlatte={setFlatte}
          withFlatten={true}
          sidebarComponent={
            <Sidebar
              id={activeItem && activeItem.id}
              machinery={activeItem}
              setActiveItem={setActiveItem}
            />
          }
          tableColumns={tableColumns}
          tableData={tableData?.content || []}
        />
      }
    </>
  );
};

Machinery.propTypes = {
  Can: PropTypes.func,
  isArchivedView: PropTypes.bool,
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(chemicalShape),
  }).isRequired,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  fetchChemicals: PropTypes.func,
  isFetching: PropTypes.bool,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object,
};

export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  return {
    isArchivedView,
    Can: abilitiesSelector(state),
    tableData: state.machinery.data,
    isFetching: state.machinery.isFetching,
  };
})(Machinery);
