import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import moment from "moment";
import DatePicker from "../../../../components/DatePicker";
import {
  clearWeatherStationState,
  getFormattedWeatherStationsDataBetween,
  setShowCharts,
  setShowDataTable
} from "../../../../actions/WeatherStation";
import { dataGroups } from "../../../../reducers/WeatherStation";
import styles from "./StationsControlBar.module.css";
import { createTimezoneSelector } from "../../../../selectors/user";
import { toUnixInTimezone, toUnixInUTC } from "../../../../utils/timezoneUtils";

const dataModeOptions = {
  TIME_BETWEEN: "TIME_BETWEEN",
  TIME_PERIOD: "TIME_PERIOD"
};

const allTimePeriodOptions = [
  {
    key: "24hours",
    text: "24 hours",
    value: "24 hours"
  },
  {
    key: "2days",
    text: "2 days",
    value: "2days"
  },
  {
    key: "7days",
    text: "7 days",
    value: "7days"
  },
  {
    key: "10days",
    text: "10 days",
    value: "10days"
  },
  {
    key: "14days",
    text: "14 days",
    value: "14days"
  },
  {
    key: "30days",
    text: "30 days",
    value: "30days"
  },
  {
    key: "90days",
    text: "90 days",
    value: "90days"
  },
  {
    key: "6months",
    text: "6 months",
    value: "6months"
  },
  {
    key: "12months",
    text: "12 months",
    value: "12months"
  },
  {
    key: "24months",
    text: "24 months",
    value: "24months"
  },
  {
    key: "36months",
    text: "36 months",
    value: "36months"
  }
];

const hourlyTimePeriodOptions = allTimePeriodOptions.filter(item =>
  ["24hours", "2days", "7days", "10days", "14days", "30days"].includes(item.key)
);

const dailyTimePeriodOptions = allTimePeriodOptions.filter(item =>
  ["7days", "10days", "14days", "30days", "90days"].includes(item.key)
);

const monthlyTimePeriodOptions = allTimePeriodOptions.filter(item =>
  ["6months", "12months", "24months", "36months"].includes(item.key)
);

const dataGroupOptions = [
  {
    key: dataGroups.RAW,
    text: "Raw",
    value: dataGroups.RAW,
    timePeriodOptions: hourlyTimePeriodOptions
  },
  {
    key: dataGroups.HOURLY,
    text: "Hourly",
    value: dataGroups.HOURLY,
    timePeriodOptions: hourlyTimePeriodOptions
  },
  {
    key: dataGroups.DAILY,
    text: "Daily",
    value: dataGroups.DAILY,
    timePeriodOptions: dailyTimePeriodOptions
  },
  {
    key: dataGroups.MONTHLY,
    text: "Monthly",
    value: dataGroups.MONTHLY,
    timePeriodOptions: monthlyTimePeriodOptions
  }
];

const StationsControlBar = props => {
  const {
    id,
    showCharts,
    showStationDataTable,
    timezone,
    actions: {
      clearWeatherStationState,
      getFormattedWeatherStationsDataBetween,
      setShowCharts,
      setShowDataTable
    }
  } = props;
  const [dataMode, setDataMode] = useState(dataModeOptions.TIME_PERIOD);
  const [from, setFrom] = useState(
    moment(moment.now())
      .subtract(1, "d")
      .format("YYYY-MM-DD")
  );

  const [to, setTo] = useState(moment(moment.now()).format("YYYY-MM-DD"));
  const [timePeriod, setTimePeriod] = useState(
    dataGroupOptions[1].timePeriodOptions[0].value
  );
  const [timePeriodOptions, setTimePeriodOptions] = useState(
    dataGroupOptions[1].timePeriodOptions
  );
  const [dataGroup, setDataGroup] = useState(dataGroupOptions[1].value);

  const getFromTimePeriod = timePeriod => {
    const index = timePeriod.search(/[A-Za-z]/);
    const count = timePeriod.substring(0, index);
    const period = timePeriod.substring(index);
    return toUnixInTimezone(
      moment(moment.now()).subtract(count, period),
      timezone
    );
  };

  const refresh = useCallback(() => {
    if (dataMode === dataModeOptions.TIME_BETWEEN) {
      getFormattedWeatherStationsDataBetween(
        id,
        dataGroup,
        toUnixInUTC(from),
        toUnixInUTC(
          moment(to)
            .add(1, "days")
            .format("YYYY-MM-DD")
        )
      );
    } else {
      getFormattedWeatherStationsDataBetween(
        id,
        dataGroup,
        getFromTimePeriod(timePeriod),
        toUnixInTimezone(moment.now(), timezone)
      );
    }
  }, [
    dataMode,
    getFormattedWeatherStationsDataBetween,
    id,
    dataGroup,
    from,
    to,
    timePeriod,
    timezone
  ]); // eslint-disable-line

  const onModeChange = useCallback(() => {
    setDataMode(
      dataMode === dataModeOptions.TIME_PERIOD
        ? dataModeOptions.TIME_BETWEEN
        : dataModeOptions.TIME_PERIOD
    );
  }, [dataMode]);

  useEffect(() => {
    clearWeatherStationState();
    refresh();
  }, [id]); // eslint-disable-line

  return (
    <div className={styles.barHolder}>
      <div>
        <Button.Group className={styles.buttonGroup}>
          <Button
            onClick={onModeChange}
            className={
              dataMode === dataModeOptions.TIME_PERIOD
                ? styles.buttonActive
                : null
            }
          >
            <Icon className="tuf-step-backward" />
          </Button>
          <Button
            onClick={onModeChange}
            className={
              dataMode === dataModeOptions.TIME_BETWEEN
                ? styles.buttonActive
                : null
            }
          >
            <Icon className="tuf-calendar-alt" />
          </Button>
        </Button.Group>
      </div>
      <div>
        <Dropdown
          options={dataGroupOptions}
          value={dataGroup}
          onChange={(e, data) => {
            const newTimePeriodOptions = dataGroupOptions.find(
              option => option.value === data.value
            ).timePeriodOptions;
            setDataGroup(data.value);
            setTimePeriodOptions(newTimePeriodOptions);
            setTimePeriod(newTimePeriodOptions[0].value);
          }}
        />
      </div>
      {dataMode === dataModeOptions.TIME_BETWEEN ? (
        <div className={styles.barHolder}>
          <div>
            <div className={styles.barHolderIn}>
              <div>From:</div>
              <DatePicker
                value={from}
                onChange={date => setFrom(date)}
                size="small"
                className={styles.dateInput}
              />
            </div>
          </div>
          <div>
            <div className={styles.barHolderIn}>
              <div>To:</div>
              <DatePicker
                value={to}
                onChange={date => setTo(date)}
                size="small"
                className={styles.dateInput}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Dropdown
            options={timePeriodOptions}
            value={timePeriod}
            onChange={(_, data) => setTimePeriod(data.value)}
          />
        </div>
      )}
      <div className={styles.barHolder}>
        <div>
          <Button
            onClick={() => setShowCharts(!showCharts)}
            className={showCharts ? styles.buttonActive : null}
          >
            <Icon name="area chart" />
          </Button>
        </div>
        <div>
          <Button
            onClick={() => setShowDataTable(!showStationDataTable)}
            className={showStationDataTable ? styles.buttonActive : null}
          >
            <Icon name="table" />
          </Button>
        </div>
      </div>
      <div>
        <Button primary onClick={refresh}>
          <Icon name="repeat" />
          <span>Refresh</span>
        </Button>
      </div>
    </div>
  );
};

StationsControlBar.propTypes = {
  id: PropTypes.number.isRequired,
  actions: PropTypes.object,
  timezone: PropTypes.string,
  showCharts: PropTypes.bool,
  showStationDataTable: PropTypes.bool
};

StationsControlBar.defaultProps = {};

const mapStateToProps = state => {
  const {
    weatherStation: {
      stationData: { showCharts, showStationDataTable }
    }
  } = state;
  return {
    showCharts,
    showStationDataTable,
    timezone: createTimezoneSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        clearWeatherStationState,
        getFormattedWeatherStationsDataBetween,
        setShowCharts,
        setShowDataTable
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StationsControlBar);
