import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { setStatus, setTaskName } from "../../../../actions/SprayDiary/common";
import { Form, Grid, Header, Input } from "semantic-ui-react";
import EmployeeList from "../Employee/EmployeeList";
import AdditionalInformation from "../AdditionalInformation";
import StatusDropdown from "../../../../components/StatusDropdown";
import WorkingTimes from "../WorkingTimes/WorkingTimes";
import SprayTaskPlannedDatePicker from "../SprayTaskPlannedDatePicker/SprayTaskPlannedDatePicker";
import SprayTaskExpiryDatePicker from "../SprayTaskExpiryDatePicker/SprayTaskExpiryDatePicker";
import InterventionTypeList from "../InterventionTypeList";
import ShedsList from "../ShedsList";
import { TASK_NAME_MAX_LENGTH } from "constants/SprayDiary";
import { fetchListSheds } from "../../../../actions/Sheds";
import { abilitiesSelector } from "selectors/user";
import "./Sidebar.module.css";
import ApplicationType from "../ApplicationType";

const Sidebar = ({
  id,
  status,
  taskName,
  isOverlapping,
  list,
  actions: { setStatus, setTaskName },
  isPreviouslyCreatedTask
}) => {
  const Can = useSelector(abilitiesSelector);
  const isCompleted = status === "COMPLETED";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchListSheds(true));
  }, []);
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column className="spraySection">
          {id ? (
            <Header as="h2" className="title">
              AT-{id}
            </Header>
          ) : (
            <Header as="h2" className="title">
              Task Information
            </Header>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          computer={9}
          tablet={10}
          mobile={10}
          className="sprayField required spraySection"
        >
          <Form.Field required className="sprayField">
            <label className="title">Status</label>
            <StatusDropdown
              fluid
              closeOnChange
              noResultsMessage="Nothing was found"
              button
              selection
              value={status}
              onChange={(_, data) => setStatus(data.value)}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      {isCompleted && (
        <WorkingTimes isOverlapping={isOverlapping} test={1111} />
      )}
      <SprayTaskPlannedDatePicker title="Planned date" />
      <SprayTaskExpiryDatePicker title="Expiry date" />
      <ApplicationType />
      <EmployeeList
        type="Supervisors"
        fieldName="supervisors"
        multiple
        required
        showArchived={isPreviouslyCreatedTask}
      />
      <Grid.Row>
        <Grid.Column className="spraySection">
          <Form.Field className="sprayField">
            <label className="title">Name</label>
            <Input
              value={taskName}
              fluid
              onChange={(a, { value }) => {
                setTaskName(value);
              }}
              name="taskName"
              maxLength={TASK_NAME_MAX_LENGTH}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <EmployeeList
        type="Operators"
        fieldName="assignees"
        multiple
        showArchived={isPreviouslyCreatedTask}
      />
      <InterventionTypeList />
      {!!list.content.length && (
        <Can I={"access_module"} a="inventory">
          <ShedsList />
        </Can>
      )}
      <AdditionalInformation />
    </Grid>
  );
};

Sidebar.propTypes = {
  actions: PropTypes.object,
  isPreviouslyCreatedTask: PropTypes.bool,
  id: PropTypes.number,
  onTaskChange: PropTypes.func,
  status: PropTypes.string,
  taskName: PropTypes.string
};

const mapStateToProps = ({
  sprayDiary: { id, status, taskName },
  shed: { list }
}) => ({
  list,
  id,
  status,
  taskName
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setStatus,
      setTaskName
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
