import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { Formik, Field } from "formik";
import styles from "./Workers.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateQC } from "actions/QualityControl";
import { employeeList } from "selectors/employee";
import { getEmployeeList } from "actions/SprayDiary/employee";
import { workersFilterOptions } from "selectors/harvestUnits";
import CustomSlider from "./CustoSlider/CustomSLider";
import SingleSelect from "components/Table/components/Filters/SingleSelect";

const WorkersAdd = ({ activeItem, setAddCheck, activeRounds }) => {
  const dispatch = useDispatch();
  const employees = useSelector(employeeList);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [activeValue, setActiveValue] = useState(null);

  const [sliderValue, setSliderValue] = useState(0);

  const onSubmit = data => {
    dispatch(updateQC(activeItem?.id, data));
  };
  const onAddWorker = (activeItem, values, activeRounds) => {
    const dataToSend = {
      barcodeId: activeItem?.barcodeId,
      createdAt: activeItem?.createdAt,
      id: activeItem?.id,
      name: activeItem?.name,
      unitToCheckPerRound: activeItem?.unitToCheckPerRound,
      qcRounds: activeItem?.qcRounds.map(item => {
        return values.roundNumber === item.roundNumber
          ? {
            ...item,
            employeeResults: [
              ...activeRounds?.employeeResults,
              ...values.employeeResults
            ]
          }
          : item;
      }),
      rounds: activeItem?.rounds
    };
    onSubmit(dataToSend);
  };

  const onAddAllWorker = (activeItem, values, activeRoundsd) => {
    const dataToSend = {
      barcodeId: activeItem?.barcodeId,
      createdAt: activeItem?.createdAt,
      id: activeItem?.id,
      name: activeItem?.name,
      unitToCheckPerRound: activeItem?.unitToCheckPerRound,
      qcRounds: activeItem?.qcRounds.map(item => {
        return values.roundNumber === item.roundNumber
          ? {
            ...item,
            employeeResults: [
              ...activeRounds?.employeeResults,
              ...values.employeeResults
            ]
          }
          : {
            ...item,
            employeeResults: [
              ...item.employeeResults,
              ...values.employeeResults
            ]
          };
      }),
      rounds: activeItem?.rounds
    };
    onSubmit(dataToSend);
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={{
        ...activeRounds,
        employeeResults: [{ id: null, employeeId: null, rate: 0 }]
      }}
    >
      {({ values, dirty, errors, isValid, setFieldValue, setFieldError }) => {
        const allUser = activeItem?.qcRounds
          .filter(r => r.roundNumber !== activeRounds?.roundNumber)
          .map(i => i.employeeResults)
          .flat();
        return (
          <>
            <div className={styles.root}>
              <Form>
                <div className={styles.rowWrapper}>
                  <div className={styles.container}>
                    <Form.Field required className={styles.formField}>
                      <label className={styles.label}>Worker name</label>
                      <Field
                        fluid
                        className={styles.selectWorkersField}
                        required
                        as={SingleSelect}
                        search={true}
                        selector={workersFilterOptions}
                        value={values.employeeBinParts?.map(
                          item => item?.employeeId
                        )}
                        text={
                          values.employeeResults?.length
                            ? employees.find(i => {
                              return (
                                i.key === values.employeeResults[0].employeeId
                              );
                            })?.text
                            : ""
                        }
                        name="employeeResults"
                        onChange={(_, value) => {
                          setActiveValue(value);
                          if (
                            activeRounds?.employeeResults.some(
                              i => i.employeeId === value
                            )
                          ) {
                            setFieldError(
                              "workers",
                              `Тhis user has already been added`
                            );
                          } else {
                            setFieldError("workers", undefined);
                          }
                          /*  if (!allUser.some((i) => i.employeeId === value)) {
                            setFieldError("workersToAll", undefined);
                          } */
                          setFieldValue(
                            "employeeResults",
                            values.employeeResults.map(i => {
                              return { ...i, employeeId: value };
                            })
                          );
                        }}
                        loaderAction={getEmployeeList}
                      />
                    </Form.Field>
                    <CustomSlider
                      setSliderValue={setSliderValue}
                      setFieldValue={setFieldValue}
                      values={values}
                      item={values.employeeResults[0]}
                      sliderValue={sliderValue}
                    />
                  </div>
                  {errors["workers"] && (
                    <div style={{ color: "#D92D20" }}>{errors["workers"]}</div>
                  )}
                  <Modal
                    className={styles.messageModal}
                    onClose={() => {
                      return (
                        setOpenAddModal(false),
                        setFieldError("workersToAll", undefined)
                      );
                    }}
                    open={openAddModal}
                    size="tiny"
                  >
                    <Modal.Content>
                      {` Add worker  ${employees.find(i => {
                        return i.key === values.employeeResults[0].employeeId;
                      })?.text
                        } to all rounds?`}
                      {errors["workersToAll"] && (
                        <div style={{ color: "#D92D20" }}>
                          {errors["workersToAll"]}
                        </div>
                      )}
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        content="Add to current"
                        className={styles.addCurrButton}
                        onClick={() => {
                          return (
                            onAddWorker(activeItem, values, activeRounds),
                            setOpenAddModal(false),
                            setAddCheck(false)
                          );
                        }}
                      />
                      <Button
                        content="Add to all"
                        className={styles.addAllButton}
                        onClick={() => {
                          if (allUser.some(i => i.employeeId === activeValue)) {
                            setFieldError(
                              "workersToAll",
                              `Тhis user has already been added in another round`
                            );
                          } else {
                            setFieldError("workersToAll", undefined);
                            return (
                              onAddAllWorker(activeItem, values, activeRounds),
                              setOpenAddModal(false),
                              setAddCheck(false)
                            );
                          }
                        }}
                        positive
                      />
                    </Modal.Actions>
                  </Modal>
                </div>
              </Form>
            </div>
            <div className={styles.btnWrapper}>
              <Button
                content="Cancel"
                type="text"
                onClick={() => {
                  setAddCheck(false);
                }}
              />
              <Button
                content={"Save"}
                primary
                onClick={() => {
                  return setOpenAddModal(true);
                }}
                disabled={
                  !isValid ||
                  !dirty ||
                  !values.employeeResults[0].employeeId ||
                  values.employeeResults[0].rate === 0
                }
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default WorkersAdd;
