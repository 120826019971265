import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "semantic-ui-react";
import ReactDOMServer from "react-dom/server";

import { copyStyles } from "../../../../../utils/styles";
import SelectPrintLayout, { PrintLayouts } from "./SelectPrintLayout";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const PrintPalletsLabelButton = ({
  pallets,
  children,
  printLayout,
  ...extraProps
}) => {
  const print = useCallback(() => {
    const newWindow = window.open();
    newWindow.document.title = ``;
    copyStyles(window.document, newWindow.document);
    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      PrintLayouts[printLayout].html.render(pallets)
    );
    newWindow.focus();
    setTimeout(function() {
      newWindow.print();
    }, 2000);
  }, [pallets, printLayout]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={11}>
          <SelectPrintLayout />
        </Grid.Column>
        <Grid.Column width={5}>
          <Button primary {...extraProps} type="button" onClick={print}>
            {children}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

PrintPalletsLabelButton.propTypes = {
  pallets: PropTypes.array.isRequired,
  children: PropTypes.node,
  printLayout: PropTypes.any
};

PrintPalletsLabelButton.defaultProps = {};

const mapStateToProps = state => {
  const {
    pallets: { printLayout }
  } = state;
  return {
    printLayout
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintPalletsLabelButton);
