import React, { useState } from "react";
import { Button, Form, Modal, Icon } from "semantic-ui-react";
import { connect, useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { Formik, Field } from "formik";

import styles from "./AddUpdateGroupModal.module.css";
import { abilitiesSelector } from "../../../../../selectors/user";
import { bindActionCreators } from "redux";
import {
  addNewLaborGroup,
  clearFormData,
  setFormData,
  updateLaborGroup
} from "../../../../../actions/LaborGroups/laborGroups";
import { getEmployeeList } from "../../../../../actions/Contractors/contractors";
import { fetchEmployees } from "../../../../../actions/Employee/employees";
import MultiSelect from "../../../../../components/MultiSelect/MultiSelect";
import { employeeOptionsSelector } from "../../../../../selectors/employee";

const REQUIRED_FIELDS = ["name", "employeeIds"];

const AddUpdateGroupModal = ({ update, open, onClose, item }) => {
  const dispatch = useDispatch();
  const { options } = useSelector(employeeOptionsSelector);

  const validateValues = async values => {
    const errors = {};
    const { name, employeeIds } = values;

    REQUIRED_FIELDS.forEach(fieldName => {
      if (!get(values, fieldName)) {
        errors[fieldName] = "Required field";
      }
    });

    if (isEmpty(name)) {
      errors.name = true;
    }

    if (!employeeIds.length) {
      errors.employeeIds = true;
    }

    return errors;
  };

  function onSubmit(values) {
    !!item
      ? dispatch(updateLaborGroup(values.id, values))
          .then(onClose)
          .then(update)
      : dispatch(addNewLaborGroup(values))
          .then(onClose)
          .then(update);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="mini"
      onClick={e => e.stopPropagation()}
      className={styles.root}
      closeOnDimmerClick={false}
    >
      <div className={styles.modalHeader}>{!!item ? "Edit" : "Add"} Group</div>
      <Formik
        validate={validateValues}
        enableReinitialize
        validateOnChange={true}
        onSubmit={onSubmit}
        initialValues={
          item
            ? {
                name: item.name,
                id: item.id,
                employeeIds: item.employeeIds
              }
            : { name: "", employeeIds: [] }
        }
      >
        {props => {
          return (
            <>
              <Modal.Content className={styles.modalContentContainer}>
                <Form>
                  <Form.Group>
                    <Field name="name" required width={16}>
                      {({ field, meta }) => (
                        <Form.Input
                          width={16}
                          label="Group name"
                          error={meta.touched && meta.error}
                          required
                          {...field}
                        />
                      )}
                    </Field>
                  </Form.Group>
                  <Form.Group className={styles.selectWorkersFields}>
                    <Form.Field required width={16}>
                      <label>Select workers</label>
                      <Field
                        fluid
                        className={styles.selectWorkersField}
                        required
                        as={MultiSelect}
                        search={true}
                        width={16}
                        text={
                          props.values.employeeIds.length
                            ? `${props.values.employeeIds.length} workers`
                            : ""
                        }
                        selector={employeeOptionsSelector}
                        value={props.values.employeeIds}
                        name="employeeIds"
                        loaderAction={getEmployeeList}
                        onOptionChange={props.setFieldValue}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <div className={styles.workerElementsContainer}>
                      {props.values.employeeIds.map(employee => (
                        <div key={employee} className={styles.workerElement}>
                          <span>
                            {
                              options.find(option => option.value === employee)
                                .label
                            }
                          </span>
                          <Icon
                            name="close"
                            onClick={() => {
                              props.setFieldValue(
                                "employeeIds",
                                props.values.employeeIds.filter(
                                  id => id !== employee
                                )
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Cancel"
                 type="text"
                  onClick={onClose}
                />
                <Button
                  content={!!item ? "Save" : "Add"}
                  primary
                  onClick={props.submitForm}
                  disabled={
                    !props.isValid || !props.dirty || props.isValidating
                  }
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = state => {
  const {
    laborGroups: {
      isFetching,
      data,
      data: { content }
    }
  } = state;

  return {
    data,
    isFetching,
    Can: abilitiesSelector(state),
    laborGroups: content,
    laborGroupForm: state.laborGroups.laborForm,
    employeesList: state.employee.data.content,
    employeesFetching: state.employee.isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFormData,
      clearFormData,
      addNewLaborGroup,
      updateLaborGroup,
      fetchEmployees
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUpdateGroupModal);
