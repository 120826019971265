import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { diff } from "deep-object-diff";
import "react-circular-progressbar/dist/styles.css";
import { abilitiesSelector, userSelector } from "../../../selectors/user";
import Layout from "../../../components/Layout";
import { Segment, Sidebar, Confirm, Grid, Header } from "semantic-ui-react";
import { hashToObject } from "../../../utils/hashToObject";

import HeaderSprayTasks from "../HeaderSprayTasks";
import TableSprayTask from "../../../components/TableSprayTask";

import TaskDetailsMobile from "../TaskDetailsMobile";
import RowHeader from "./RowHeader";
import Loader from "../../../components/Loader";
import {
  setTaskBlockDone,
  deleteSprayTasks,
  fetchSprayTasks,
  setFieldValue
} from "../../../actions/Spray/tasks";
import {
  taskSelector,
  sortOptionsSelector,
  paginationSelector
} from "../../../selectors/spray";
import { onlineSelector } from "../../../selectors/common";
import {
  createTask,
  getTaskById,
  fetchTaskById
} from "actions/SprayDiary/common";
import CreateSprayTaskPrompt from "../CreateSprayTaskPrompt";
import asyncForEach from "../../../utils/asyncForEach";
import SortDropdown from "../../../components/SortDropdown";
import Pagination from "../../../components/ListTable/Pagination";
import TasksFilters from "../TasksFilters";
import { collectBlockData } from "../Utils/collectBlockData";
import generatePrintPage from "../TaskDetails/generatePrintPage";
import styles from "./Tasks.module.css";
import { get, replace } from "lodash";
import { calculateChemicalQuantity } from "utils/chemicalBreakdownUtils";

class Tasks extends PureComponent {
  state = {
    showSidebar: !!this.props.activeTask,
    taskToDelete: null,
    showConfirm: false,
    sortOrder: null,
    sortValue: "none",
    pageSize: null
  };

  componentDidMount() {
    const { sort, actions, activeTaskMatch } = this.props;

    if (activeTaskMatch) {
      actions.getTaskById(activeTaskMatch);
    }
    if (sort && sort[0]) {
      const sortOptions = sort[0].split(",");
      this.setState({
        sortOrder: sortOptions[1],
        sortValue: sortOptions[0]
      });
    }
    this.setAllSeen();
    document.body.addEventListener("click", this.handleBlur);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeTask, tasks, activeTaskMatch, actions } = this.props;

    if (activeTaskMatch && activeTaskMatch !== prevProps.activeTaskMatch) {
      actions.getTaskById(activeTaskMatch);
    }
    if (tasks !== prevProps.tasks) {
      this.setAllSeen(prevProps.tasks);
    }

    if (!activeTask && prevProps.activeTask) {
      this.setState({
        showSidebar: false
      });
    } else if (activeTask && !prevProps.activeTask) {
      this.setState({
        showSidebar: true
      });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBlur);
  }

  handleBlur = event => {
    if (
      !(
        event.target.closest("#tasksListHolder") ||
        event.target.closest("#sidebarHolder") ||
        event.target.closest(".modal")
      )
    ) {
      this.handleHideTask();
    }
  };

  handleHideTask = () => {
    this.setState({ showSidebar: false });
  };

  setAllSeen = async prevTasks => {
    const { actions, user, tasks } = this.props;

    const tasksIds = tasks.content.map(task => task.id);
    const prevTasksIds = prevTasks
      ? prevTasks.content.map(task => task.id)
      : [];
    const diffKeys = Object.keys(diff(tasksIds, prevTasksIds));
    if (diffKeys.length === 0) {
      return;
    }

    const tasksToSet =
      tasks && user
        ? tasks.content.filter(task =>
            task.supervisors.some(
              supervisor =>
                supervisor.employee &&
                supervisor.employee.id === user.profile.employee_id &&
                !supervisor.seen
            )
          )
        : [];

    await asyncForEach(tasksToSet, async task => {
      await actions.createTask({
        id: task.id,
        data: {
          supervisors: [
            {
              employee: {
                id: user.profile.employee_id
              },
              seen: true,
              action: "UPDATE"
            }
          ]
        },
        offlineMode: !this.props.online
      });
    });
  };

  setActiveTask = taskToSetId => {
    const {
      activeTask,
      route,
      navigate,
      activeFiltersMatch,
      location
    } = this.props;

    if (taskToSetId && taskToSetId !== Number(activeFiltersMatch)) {
      navigate(
        `${route.href}/${activeFiltersMatch || ``}/${taskToSetId}${
          location.hash
        }`,
        { replace: true }
      );
    } else if (taskToSetId && (!activeTask || taskToSetId !== activeTask.id)) {
      navigate(
        `${route.href}/${activeFiltersMatch || ``}/${taskToSetId}${
          location.hash
        }`,
        { replace: true }
      );
    } else if (!taskToSetId) {
      navigate(
        activeFiltersMatch
          ? `${route.href}/${activeFiltersMatch}${location.hash}`
          : `${route.href}${location.hash}`,
        { replace: true }
      );
    } else {
      this.handleHideTask();
    }
  };

  changeBlockDone = (taskId, blockId, blockType, done = false) => {
    const { actions } = this.props;
    actions.setTaskBlockDone({
      taskId,
      blockId,
      done,
      blockType: blockType === "patch" ? "patches" : "blocks"
    });
  };

  handleDeleteTask = (event, taskToDelete) => {
    event.stopPropagation();
    this.setState({
      taskToDelete,
      showConfirm: true
    });
  };

  handlePrintTask = async (event, shortTask) => {
    const {
      interventionTypes,
      strenghts,
      areasList,
      showParentBlocks,
      rowsToSpray,
      widthPerRow,
      conc
    } = this.props;

    event.stopPropagation();
    const task = await fetchTaskById(shortTask.id);
    const selectedAreas = task.areas.map(area => ({
      ...area.area,
      ...area
    }));
    const selectedChemicals = task.chemicals.map(chemical => ({
      ...chemical,
      ...chemical.chemical,
      rate: {
        ...chemical.chemicalRate,
        rate: chemical.dilutionRate
      }
    }));
    const totalChemicals = selectedChemicals.map(chemical => ({
      units: chemical.rate.unit,
      quantity: selectedAreas.reduce(
        (prev, { size, actualTanks, tanks, plannedTanks }) => {
          const { quantity: predictedQuantity } = calculateChemicalQuantity(
            chemical,
            size,
            rowsToSpray,
            widthPerRow,
            task.literPerHectare,
            conc
          );
          let quantity = predictedQuantity;
          if (
            actualTanks !== null &&
            actualTanks !== undefined &&
            actualTanks !== (tanks || plannedTanks)
          ) {
            quantity =
              (predictedQuantity * actualTanks) / (tanks || plannedTanks);
          }

          return prev + quantity;
        },
        0
      )
    }));
    const blocksData = collectBlockData(task, areasList, showParentBlocks);
    const spayCondition = {
      windStrength: task.windStrength,
      temperature: task.temperature,
      strenghts,
      deltaT: task.deltaT,
      windDirection: task.windDirection
    };
    const newWindow = window.open();

    newWindow.document.title = `AT-${task.id || "New"}`;
    newWindow.document.body.innerHTML = generatePrintPage(
      { ...task, id: task.id || "New" },
      blocksData,
      spayCondition,
      interventionTypes,
      showParentBlocks,
      totalChemicals,
      selectedChemicals,
      selectedAreas,
      rowsToSpray,
      widthPerRow,
      task.literPerHectare,
      conc
    );
    newWindow.focus();
    setTimeout(() => {
      newWindow.print();
    }, 100);
  };

  hideConfirm = () => {
    this.setState({
      taskToDelete: null,
      showConfirm: false
    });
  };

  proceedDelete = () => {
    const { taskToDelete } = this.state;
    const { actions } = this.props;

    actions.deleteSprayTasks(taskToDelete);

    this.hideConfirm();
  };

  onSortChange = ({ sortValue, sortOrder, sort }) => {
    const { actions, queryString, pagination } = this.props;
    this.setState({ sortValue, sortOrder });
    actions.setFieldValue({ fieldName: "sort", fieldValue: sort });

    actions.fetchSprayTasks({
      search: queryString,
      filters: this.props.activeFilter,
      sort,
      pagination: { ...pagination, page: 0 }
    });
  };

  onPageSizeChange = async (_, data) => {
    const { actions, queryString, sort } = this.props;
    actions.fetchSprayTasks({
      search: queryString,
      filters: this.props.activeFilter,
      sort,
      pagination: { page: 0, size: data.value }
    });
  };

  onPageNumChange = (page, loadMore) => {
    const { actions, queryString, sort, pagination } = this.props;
    actions.fetchSprayTasks({
      search: queryString,
      filters: this.props.activeFilter,
      sort,
      pagination: { ...pagination, page },
      loadMore
    });
  };

  render() {
    const {
      route,
      location,
      tasks,
      isFetching,
      statuses,
      dilutionRateTypes,
      navigate,
      Can,
      activeTask,
      online,
      activeFiltersMatch,
      activeTaskMatch,
      sortOptions,
      pagination,
      isFetchingCurrentTask
    } = this.props;
    const { showSidebar, showConfirm, sortValue, sortOrder } = this.state;
    return (
      <Layout
        route={route}
        location={location}
        classForMain={styles.mainHolder}
      >
        <Confirm
          open={showConfirm}
          onCancel={this.hideConfirm}
          onConfirm={this.proceedDelete}
          confirmButton="Yes"
        />
        <Sidebar.Pushable className={styles.farmsHolder}>
          <Sidebar.Pusher>
            <HeaderSprayTasks
              navigate={navigate}
              tasks={tasks}
              route={route}
              statuses={statuses}
              Can={Can}
              online={online}
              activeFiltersMatch={activeFiltersMatch}
              activeTaskMatch={activeTaskMatch}
            />
            <Segment>
              <Grid className={styles.filtersGrid} verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={12}
                    computer={12}
                    largeScreen={12}
                  >
                    <TasksFilters
                      navigate={navigate}
                      location={location}
                      activeFiltersMatch={activeFiltersMatch}
                      activeTaskMatch={activeTaskMatch}
                      route={route}
                      online={online}
                      defaultSort="date,desc"
                    />
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={4}
                    computer={4}
                    largeScreen={4}
                    floated="right"
                    textAlign="right"
                  >
                    <SortDropdown
                      navigate={navigate}
                      sortOptions={sortOptions}
                      onChange={this.onSortChange}
                      sortValue={sortValue}
                      sortOrder={sortOrder}
                      disabled={!online}
                      silentOnMount
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {isFetching ? (
                <Loader className={styles.tasksLoader} />
              ) : tasks && tasks.content && tasks.content.length > 0 ? (
                <>
                  <Sidebar
                    animation="overlay"
                    direction="right"
                    visible={activeTaskMatch}
                    className={styles.sidebarHolder}
                    onHidden={this.setActiveTask}
                    id="sidebarHolder"
                  >
                    {isFetchingCurrentTask ? (
                      <Loader />
                    ) : activeTask ? (
                      <TaskDetailsMobile
                        navigate={navigate}
                        task={activeTask}
                        setActiveTask={this.setActiveTask}
                        Can={Can}
                        handlePrintTask={this.handlePrintTask}
                        onCloseSidebar={() => this.setActiveTask(null)}
                      />
                    ) : null}
                  </Sidebar>
                  <Header as="h3">
                    Tasks:{" "}
                    <span className="show-sm-inline">
                      {pagination.itemsCount.itemFrom}
                    </span>
                    <span className="hide-sm-inline">1</span>-
                    {pagination.itemsCount.itemTo} /{" "}
                    {pagination.totalElements || "..."} items
                  </Header>
                  <RowHeader Can={Can} />
                  <Segment
                    basic
                    className={styles.tasksListHolder}
                    id="tasksListHolder"
                  >
                    {tasks.content.map(task => (
                      <TableSprayTask
                        task={task}
                        key={`task_${task.id || task._id}`}
                        statuses={statuses}
                        dilutionRateTypes={dilutionRateTypes}
                        setActiveTask={this.setActiveTask}
                        handleDeleteTask={this.handleDeleteTask}
                        handlePrintTask={this.handlePrintTask}
                        Can={Can}
                      />
                    ))}
                    <Pagination
                      pageSize={pagination.size}
                      currentPage={pagination.page}
                      pages={pagination.totalPages}
                      totalElements={pagination.totalElements}
                      itemsCount={pagination.itemsCount}
                      updatePageSize={this.onPageSizeChange}
                      onPageChangeOwn={this.onPageNumChange}
                      online={online}
                    />
                  </Segment>
                </>
              ) : activeFiltersMatch ? (
                <Header as="h4">Nothing found</Header>
              ) : (
                <CreateSprayTaskPrompt
                  navigate={navigate}
                  route={route}
                  online={online}
                  Can={Can}
                />
              )}
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Layout>
    );
  }
}

Tasks.propTypes = {
  areasList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  route: PropTypes.object.isRequired,
  tasks: PropTypes.object,
  statuses: PropTypes.object,
  dilutionRateTypes: PropTypes.object,
  isFetching: PropTypes.bool,
  actions: PropTypes.object,
  activeTask: PropTypes.object,
  userType: PropTypes.string,
  strenghts: PropTypes.array,
  Can: PropTypes.func,
  user: PropTypes.object,
  online: PropTypes.bool,
  activeFiltersMatch: PropTypes.string,
  activeTaskMatch: PropTypes.string,
  sortOptions: PropTypes.array,
  showParentBlocks: PropTypes.bool,
  location: PropTypes.object,
  interventionTypes: PropTypes.object,
  queryString: PropTypes.string,
  sort: PropTypes.array,
  pagination: PropTypes.object
};

Tasks.defaultProps = {
  route: { name: "{% name %}" }
};

function mapStateToProps(state, ownProps) {
  const hashObject = hashToObject(ownProps.location.hash);
  const sortOptions = hashObject.sort || null;
  const {
    spray: {
      tasks: {
        isFetching,
        statuses,
        dilutionRateTypes,
        interventionTypes,
        data
      },
      queryString,
      sort
    },
    applicationSettings: { showParentBlocks },
    sprayDiary: {
      activeFilter,
      strenghts,
      areasList,
      originalTask,
      isFetching: isFetchingCurrentTask,
      selectedChemicals,
      selectedAreas,
      rowsToSpray,
      widthPerRow,
      literPerHectare,
      conc
    }
  } = state;
  return {
    data,
    activeFilter,
    areasList,
    tasks: taskSelector(state, { sortOptions }),
    isFetching,
    isFetchingCurrentTask,
    statuses,
    dilutionRateTypes,
    interventionTypes,
    activeTask: Object.keys(originalTask || {}).length ? originalTask : null,
    strenghts,
    user: userSelector(state),
    Can: abilitiesSelector(state),
    online: onlineSelector(state),
    activeFiltersMatch: ownProps.match.params.activeFilters,
    activeTaskMatch: ownProps.match.params.activeTask,
    match: null,
    sortOptions: sortOptionsSelector(state),
    queryString,
    sort,
    showParentBlocks,
    pagination: paginationSelector(state),
    selectedChemicals,
    selectedAreas,
    rowsToSpray,
    widthPerRow,
    literPerHectare,
    conc
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setTaskBlockDone,
        deleteSprayTasks,
        createTask,
        fetchSprayTasks,
        setFieldValue,
        getTaskById
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
