import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "components/Loader";
import PropTypes from "prop-types";
import { Checkbox, Table, Button } from "semantic-ui-react";
import styles from "./Parametrs.module.css";

const Parametrs = ({
  activeItem,
  setParameterAddCheck,
  activeRounds,
  isFetching,
  isFetchingBarcode
}) => {
  const [active, setActive] = useState(false);
  return isFetching || isFetchingBarcode ? (
    <Loader />
  ) : (
    <>
      <div className={styles.checkboxWrapper}>
        {activeRounds?.defectReports?.some(
          i => i.minorAmount || i.majorAmount
        ) && (
          <>
            <span className={styles.checkboxLabel}>
              Specify the minor / major
            </span>
            <Checkbox
              toggle
              className={styles.checkbox}
              checked={active}
              onChange={(_, data) => setActive(!active)}
            />
          </>
        )}
      </div>
      <Table className={styles.parametrsTable} celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.parametrsHeader}>
              Parameter
            </Table.Cell>
            {active && (
              <Table.Cell className={styles.parametrsHeader}>Minor</Table.Cell>
            )}
            {active && (
              <Table.Cell className={styles.parametrsHeader}>Major</Table.Cell>
            )}
            <Table.Cell className={styles.parametrsHeader}>Total</Table.Cell>
          </Table.Row>
          {activeRounds?.defectReports?.map(item => {
            return (
              <>
                <Table.Row>
                  <Table.Cell className={styles.parametrsValue}>
                    {item.parameter
                      .replace(/_/g, " ")
                      .replace(
                        /^(.)(.*)$/,
                        (_, first, rest) =>
                          first.toUpperCase() + rest.toLowerCase()
                      )}
                  </Table.Cell>
                  {active && (
                    <Table.Cell className={styles.parametrsValue}>
                      {item.minorAmount || "-"}
                    </Table.Cell>
                  )}
                  {active && (
                    <Table.Cell className={styles.parametrsValue}>
                      {item.majorAmount || "-"}
                    </Table.Cell>
                  )}
                  <Table.Cell className={styles.parametrsValue}>
                    {item.majorAmount && item.minorAmount
                      ? +item.majorAmount + +item.minorAmount
                      : item.majorAmount
                      ? item.majorAmount
                      : item.minorAmount}
                  </Table.Cell>
                </Table.Row>
              </>
            );
          })}
        </Table.Body>
      </Table>
      {activeItem?.qcRounds.length < 2 && (
        <div className={styles.addBtnWrapper}>
          <Button
            content="+ Add a parameter"
            primary
            onClick={() => {
              setParameterAddCheck(true);
            }}
          />
        </div>
      )}
    </>
  );
};

Parametrs.propTypes = {
  activeRounds: PropTypes.object
};

export default connect((state, props) => {
  return {
    isFetching: state.qualityControl.data.isFetching,
    isFetchingBarcode: state.generalBarcodes.isFetching
  };
})(Parametrs);
