import React, { useState } from "react";
import {
  Accordion,
  Grid,
  Header,
  Icon,
  Image as SemanticImage,
  Segment
} from "semantic-ui-react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { scoutingAreaIssueShape } from "constants/Scouting/types";
import { getReadableIssueType } from "utils/scouting";
import styles from "../ScoutingSidebar.module.css";

const ScoutingSidebarIssuesAccordion = ({ areaIssues, onSelectIssue }) => {
  const [activeIndex, setActiveIndex] = useState();
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    setActiveIndex(activeIndex => (activeIndex === index ? -1 : index));
  };

  return (
    <Accordion fluid>
      {areaIssues.map((issue, index) => {
        const attachment = get(issue, "attachments[0].attachment");
        const title = `Issue ${index + 1}: ${getReadableIssueType(issue.type)}`;

        return (
          <Segment className={styles.issueContainer} key={index}>
            <Accordion.Title
              active={index === activeIndex}
              index={index}
              onClick={handleClick}
              content={title}
              className={styles.issueTitle}
            />
            <Accordion.Content active={index === activeIndex}>
              <Grid columns="equal">
                <Grid.Column width={10}>
                  <Grid columns="equal">
                    <Grid.Row>
                      {!!issue.pressureRating && (
                        <>
                          <Grid.Column width={8}>
                            <Header size={"tiny"}>
                              Pressure rating:&nbsp;
                              <b>{issue.pressureRating}/5</b>
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={4} />
                        </>
                      )}
                      <Grid.Column width={4}>
                        <Header size={"tiny"}>
                          Row:&nbsp;<b>{issue.rowNumber}</b>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    {issue.comment && (
                      <Grid.Row style={{ paddingTop: 5 }}>
                        <Grid.Column>
                          <Header style={{ marginBottom: 10 }} size={"tiny"}>
                            Comments
                          </Header>
                          <Header style={{ marginTop: 0 }} size={"tiny"}>
                            {issue.comment}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Grid>
                </Grid.Column>
                {attachment && (
                  <Grid.Column floated="right" width={5}>
                    <SemanticImage
                      className={styles.image}
                      src={attachment.presignedUrl}
                      rounded
                      verticalAlign="middle"
                      onClick={() => onSelectIssue(issue, issue.attachments[0])}
                    />
                    <Header
                      as="a"
                      color="green"
                      href={attachment.presignedUrl}
                      download
                      target="_blank"
                      size="small"
                    >
                      <Icon name="download" />
                      {attachment.name}
                    </Header>
                  </Grid.Column>
                )}
              </Grid>
            </Accordion.Content>
          </Segment>
        );
      })}
    </Accordion>
  );
};

ScoutingSidebarIssuesAccordion.propTypes = {
  areaIssues: PropTypes.arrayOf(scoutingAreaIssueShape),
  onSelectIssue: PropTypes.func
};

export default ScoutingSidebarIssuesAccordion;
