import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Grid, Header, Icon, Button } from "semantic-ui-react";
import styles from "./Sidebar.module.css";
import moment from "moment";
import { isEmptyValue } from "../../../../utils/tasksUtils";
import TaskRow from "../../../SprayDiary/TaskDetailsMobile/TaskRow";
import PrintPalletsLabelButton from "../Labels/PrintPalletsLabelButton";
import { Link } from "react-router-dom";
class Sidebar extends PureComponent {
  render() {
    const { pallet, id, setActiveItem } = this.props;
    return pallet ? (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column className={styles.wrapper} width={16}>
              <Header as="h1" className={styles.name}>
                #{pallet.id}
              </Header>
              <div className={styles.btnWrapper}>
                <Button
                  as={Link}
                  primary
                  to={`/harvest/pallets/pallet/${id}`}
                >
                  Edit
                </Button>
                <Icon
                  className={styles.closeIcon}
                  name="close"
                  onClick={() => setActiveItem(null)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <TaskRow
            label="Date"
            name="createDate"
            value={
              <span>
                {isEmptyValue(
                  pallet.createDate &&
                    moment(pallet.createDate).format("DD/MM/YYYY")
                )}
              </span>
            }
          />
          <TaskRow
            label="Variety"
            name="variety"
            value={
              <span>{isEmptyValue(pallet.variety && pallet.variety.name)}</span>
            }
          />
          <TaskRow
            label="Box unit"
            name="boxUnit"
            value={
              <span>
                {isEmptyValue(pallet.boxUnit && pallet.boxUnit.displayName)}
              </span>
            }
          />
          <TaskRow
            label="Areas"
            name="areas"
            value={
              <span>
                {isEmptyValue(
                  pallet.areas
                    .map(
                      ({ area }) =>
                        `${area.parent ? `${area.parent.name} - ` : ""}${
                          area.name
                        }`
                    )
                    .join(", ")
                )}
              </span>
            }
          />
          <TaskRow
            label="Packing shed"
            name="packingShed"
            value={
              <span>
                {isEmptyValue(pallet.packingShed && pallet.packingShed.name)}
              </span>
            }
          />
          <TaskRow
            label="Units on pallet"
            name="unitsOnPallet"
            value={<span>{isEmptyValue(pallet.unitsOnPallet)}</span>}
          />
          <TaskRow
            label="Dispatch id"
            name="dispatchId"
            value={
              <span>{isEmptyValue(pallet.dispatch && pallet.dispatch.id)}</span>
            }
          />
          <TaskRow
            label="Notes"
            name="notes"
            value={<span>{isEmptyValue(pallet.notes)}</span>}
            wide
          />
          <Grid.Row>
            <Grid.Column>
              <PrintPalletsLabelButton pallets={[pallet]}>
                Print Label
              </PrintPalletsLabelButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    ) : (
      <div>No Data</div>
    );
  }
}

Sidebar.propTypes = {
  pallet: PropTypes.object,
};

export default Sidebar;
