import React, { useState } from "react";
import {
  Button,
  Icon,
  Modal,
  Header,
  List,
  Container
} from "semantic-ui-react";
import styles from "../HarvestUnitsControl.module.css";

const HarvestUnitsControlActionButtons = ({
  onDeleteClick,
  withModal,
  selectedProducts
}) => {
  const [isArchiveConfirmShown, setArchiveConfirmShown] = useState(false);

  return (
    <>
      <Modal
        className={styles.messageModal}
        onClose={() => setArchiveConfirmShown(false)}
        open={isArchiveConfirmShown}
        size="tiny"
      >
        <Modal.Content>
          <Header className={styles.modalMessageHeader}>
            {`  Delete ${selectedProducts.length} units?`}
          </Header>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type='text'
            content="Cancel"
            onClick={() => setArchiveConfirmShown(false)}
          />
          <Button
            content="Delete"
            className={styles.deleteButton}
            onClick={onDeleteClick}
          />
        </Modal.Actions>
      </Modal>
      <Button
        size="small"
        type="delete"
        className={styles.deleteIconActionButton}
        onClick={withModal ? () => setArchiveConfirmShown(true) : onDeleteClick}
      >
        <Icon name="trash alternate" />
        Delete
      </Button>
    </>
  );
};

export default HarvestUnitsControlActionButtons;
