import { OFFLINE_STATUS_CHANGED } from "@redux-offline/redux-offline/lib/constants";
import { actionType } from "../../constants/Spray";
import { actionType as actionDiaryType } from "../../constants/SprayDiary";

const initialState = {
  tasks: {
    isFetching: false,
    error: null,
    sort: [],
    sortFields: {
      id: { name: "Task ID" },
      seen: { name: "Seen" },
      status: { name: "Status" },
      area: { name: "Areas" },
      chemical: { name: "Chemicals" },
      progress: { name: "Progress" },
      supervisor: { name: "Supervisor" },
      assignee: { name: "Assignee" },
      date: { name: "Date" }
    },
    statuses: {
      OPEN: "Open",
      IN_PROGRESS: "In progress",
      COMPLETED: "Completed",
      CANCELED: "Cancelled"
    },
    interventionTypes: {
      CORRECTIVE: "Corrective",
      PREVENTIVE: "Preventive"
    },
    dilutionRateTypes: {
      PER_HECTARE: "Ha",
      PER_100_LITERS: "100L"
    },
    data: {
      content: [],
      page: {}
    },
    contentFull: {},
    contentFiltered: {},
    dataOffline: {
      content: [],
      page: {}
    },
    routes: {
      isFetching: false
    }
  },
  records: {
    isFetching: false,
    data: {
      content: []
    },
    error: null
  },
  ids: {
    isFetching: false,
    data: {
      content: []
    },
    error: null
  },
  crops: {
    isFetching: false,
    data: {
      content: []
    },
    error: null
  },
  varieties: {
    isFetching: false,
    data: {
      content: []
    },
    error: null
  },
  batchNumbers: {
    isFetching: true,
    data: [],
    error: null
  },
  urlString: "",
  queryString: ""
};

const updateDataOffline = (storage, workerFunc, action) => {
  Object.keys(storage).forEach(item => {
    storage[item] = {
      ...storage[item],
      content: workerFunc(
        storage[item].content || [],
        action,
        storage[item].number || 0
      )
    };
  });
  return storage;
};

const tasksActions = (state, action) => {
  const initialData = { content: [], page: {} };
  const dataToUpdate = JSON.parse(
    JSON.stringify(state.tasks.data.content || [])
  );
  const dataToUpdateOffline = JSON.parse(
    JSON.stringify(state.tasks.dataOffline.content || [])
  );
  const targetStore = state.urlString ? "contentFiltered" : "contentFull";
  const checkOfflineAttachments = (task, data) => {
    if (!data.attachments) {
      return data;
    }
    const replaceIds = data.attachments
      .filter(file => file.idReplace)
      .map(file => file.idReplace);
    const dataIds = data.attachments
      .filter(file => file.id)
      .map(file => file.id);
    return data.attachments
      ? {
          ...data,
          attachments: [
            ...data.attachments,
            ...task.attachments.filter(
              file =>
                !dataIds.includes(file.id) &&
                !replaceIds.includes(file.idReplace)
            )
          ]
        }
      : data;
  };
  switch (action.type) {
    case OFFLINE_STATUS_CHANGED:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...(!action.payload.online
            ? {
                dataOffline:
                  state.tasks[targetStore][state.tasks.data.number] &&
                  state.tasks[targetStore][state.tasks.data.number].content
                    ? { ...state.tasks[targetStore][state.tasks.data.number] }
                    : state.data || initialData
              }
            : {}),
          ...(action.payload.online
            ? {
                data: state.tasks.dataOffline.content
                  ? { ...state.tasks.dataOffline }
                  : initialData
              }
            : {})
        }
      };

    case actionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.fieldValue
      };
    case actionType.SET_URL_STRING:
      return {
        ...state,
        urlString: action.payload.urlString,
        ...(!action.payload.urlString && !action.payload.online
          ? {
              tasks: {
                ...state.tasks,
                dataOffline: { ...(state.tasks.contentFull[0] || initialData) }
              }
            }
          : {})
      };
    case actionType.GET_TASKS_IDS_START:
      return {
        ...state,
        ids: {
          ...state.ids,
          error: null,
          isFetching: true
        }
      };
    case actionType.GET_TASKS_IDS:
      return {
        ...state,
        ids: {
          ...state.ids,
          isFetching: false,
          data: action.payload
        }
      };
    case actionType.GET_TASKS_IDS_FAIL:
      return {
        ...state,
        ids: {
          ...state.ids,
          error: action.payload,
          isFetching: false
        }
      };
    case actionType.GET_CROPS_START:
      return {
        ...state,
        crops: {
          ...state.crops,
          error: null,
          isFetching: true
        }
      };
    case actionType.GET_CROPS:
      return {
        ...state,
        crops: {
          ...state.crops,
          isFetching: false,
          data: action.payload
        }
      };
    case actionType.GET_CROPS_FAIL:
      return {
        ...state,
        crops: {
          ...state.crops,
          error: action.payload,
          isFetching: false
        }
      };
    case actionType.GET_VARIETIES_START:
      return {
        ...state,
        varieties: {
          ...state.varieties,
          error: null,
          isFetching: true
        }
      };
    case actionType.GET_VARIETIES:
      return {
        ...state,
        varieties: {
          ...state.varieties,
          isFetching: false,
          data: action.payload
        }
      };
    case actionType.GET_VARIETIES_FAIL:
      return {
        ...state,
        varieties: {
          ...state.varieties,
          error: action.payload,
          isFetching: false
        }
      };
    case actionType.GET_BATCH_NUMBERS_START:
      return {
        ...state,
        batchNumbers: {
          isFetching: true,
          data: [],
          error: null
        }
      };
    case actionType.GET_BATCH_NUMBERS:
      return {
        ...state,
        batchNumbers: {
          ...state.batchNumbers,
          isFetching: false,
          data: action.payload
        }
      };
    case actionType.GET_BATCH_NUMBERS_FAIL:
      return {
        ...state,
        batchNumbers: {
          ...state.batchNumbers,
          error: action.payload,
          isFetching: true
        }
      };
    case actionType.GET_SPRAY_TASKS_START:
    case actionType.SET_TASK_BLOCK_DONE_START:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: null,
          isFetching: true
        }
      };
    case actionType.GET_SPRAY_TASKS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isFetching: false,
          data: action.payload.loadMore
            ? {
                ...action.payload,
                content: [
                  ...state.tasks.data.content,
                  ...action.payload.content
                ]
              }
            : action.payload
        }
      };
    case actionType.DELETE_SPRAY_TASK_START:
      const deleteTask = content => {
        return content.filter(task =>
          action.payload.id
            ? task.id !== action.payload.id
            : action.payload._id
            ? task._id !== action.payload._id
            : true
        );
      };

      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: null,
          isFetching: !!action.payload.id,
          data: {
            ...state.tasks.data,
            content: deleteTask(dataToUpdate, action)
          },
          dataOffline: {
            ...state.tasks.dataOffline,
            content: deleteTask(dataToUpdateOffline, action)
          },
          contentFull: updateDataOffline(
            state.tasks.contentFull,
            deleteTask,
            action
          ),
          contentFiltered: updateDataOffline(
            state.tasks.contentFiltered,
            deleteTask,
            action
          )
        }
      };
    case actionType.DELETE_SPRAY_TASK:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isFetching: false
        }
      };
    case actionType.GET_SPRAY_TASKS_FAIL:
    case actionType.SET_TASK_BLOCK_DONE_FAIL:
    case actionType.DELETE_SPRAY_TASK_FAIL:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: action.payload,
          isFetching: false
        }
      };
    case actionType.SET_TASK_BLOCK_DONE:
      /*  data: res.data,
        taskId,
        blockId,
        blockType,*/

      //dataToUpdate
      const updateData = (content, action) => {
        const taskToUpdate = content.find(
          task => task.id === action.payload.taskId
        );
        if (!taskToUpdate) {
          return content;
        }
        const blockToUpdate = taskToUpdate[action.payload.blockType].find(
          block =>
            block[action.payload.blockType === "patches" ? "patch" : "block"]
              .id === action.payload.blockId
        );

        blockToUpdate.done = action.payload.done;
        return content;
      };

      return {
        ...state,
        tasks: {
          ...state.tasks,
          isFetching: false,
          data: {
            ...state.tasks.data,
            content: updateData(dataToUpdate, action)
          },
          dataOffline: {
            ...state.tasks.dataOffline,
            content: updateData(dataToUpdateOffline, action)
          },
          contentFull: updateDataOffline(
            state.tasks.contentFull,
            updateData,
            action
          ),
          contentFiltered: updateDataOffline(
            state.tasks.contentFiltered,
            updateData,
            action
          )
        }
      };
    case actionDiaryType.CREATE_TASK_START:
      return {
        ...state,
        urlString: state.urlString
        /*  action.payload.id &&
          dataToUpdate.findIndex(task => task.id === action.payload.id) >= 0
            ? state.urlString
            : "" */
      };
    case actionDiaryType.SET_ATTACHMENTS:
      const updateAttachments = (content, action) => {
        const updateTaskIndex = content.findIndex(
          task =>
            action.payload.parentId &&
            (task.id === action.payload.parentId ||
              task._id === action.payload.parentId)
        );
        if (updateTaskIndex >= 0) {
          content[updateTaskIndex] = {
            ...content[updateTaskIndex],
            attachments: action.payload.attachments
          };
        }

        return content;
      };

      return {
        ...state,
        tasks: {
          ...state.tasks,
          data: {
            ...state.tasks.data,
            content: updateAttachments(dataToUpdate, action)
          },
          dataOffline: {
            ...state.tasks.dataOffline,
            content: updateAttachments(dataToUpdateOffline, action)
          },
          contentFull: updateDataOffline(
            state.tasks.contentFull,
            updateAttachments,
            action
          ),
          contentFiltered: updateDataOffline(
            state.tasks.contentFiltered,
            updateAttachments,
            action
          )
        }
      };

    case actionDiaryType.CREATE_TASK_SUCCESS:
      const createTask = (content, action, page) => {
        const crateTaskIndex = content.findIndex(
          task =>
            (action.payload.id && task.id === action.payload.id) ||
            (action.meta._id && task._id === action.meta._id)
        );

        if (crateTaskIndex >= 0) {
          content[crateTaskIndex] = {
            ...content[crateTaskIndex],
            ...checkOfflineAttachments(content[crateTaskIndex], action.payload),
            _id: undefined
          };
        } else if (page === 0) {
          content.unshift(action.payload);
        }

        return content;
      };

      return {
        ...state,
        tasks: {
          ...state.tasks,
          data: {
            ...state.tasks.data,
            content: createTask(dataToUpdate, action, state.tasks.data.number)
          },
          dataOffline: {
            ...state.tasks.dataOffline,
            content: createTask(
              dataToUpdateOffline,
              action,
              state.tasks.dataOffline.number
            )
          },
          contentFull: updateDataOffline(
            state.tasks.contentFull,
            createTask,
            action
          ),
          contentFiltered: updateDataOffline(
            state.tasks.contentFiltered,
            createTask,
            action
          )
        }
      };
    case actionDiaryType.CREATE_TASK_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case actionType.UPDATE_TASK_ITEM:
      const updateTask = (content, action, page) => {
        const updateTaskIndex = content.findIndex(
          task =>
            (action.payload.id && task.id === action.payload.id) ||
            (action.payload._id && task._id === action.payload._id)
        );
        if (updateTaskIndex >= 0) {
          content[updateTaskIndex] = {
            ...content[updateTaskIndex],
            ...checkOfflineAttachments(
              content[updateTaskIndex],
              action.payload.data
            )
          };
        } else if (page === 0 && !action.payload.id) {
          content.unshift(action.payload.data);
        }
        return content;
      };

      return {
        ...state,
        tasks: {
          ...state.tasks,
          data: {
            ...state.tasks.data,
            content: updateTask(dataToUpdate, action, state.tasks.data.number)
          },
          dataOffline: {
            ...state.tasks.dataOffline,
            content: updateTask(
              dataToUpdateOffline,
              action,
              state.tasks.dataOffline.number
            )
          },
          contentFull: updateDataOffline(
            state.tasks.contentFull,
            updateTask,
            action
          ),
          contentFiltered: updateDataOffline(
            state.tasks.contentFiltered,
            updateTask,
            action
          )
        }
      };
    case actionDiaryType.UPLOAD_TASK_ATTACHMENT:
      const updateAttachment = (content, action) => {
        const { id, subPath, name, idReplace, parentId, data } = action.payload;
        const taskToUpdate = content.find(
          task => task.id === parentId || task._id === parentId
        );

        if (!taskToUpdate) {
          return content;
        }
        const indexToReplace = taskToUpdate.attachments.findIndex(
          file => file.idReplace === idReplace
        );
        const gotMoreToUpload = taskToUpdate.attachments.find(
          file => file.idReplace && file.idReplace !== idReplace
        );

        if (indexToReplace >= 0) {
          taskToUpdate._id =
            !gotMoreToUpload && taskToUpdate.id ? undefined : taskToUpdate._id;
          taskToUpdate.attachments.splice(indexToReplace, 1, {
            id,
            subPath,
            name,
            data
          });
        }

        return content;
      };

      return {
        ...state,
        tasks: {
          ...state.tasks,
          data: {
            ...state.tasks.data,
            content: updateAttachment(
              dataToUpdate,
              action,
              state.tasks.data.number
            )
          },
          dataOffline: {
            ...state.tasks.dataOffline,
            content: updateAttachment(
              dataToUpdateOffline,
              action,
              state.tasks.dataOffline.number
            )
          },
          contentFull: updateDataOffline(
            state.tasks.contentFull,
            updateAttachment,
            action
          ),
          contentFiltered: updateDataOffline(
            state.tasks.contentFiltered,
            updateAttachment,
            action
          )
        }
      };

    case actionDiaryType.UPLOAD_TASK_ATTACHMENT_ERROR: {
      //eslint-disable-next-line

      const removeAttachment = (content, action) => {
        const { idReplace, parentId } = action.meta;

        const taskToUpdate = content.find(
          task => task.id === parentId || task._id === parentId
        );

        if (!taskToUpdate) {
          return content;
        }
        taskToUpdate.attachments = taskToUpdate.attachments.filter(
          file => file.idReplace !== idReplace
        );

        return content;
      };

      return {
        ...state,
        isUploadingAttachment: false,
        error: {
          ...action.payload,
          message: `Error uploading file: ${action.meta.name}`
        },
        tasks: {
          ...state.tasks,
          data: {
            ...state.tasks.data,
            content: removeAttachment(
              dataToUpdate,
              action,
              state.tasks.data.number
            )
          },
          dataOffline: {
            ...state.tasks.dataOffline,
            content: removeAttachment(
              dataToUpdateOffline,
              action,
              state.tasks.dataOffline.number
            )
          },
          contentFull: updateDataOffline(
            state.tasks.contentFull,
            removeAttachment,
            action
          ),
          contentFiltered: updateDataOffline(
            state.tasks.contentFiltered,
            removeAttachment,
            action
          )
        }
      };
    }
    case actionDiaryType.GET_ROUTE_START: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          routes: {
            ...state.tasks.routes,
            isFetching: true
          }
        }
      };
    }
    case actionDiaryType.GET_ROUTE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          routes: {
            ...state.tasks.routes,
            isFetching: false,
            [action.payload.id]: action.payload.route
          }
        }
      };
    case actionDiaryType.CLEAR_ROUTE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          routes: {
            ...state.tasks.routes,
            isFetching: false,
            [action.payload.id]: null
          }
        }
      };
    case actionDiaryType.GET_ROUTE_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          routes: {
            ...state.tasks.routes,
            isFetching: false
          }
        },
        error: action.payload
      };
    case actionType.CLEAR_SPRAY_TASKS_LIST_FOR_RECORDS: {
      return {
        ...state,
        records: initialState.records
      };
    }
    case actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS_START: {
      return {
        ...state,
        records: {
          ...state.records,
          isFetching: true
        }
      };
    }
    case actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS: {
      return {
        ...state,
        records: {
          ...state.records,
          data: {
            ...action.payload
          },
          isFetching: false
        }
      };
    }
    case actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS_FAIL: {
      return {
        ...state,
        records: {
          ...state.records,
          isFetching: false
        },
        error: action.payload
      };
    }
    case actionType.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    case actionType.CLEAR_TASKS_ERROR:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: null
        }
      };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.GET_SPRAY_TASKS_START:
    case actionType.GET_SPRAY_TASKS:
    case actionType.GET_SPRAY_TASKS_FAIL:
    case actionType.SET_TASK_BLOCK_DONE_START:
    case actionType.SET_TASK_BLOCK_DONE:
    case actionType.SET_TASK_BLOCK_DONE_FAIL:
    case actionType.DELETE_SPRAY_TASK_START:
    case actionType.DELETE_SPRAY_TASK:
    case actionType.DELETE_SPRAY_TASK_FAIL:
    case actionDiaryType.GET_ROUTE_START:
    case actionDiaryType.GET_ROUTE:
    case actionDiaryType.GET_ROUTE_ERROR:
    case actionDiaryType.CLEAR_ROUTE:
    case actionType.UPDATE_TASK_ITEM:
    case actionType.CLEAR_SPRAY_TASKS_LIST_FOR_RECORDS:
    case actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS_START:
    case actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS:
    case actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS_FAIL:
    case actionType.GET_TASKS_IDS_START:
    case actionType.GET_TASKS_IDS:
    case actionType.GET_TASKS_IDS_FAIL:
    case actionType.GET_CROPS_START:
    case actionType.GET_CROPS:
    case actionType.GET_CROPS_FAIL:
    case actionType.GET_VARIETIES_START:
    case actionType.GET_VARIETIES:
    case actionType.GET_VARIETIES_FAIL:
    case actionType.GET_BATCH_NUMBERS_START:
    case actionType.GET_BATCH_NUMBERS:
    case actionType.GET_BATCH_NUMBERS_FAIL:
    case actionType.SET_PROPERTY_VALUE:
    case actionType.SET_URL_STRING:
    case actionDiaryType.CREATE_TASK_START:
    case actionDiaryType.CREATE_TASK_SUCCESS:
    case actionDiaryType.SET_ATTACHMENTS:
    case actionDiaryType.CREATE_TASK_ERROR:
    case actionDiaryType.UPLOAD_TASK_ATTACHMENT:
    case actionType.CLEAR_ERROR:
    case actionType.CLEAR_TASKS_ERROR:
    case OFFLINE_STATUS_CHANGED:
      return tasksActions(state, action);

    default:
      return state;
  }
};
