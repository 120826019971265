import React from "react";
import { Modal, Button } from "semantic-ui-react";
import {
  addEmployee as addEmployeeAction,
  updateEmployee as updateEmployeeAction
} from "../../../../actions/Employee/employees";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import styles from "./AddUpdateEmployeeModal.module.css";
import _, { get, isEmpty } from "lodash";
import PersonalDetailsSection from "./components/PersonalDetailsSection";
import AddressSection from "./components/AddressSection";
import AdditionalInformationSection from "./components/AdditionalInformationSection";
import EmploymentSection from "./components/EmploymentSection";
// import NotificationSection from "./components/NotificationSection"; //Todo

const REQUIRED_FIELDS = [
  "firstName",
  "lastName",
  "gender",
  "startDate",
  "email",
  "type"
];

const AddUpdateEmployeeModal = ({
  update,
  open,
  item,
  Can,
  onClose,
  itemId,
  editSectionIndex
}) => {
  const dispatch = useDispatch();

  const validateValues = async values => {
    const errors = {};
    const { email, type, gender, ratePerHour } = values;

    REQUIRED_FIELDS.forEach(fieldName => {
      if (!get(values, fieldName)) {
        errors[fieldName] = "Required field";
      }
    });

    if (isEmpty(gender)) {
      errors.gender = "Required field";
    }

    if (isEmpty(type)) {
      errors.type = "Role is required";
    }
    if (!get(values, "ratePerHour") && get(values, "ratePerHour") == null) {
      errors["ratePerHour"] = "Required field";
    }

    if (isEmpty(email)) {
      if (type !== "WORKER") {
        errors.email = true;
      } else {
        delete errors.email;
      }
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  function onSubmit(values) {
    if (!!item) {
      dispatch(
        updateEmployeeAction({
          ...values,
          contractor: values.contractor?.id
            ? { id: values.contractor?.id }
            : null,
          picture:
            values.picture && values.picture.presignedUrl
              ? {
                  subPath: values.picture.subPath,
                  presignedUrl: values.picture.presignedUrl,
                  name: values.picture.name,
                  id: values.picture.id
                }
              : null
        })
      )
        .then(onClose)
        .then(update);
    } else {
      dispatch(
        addEmployeeAction({
          ...values,
          contractor: values.contractor?.id
            ? { id: values.contractor?.id }
            : null,
          picture:
            values.picture && values.picture.presignedUrl
              ? {
                  subPath: values.picture.subPath,
                  presignedUrl: values.picture.presignedUrl,
                  name: values.picture.name,
                  id: values.picture.id
                }
              : null
        })
      )
        .then(onClose)
        .then(update);
    }
  }

  const formInitialValues = {
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    type: "",
    email: "",
    passportNumber: "",
    visaType: "",
    immicardNumber: "",
    address: "",
    number: "",
    street: "",
    gender: "MALE",
    ratePerHour: null,
    dateOfBirth: "",
    state: "",
    vaccinationStatus: null,
    houseNumber: "",
    countryOfResidence: "",
    city: "",
    country: "",
    startDate: "",
    finishDate: "",
    vevoCheck: "",
    contractor: {
      id: "",
      name: ""
    },
    followContractorsRate: false,
    picture: {
      id: "",
      presignedUrl: "",
      name: "",
      subPath: ""
    },
    ...(!!item ? item : {})
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
      closeOnDimmerClick={false}
    >
      <Modal.Header className={styles.modalHeader}>
        {!!item ? "Edit" : "Add"} employee
      </Modal.Header>
      <Formik
        key={item}
        validate={validateValues}
        enableReinitialize
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={onSubmit}
        initialValues={formInitialValues}
      >
        {props => {
          return (
            <>
              <Modal.Content scrolling className={styles.modalContentContainer}>
                <>
                  <PersonalDetailsSection
                    isEdit={!!item}
                    editSectionIndex={editSectionIndex}
                  />
                  <EmploymentSection
                    Can={Can}
                    isEdit={!!item}
                    employee={item}
                    editSectionIndex={editSectionIndex}
                  />
                  {/*/!*<NotificationSection/>*!/ //Todo*/}
                  <AddressSection editSectionIndex={editSectionIndex} />
                  <AdditionalInformationSection
                    editSectionIndex={editSectionIndex}
                  />
                </>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Cancel"
                  onClick={onClose}
                  type='text'
                />
                <Button
                  content={!!item ? "Save" : "Add"}
                  primary
                  onClick={props.submitForm}
                  disabled={
                    !props.isValid || !props.dirty || props.isValidating
                  }
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddUpdateEmployeeModal;
