import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import DateTimePicker from "react-datetime-picker";
import styles from "./DateModal.module.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { fetchGeneralTaskList, updateGeneralTask } from "actions/Tasks";

const DateModal = ({ open, setOpen, taskData }) => {
  const dispatch = useDispatch();
  const [value, onChange] = useState(new Date());
  const localTime = moment().toDate();
  const { updateTask, status } = taskData;

  let minDate = null;
  let maxDate = null;

  if (status === "EXPIRED") {
    maxDate = localTime;
  } else {
    minDate = status !== "COMPLETED" ? localTime : null;
  }
  const handleClose = () => {
    setOpen(false);
  };
  const onConfirm = async () => {
    const dateObject = new Date(value);
    const formattedDateTime = moment(dateObject).format("YYYY-MM-DDTHH:mm:ss");
    const dataToSend = {
      areas: updateTask.areas,
      assignedTo: updateTask.assignedTo,
      attachments: updateTask.attachments,
      endAt: updateTask.endAt,
      equipments: updateTask.equipments,
      flowRate: updateTask.flowRate,
      generalTaskTypeId: updateTask.generalTaskTypeId,
      name: updateTask.name,
      paymentMethod: updateTask.paymentMethod,
      runningTimeMin: updateTask.runningTimeMin,
      startAt: updateTask.startAt,
      status: status !== "EXPIRED" ? status : "OPEN",
      subTaskName: updateTask.subTaskName,
      totalIrrigationWater: updateTask.totalIrrigationWater,
      expireAt: formattedDateTime
    };
    return (
      await dispatch(updateGeneralTask(updateTask.id, dataToSend)).then(() =>
        dispatch(fetchGeneralTaskList())
      ),
      handleClose()
    );
  };

  // const isoString = dateObject.toISOString();

  return (
    <>
      <Modal
        size="tiny"
        open={open}
        onClose={handleClose}
        className={styles.datedModal}
      >
        <Modal.Content className={styles.dateModalContent}>
          <div className={styles.dateModalHeader}>
            {status === "EXPIRED"
              ? `Move the ${updateTask?.name} to the Expired tasks`
              : `Move the ${updateTask?.name} from the Expired tasks`}
          </div>
          <div className={styles.dateModalSubHeader}>
            {status === "EXPIRED"
              ? "The task you are trying to move has not yet expired. Please update the expiry date."
              : "The task you are trying to move has been expired. Please update the expiry date."}
          </div>
          <div className={styles.dateModalBody}>
            <label className={styles.calendarLabel}>Expiry date and time</label>
            <DateTimePicker
              className={styles.taskDateCalendar}
              showLeadingZeros={true}
              minDate={minDate}
              maxDate={maxDate}
              clearIcon={null}
              format="dd/MM/yyyy HH:mm"
              // shouldCloseWidgets={true}
              disableClock={true}
              onChange={onChange}
              value={value}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Cancel"
            type="text"
            onClick={handleClose}
          />
          <Button content="Save" onClick={onConfirm} primary />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default DateModal;
