import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { Form, Input, Segment, Button } from "semantic-ui-react";
import CollapsibleBlock from "../../../../../components/CollapsibleBlock";
import styles from "./ContractorRate.module.css";
import { useFormikContext, useField } from "formik";
import { get } from "lodash";
import { ShowRateHistory } from "../../../components/ShowRateHistory/ShowRateHistory";

const ContractorRate = ({ isEdit, contractor }) => {
  const [editingRate, setEditingRate] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const [field, meta] = useField("rate");

  const [resetRequired, setResetRequired] = useState(false);

  useEffect(() => {
    if (resetRequired) {
      setResetRequired(false);
    }
  }, [resetRequired]);

  if (resetRequired) {
    return null;
  }

  return (
    <Segment>
      <CollapsibleBlock title="Rate" className={styles.root}>
        {isEdit && !editingRate && (
          <div className={styles.currentRateContainer}>
            <div className={styles.medium}>
              <span>Current rate:</span>
              {get(values, "rate")}$/hr
            </div>
            <Button
            primary
              onClick={() => setEditingRate(true)}
            >
              Edit rate
            </Button>
          </div>
        )}
        {(!isEdit || (isEdit && editingRate)) && (
          <>
            <div className={styles.rateInfoText}>
              Setting a contractor rate will allow you to assign a base rate for
              all the workers associated with this contractor
            </div>
            <Form>
              <Form.Group>
                <Form.Field
                  width={5}
                  required
                  error={meta.touched && meta.error}
                >
                  <label>Rate</label>
                  <Field
                    as={Input}
                    error={meta.touched && meta.error}
                    name="rate"
                    required
                    value={get(values, "rate")}
                    labelPosition="right"
                    label={`$/hr`}
                    onChange={(_, { value }) => {
                      if (!isNaN(value)) {
                        setFieldValue("rate", value);
                      }
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </>
        )}
        {isEdit && (
          <ShowRateHistory rateHistory={contractor.contractorRateHistory} />
        )}
      </CollapsibleBlock>
    </Segment>
  );
};

export default ContractorRate;
