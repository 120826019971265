import PropTypes from "prop-types";
import uuid from "uuid/v4";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Table,
  TableHeaderCell
} from "semantic-ui-react";
import React, { useState } from "react";
import Layout from "../../components/Layout";
import styles from "../../components/ListHolder/ListHolder.module.css";
import style from "./WorkersPayroll.module.css";
import DatePicker from "../../components/DatePicker";
import ExportToExcel from "../../components/ExportToExcel";
import SelectContractor from "../../components/SelectContractor";
import { copyStyles } from "../../utils/styles";
import { minutesToHM } from "../../utils/timeUtils";
import ReactDOMServer from "react-dom/server";
import PrintReport from "./components/PrintReport";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { fetchEmployeePayrollReport } from "../../actions/GeneralTasks/generalTasks";
import {
  getHourlyRateColumnsConfig,
  getPieceRateColumnsConfig,
  printReportColumnsConfig
} from "./columnsConfigs";
import {
  generateHourlyReports,
  generatePieceRateReports,
  generatePrintReport
} from "./logic";
import { addCommas, removeCommas } from "../../components/Numeric";
import { flow } from "lodash";
import ToolTip from "../../components/ToolTip";
import moment from "moment";

const EXCEL_REPORT_TYPES = {
  HOURLY_RATE_REPORT: {
    columns: getHourlyRateColumnsConfig,
    fn: generateHourlyReports,
    filterFn: e => e.totalWorkingTime > 0 || e.isTotalCostRow
  },
  PIECE_RATE_REPORT: {
    columns: getPieceRateColumnsConfig,
    fn: generatePieceRateReports,
    filterFn: x => x
  }
};

const transformTitle = title => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return capitalizeFirstLetter(title.replaceAll("_", " ").toLowerCase());
};

const WorkersPayroll = ({ route, location }) => {
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState(
    moment()
      .subtract(7, "days")
      .toDate()
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [contractorId, setContractorId] = useState(null);
  const [data, setData] = useState([]);
  const [chosenReport, chooseReport] = useState("HOURLY_RATE_REPORT");

  const { isFetching } = useSelector(state => state.generalTasks);

  const employeeRows = generatePrintReport(data, contractorId).filter(
    e => e.totalWorkingTime > 0 || e.payPerPiece > 0
  );

  const aggregations = {
    totalPay: flow(
      data => data.reduce((a, b) => a + removeCommas(b.totalPay), 0),
      n => n.toFixed(2),
      addCommas
    )(employeeRows),
    payPerHours: flow(
      data => data.reduce((a, b) => a + removeCommas(b.payPerHours), 0),
      n => n.toFixed(2),
      addCommas
    )(employeeRows),
    totalWorkingTime: employeeRows.reduce((a, b) => a + b.totalWorkingTime, 0)
  };

  const print = () => {
    const newWindow = window.open();
    newWindow.document.title = `Workers Payroll`;
    copyStyles(window.document, newWindow.document);

    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <PrintReport
        data={employeeRows}
        aggregations={aggregations}
        startDate={dateFrom}
        endDate={dateTo}
      />
    );
    newWindow.print();
  };

  const refresh = () => {
    const currentToken = uuid();
    window.localStorage.setItem("asyncToken", currentToken);
    dispatch(
      fetchEmployeePayrollReport({
        from: dateFrom,
        to: dateTo,
        token: currentToken
      })
    ).then(({ data, token }) => {
      const currentToken = window.localStorage.getItem("asyncToken");
      if (token === currentToken) setData(data);
    });
  };

  return (
    <Layout route={route} location={location} classForMain={styles.mainHolder}>
      <div className={style.p1em}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h2">{route.name}</Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment>
          <Grid divided="vertically">
            <Grid.Row>
              <Grid.Column>
                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      widescreen={3}
                      largeScreen={3}
                      mobile={8}
                      tablet={8}
                    >
                      <Form.Field>
                        <label>From:</label>
                        <DatePicker
                          onChange={value => {
                            if (value === "") return;
                            if (dateTo !== value) setDateFrom(value);
                          }}
                          value={dateFrom}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column
                      widescreen={3}
                      largeScreen={3}
                      mobile={8}
                      tablet={8}
                    >
                      <Form.Field>
                        <label>To:</label>
                        <DatePicker
                          onChange={value => {
                            if (value === "") return;
                            if (dateTo !== value) setDateTo(value);
                          }}
                          value={dateTo}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column
                      widescreen={1}
                      largeScreen={1}
                      mobile={8}
                      tablet={8}
                    >
                      <Button primary onClick={refresh} className={style.refreshButton}>
                        Refresh
                      </Button>
                    </Grid.Column>
                    <Grid.Column
                      floated={"right"}
                      widescreen={5}
                      largeScreen={5}
                      mobile={16}
                      tablet={16}
                    >
                      <div className={style.exportColumn}>
                        <Dropdown
                          className={style.reportTypeSelector}
                          fluid
                          selection
                          name={"report_type"}
                          options={Object.keys(EXCEL_REPORT_TYPES).map(key => ({
                            id: key,
                            text: transformTitle(key),
                            value: key
                          }))}
                          value={chosenReport}
                          onChange={(_, data) => chooseReport(data.value)}
                        />
                        <ExportToExcel
                          className={style.exportToExcel}
                          columns={EXCEL_REPORT_TYPES[chosenReport].columns(
                            dateFrom,
                            dateTo
                          )}
                          rawData={EXCEL_REPORT_TYPES[chosenReport]
                            .fn(data, contractorId, dateFrom, dateTo)
                            .filter(EXCEL_REPORT_TYPES[chosenReport].filterFn)}
                        />
                        <ToolTip content="Export to PDF/Print">
                          <Icon
                            name="print"
                            onClick={print}
                            className={style.printButton}
                          />
                        </ToolTip>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column
                      widescreen={3}
                      largeScreen={3}
                      mobile={16}
                      tablet={16}
                    >
                      <Form.Field>
                        <label>Contractor:</label>
                        <SelectContractor
                          clearable
                          onChange={(_, data) => setContractorId(data.value)}
                          value={contractorId}
                          native
                          name={"contractor"}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: 10 }}>
              {isFetching ? (
                <Loader />
              ) : (
                <Table basic>
                  <Table.Header>
                    <Table.Row>
                      {printReportColumnsConfig.map(config => (
                        <TableHeaderCell key={`table-header-${config.Header}`}>
                          {config.Header}
                        </TableHeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {employeeRows.map((data, i) => (
                      <Table.Row key={`table-row-${i}`}>
                        {printReportColumnsConfig.map(config => (
                          <Table.Cell key={`table-row-${i}-${config.accessor}`}>
                            {data[config.accessor]}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan={3}>Total</Table.HeaderCell>
                      <Table.HeaderCell>
                        {minutesToHM(aggregations.totalWorkingTime)}
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                      <Table.HeaderCell>
                        {aggregations.payPerHours}
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                      <Table.HeaderCell>
                        {aggregations.totalPay}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    </Layout>
  );
};

WorkersPayroll.propTypes = {
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
};

export default WorkersPayroll;
