import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import filterTypes from "components/Table/filterTypes";
import { connect, useDispatch, useSelector } from "react-redux";
import { Icon, Button, Popup } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import {
  selectTableStructuredData,
  selectExpotStructuredData,
  formaterData,
} from "selectors/reports/harvest/pickTopack/pickerReport";
import {
  matterOptionsSelector,
  chemicalsOptionsFilterSelector,
  chemicalTypeOptionsSelector,
  typeOptionsSelector,
  activeIngredientsSelector,
} from "selectors/chemicals";
import { farmsOptionsFilterSelector } from "selectors/farms";
import {
  blockSelector,
  patchSelector,
  blocListSelector,
  patchListSelector,
  varietiesOptionSelector,
  cropsOptionSelector,
  areaFilterOptions,
} from "selectors/areas";
import {
  fetchPickToPackPickerReportTableData,
  fetchPickToPackPickerReportExportData,
} from "actions/Reports/harvest/pickerReport";
import { fetchCropsList, getCropsList } from "actions/Farms/crops";
import { getVarietiesList } from "actions/Varieties/index";
import { harvestUnitsFetch } from "actions/HarvestUnits/harvestUnits";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { history } from "../../../../../store";
import styles from "./PickerReport.module.css";
import { excelExport } from "utils/excelExport";
import { useReactToPrint } from "react-to-print";
import moment from "moment-timezone";
import _, { get, pickBy, pick } from "lodash";
import { allWorkersFilterOptions } from "selectors/harvestUnits";
import { contractorsListSelector } from "selectors/employee";
import PrintReports from "./PickerReportPrintout/PrintReports";
import { harvestUnitOptionsSelector } from "selectors/boxUnits";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";

const headerColumn = {
  employeeName: "Worker",
  contractor: "Contractor",
  totalQty: "No.units",
  totalPrice: "Total Price ($)",
  date: "Date",
  farmId: "Farm",
  areaId: "Block",
  patchId: "Patch",
  row: "Row",
  qty: "Quantity",
  cropId: "Crop",
  varietyId: "Variety",
  barcodeId: "Barcode Id",
  unitName: "Bin unit",
  price: "Price",
};

const PickToPackPickerReport = ({
  location,
  route,
  areaList,
  isFetching,
  navigate,
}) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    type: [],
    matter: "",
    activeIngredient: [],
    blockIds: [],
    patchIds: [],
    chemicalIds: [],
    farmIds: [],
    seasons: [],
    from: "",
    to: "",
  });
  const workersList = useSelector(allWorkersFilterOptions);
  const contractorsList = useSelector(contractorsListSelector);
  const farmsList = useSelector(farmsOptionsFilterSelector);
  const typeList = useSelector(chemicalTypeOptionsSelector);
  const blockList = useSelector(blocListSelector);
  const patchList = useSelector(patchListSelector);
  const varietiesList = useSelector((state) =>
    varietiesOptionSelector(filters)(state)
  );
  const cropsList = useSelector(cropsOptionSelector);
  const binUnitsList = useSelector(harvestUnitOptionsSelector);
  const { content } = useSelector(selectExpotStructuredData);
  const [open, setOpen] = useState(false);
  const [filtersList, setFiltersList] = useState([]);
  const [printExpandedData, setPrintExpandedData] = useState(false);
  const [expandedRow, setExpandedRow] = useState(false);
  const [selectItem, setSelectItem] = useState();
  const [excel, setExcel] = useState();
  const componentRef = useRef();
  const chemicalsList = useSelector((state) => state.chemical.list.content);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getData = useCallback(
    (params) => {
      setFiltersList(params);
      dispatch(fetchPickToPackPickerReportTableData(params));
      dispatch(getCropsList());
      dispatch(getVarietiesList());
      dispatch(harvestUnitsFetch());
    },
    [fetchPickToPackPickerReportTableData]
  );
  const columns = [
    {
      name: "Worker",
      id: 1,
    },
    {
      name: "Contractor",
      id: 2,
    },
    {
      name: "No.units",
      id: 3,
    },
    {
      name: "Total Price ($)",
      id: 4,
    },
    {
      name: "Date",
      id: 5,
    },
    {
      name: "Farm",
      id: 6,
    },
    {
      name: "Block",
      id: 7,
    },
    {
      name: "Patch",
      id: 8,
    },
    {
      name: "Row",
      id: 9,
    },
    {
      name: "Quantity",
      id: 10,
    },
    {
      name: "Crop",
      id: 11,
    },
    {
      name: "Variety",
      id: 12,
    },
    {
      name: "Barcode Id",
      id: 13,
    },
    {
      name: "Bin unit",
      id: 14,
    },
    {
      name: "Price",
      id: 15,
    },
  ];

  const fetchPrintoutAllInformation = () => {
    return dispatch(fetchPickToPackPickerReportExportData(filtersList));
  };

  const tableData = useSelector(selectTableStructuredData);
  const printData = useSelector(selectExpotStructuredData);
  useEffect(() => {
    return setExpandedRow(false);
  }, [tableData.number]);

  const handleExcelExport = useCallback(
    async (selectedItem) => {
      const headerNewData = pickBy(headerColumn, function (value, key) {
        return selectedItem && selectedItem.find((item) => value === item);
      });
      const data = await dispatch(
        fetchPickToPackPickerReportExportData(filtersList)
      );

      if (data?.content.length) {
        const newData = data.content
          ?.map((parent) =>
            parent.employeeData.map((child) => ({
              ...child,
              contractor: parent.contractor,
              totalQty: parent.totalQty,
              employeeId: parent.employeeId,
              employeeName: parent.employeeName,
              totalPrice: parent.totalPrice,
              patchId: child.areaId,
            }))
          )
          .flat();
        const filtredDate = newData.map((item) =>
          pick(item, Object.keys(headerNewData))
        );
        const expanded = filtredDate.map((item) => {
          const areaName = areaList.find((area) => area.id === item?.areaId);
          const patchName = areaList.find((area) => area.id === item?.patchId);
          return {
            ...(item.hasOwnProperty("employeeName") && {
              employeeName: item.employeeName || "-",
            }),
            ...(item.hasOwnProperty("contractor") && {
              contractor: item.contractor || "-",
            }),
            ...(item.hasOwnProperty("totalQty") && {
              totalQty: item.totalQty.toFixed(2) || "-",
            }),
            ...(item.hasOwnProperty("totalPrice") && {
              totalPrice: item.totalPrice.toFixed(2) || "-",
            }),
            ...(item.hasOwnProperty("date") && {
              date: moment(item.date).format("DD/MM/YYYY") || "-",
            }),
            ...(item.hasOwnProperty("farmId") && {
              farm:
                farmsList.options.find((farm) => farm.key === item.farmId)
                  ?.label || "-",
            }),
            ...(item.hasOwnProperty("areaId") && {
              block: areaName
                ? areaName.parent
                  ? areaName.parent.name
                  : areaName.name
                : " - ",
            }),
            ...(item.hasOwnProperty("patchId") && {
              patch: patchName.parent ? patchName.name : " - ",
            }),
            ...(item.hasOwnProperty("row") && { row: item.row || "-" }),
            ...(item.hasOwnProperty("qty") && {
              qty: item.qty.toFixed(2) || "-",
            }),
            ...(item.hasOwnProperty("cropId") && {
              crop:
                cropsList.options.find((crop) => crop.key === item.cropId)
                  ?.label || "-",
            }),
            ...(item.hasOwnProperty("varietyId") && {
              variety:
                varietiesList.options.find(
                  (variety) => variety.key === item.varietyId
                )?.label || "-",
            }),
            ...(item.hasOwnProperty("barcodeId") && {
              barcodeId: item.barcodeId || "-",
            }),
            ...(item.hasOwnProperty("unitName") && {
              unitName: item.unitName || "-",
            }),
            ...(item.hasOwnProperty("price") && {
              price: !isNaN(item.price) ? item.price.toFixed(2) : "-",
            }),
          };
        });
        const filename = "picker_report";
        excelExport(expanded, selectedItem, filename);
      }
    },
    [filtersList]
  );

  const columnsConfig = useMemo(() => {
    return [
      {
        accessor: "workers",
        id: "employee",
        Header: "Workers",
        filterId: "date",
        withSort: true,
        Footer: <div className={styles.totalRow}>Total</div>,
        // cellWidth: 1,
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <span {...row.getToggleRowExpandedProps({})}>
              <Icon
                className={styles.chevronIcon}
                onClick={() => setExpandedRow(row.id)}
                name={`angle ${row.isExpanded ? "up" : "down"}`}
              />
              {row.canExpand ? (
                <span
                  style={{
                    paddingRight: "10px",
                  }}
                >
                  {" "}
                  {value || "-"}
                </span>
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF",
                  }}
                >
                  {value || " - "}
                </div>
              )}
            </span>
          ) : row.depth == 1 ? (
            <span>
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF",
                  }}
                >
                  {value || "-"}
                </div>
              )}
            </span>
          ) : (
            <span>
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF",
                  }}
                >
                  {value || "-"}
                </div>
              )}
            </span>
          );
        },
        filter: {
          title: "Date",
          type: filterTypes.DateRangeSelect,
          selector: <DateRangeSelect loading={isFetching} />,
        },
        minWidth: 100,
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty",
        withSort: false,
        accessor: "empty",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "contractorIds",
        filter: {
          title: "Contractor",
          type: filterTypes.MultiSelect,
          selector: contractorsListSelector,
        },
      },
      {
        Header: "Contractors",
        id: "contractor",
        withSort: true,
        accessor: "contractors",
        minWidth: 70,
        // cellWidth: 1,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
              }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "employeeIds",
        filter: {
          title: "Worker",
          type: filterTypes.MultiSelect,
          selector: allWorkersFilterOptions,
        },
      },

      {
        Header: " ",
        onlyTable: true,
        id: "empty1",
        withSort: false,
        accessor: "empty1",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "areaIds",
        filter: {
          title: "Area",
          type: filterTypes.MultiSelect,
          selector: areaFilterOptions,
        },
      },
      {
        Header: " ",
        onlyTable: true,
        id: "empty5",
        withSort: false,
        accessor: "empty5",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "farmIds",
        filter: {
          title: "Farm",
          type: filterTypes.MultiSelect,
          selector: farmsOptionsFilterSelector,
        },
      },
      {
        Header: " ",
        onlyTable: true,
        id: "empty6",
        withSort: false,
        accessor: "empty6",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
      },
      {
        Header: "No. units",
        className: "test",
        id: "numberOfUnits",
        withSort: true,
        accessor: "numberOfUnits",
        minWidth: 70,
        // cellWidth: 1,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
              }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "cropIds",
        filter: {
          title: "Crop",
          type: filterTypes.MultiSelect,
          selector: cropsOptionSelector,
          loaderAction: fetchCropsList,
        },
        Footer: (info) => {
          const total = useMemo(
            () =>
              info.sortedRows
                .reduce((sum, row) => +row.original.numberOfUnits + sum, 0)
                .toFixed(2),
            [info.rows]
          );

          return <div className={styles.totalRow}>{total}</div>;
        },
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty2",
        withSort: false,
        accessor: "empty2",
        minWidth: 70,
        // cellWidth: 2,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
                whiteSpace: "normal",
              }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "varietyIds",
        filter: {
          title: "Variety",
          type: filterTypes.MultiSelect,
          selector: (state) => varietiesOptionSelector(filters)(state),
        },
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty3",
        withSort: false,
        accessor: "empty3",
        minWidth: 70,
        // cellWidth: 2,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            " "
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
              }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "seasons",
        filter: {
          title: "Season",
          type: filterTypes.SingleSelect,
          selector: seasonsOptionsSelector,
        },
      },
      {
        Header: "Total price ($)",
        id: "totalPrice",
        withSort: true,
        accessor: "totalPrice",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
              }}
            >
              {value || "-"}
            </div>
          ),
      },
      {
        Header: "",
        onlyTable: true,
        id: "empty4",
        withSort: false,
        accessor: "empty4",
        minWidth: 70,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            " "
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
                borderRight: "1px solid #DEDEDF",
                marginRight: "15px",
              }}
            >
              {value || "-"}
            </div>
          ),
      },
    ];
  }, [filters]);

  const handleBackClick = () => {
    navigate({
      pathname: "/reports",
      state: {
        activeNode: location?.state?.reportNode,
      },
    });
  };
  const printButton = (selectedItem) => {
    setSelectItem(selectedItem);
    fetchPrintoutAllInformation().then(() => {
      setPrintExpandedData(true);
      handlePrint();
    });
  };
  return (
    <>
      <div style={{ display: "none" }}>
        <PrintReports
          title="Picker report"
          showExpandedData={printExpandedData}
          workersList={workersList}
          contractorsList={contractorsList}
          farmsList={farmsList}
          blockList={blockList}
          patchList={patchList}
          cropsList={cropsList}
          varietiesList={varietiesList}
          binUnitsList={binUnitsList}
          cropIds={filters.cropIds}
          farmIds={filters.farmIds}
          varietyIds={filters.varietyIds}
          blockIds={filters.blockIds}
          patchIds={filters.patchIds}
          employeeIds={filters.employeeIds}
          contractorIds={filters.contractorIds}
          binUnitsIds={filters.harvestUnitIds}
          tableData={content}
          selectedItem={selectItem}
          data={printData}
          from={filters.from}
          to={filters.to}
          ref={componentRef}
        />
      </div>
      <TablePageHolder
        navigate={navigate}
        backButton={
          <Button
            floated="right"
            className={styles.backIconWrapper}
            onClick={handleBackClick}
          >
            <Icon className={styles.backIcon} name="angle left" />
          </Button>
        }
        actionsButtons={
          <>
            <Button
              primary
              onClick={() => {
                return setExcel(true), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="download" />
                Export
              </div>
            </Button>
            <Button
              primary
              onClick={() => {
                return setExcel(false), setOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="print" />
                Print
              </div>
            </Button>
          </>
        }
        rowId={expandedRow}
        // withMoreButton={true}
        excel={excel}
        getData={getData}
        isFetching={tableData.isFetching}
        location={location}
        onRowClick={() => { }}
        open={open}
        setOpen={setOpen}
        handleExcelExport={handleExcelExport}
        setFilters={setFilters}
        printButton={printButton}
        pageTitle={route.name}
        tableType="report"
        route={route}
        printColumns={columns}
        sectionModal={true}
        tableColumns={columnsConfig}
        withSelection={false}
        tableData={tableData}
        withoutSort={false}
        firstColumnFixed={false}
        singleLine
        fixed={true}
        withTable={true}
        withHeader={true}
      />
    </>
  );
};

PickToPackPickerReport.propTypes = {
  Can: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object,
};

export default connect((state, props) => {
  return {
    areaList: state.areas.list.content,
    filterValues: state.packedBoxes.filters,
    productsList: state.chemical.list.content,
    isFetching: state.packedBoxes.data.isFetching,
    error: state.packedBoxes.error,
  };
})(PickToPackPickerReport);
