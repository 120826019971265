import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Popup,
  Modal
} from "semantic-ui-react";
import styles from "./SideBar.module.css";
import { StatusChip } from "../components/statusChip";
import HarvestUnitInfo from "./components/HarvestUnitInfo";
import OverAllSummary from "./components/OverAllSummary";
import GeneralEdit from "./GeneralEdit";
import RoundsInfo from "./components/RoundsInfo";
import HarvestUnitsImageModal from "routes/HarvestUnitsControl/components/Slider";
import GeneralInfo from "./components/GeneralInfo";
import Tabs from "./components/Tabs/Tabs";
import { connect, useDispatch } from "react-redux";
import Loader from "components/Loader";
import { updateQC } from "actions/QualityControl";
import RoundsAdd from "./components/Tabs/RoundsAdd";

function Sidebar({
  onDeleteQc,
  onDeleteRounds,
  activeItem,
  onClose,
  employees,
  isFetching
}) {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRoundDeleteModal, setOpenRoundDeleteModal] = useState(false);
  const [checkEdit, setCheckEdit] = useState(false);
  const [generalEdit, setGeneralEdit] = useState(false);
  const [addCheck, setAddCheck] = useState(false);
  const [parameterAddCheck, setParameterAddCheck] = useState(false);
  const [openSecondSideBar, setOpenSecondSideBar] = useState(false);
  const [openAddSideBar, setOpenAddSideBar] = useState(false);
  const [roundAddInitialValue, setRoundAddInitialValue] = useState(null);
  const [urlList, setUrlList] = useState(false);
  const [activeRounds, setActiveRounds] = useState({});
  const [blockName, setblockName] = useState(false);
  const [imageModalOpen, setimageModalOpen] = useState(false);
  const [openParametrAdd, setOpenParametrAdd] = useState(true);
  const [openWorkerAdd, setOpenWorkerAdd] = useState(true);
  const [activeRoundsNumber, setActiveRoundsNumber] = useState(null);

  useEffect(() => {
    activeItem &&
      setActiveRounds(
        activeItem?.qcRounds.find(
          i => i.roundNumber === activeRounds?.roundNumber
        )
      );
  }, [activeItem]);
  useEffect(() => {
    if (activeItem === null) {
      setOpenSecondSideBar(false);
      setActiveRounds(false);
      setCheckEdit(false);
      setGeneralEdit(false);
      setAddCheck(false);
      setParameterAddCheck(false);
      setOpenAddSideBar(false);
      setRoundAddInitialValue(false);
      setOpenParametrAdd(false);
      setOpenWorkerAdd(false);
      setActiveRoundsNumber(false);
    }
  }, [activeItem]);
  const onSubmit = data => {
    dispatch(updateQC(activeItem.id, data)).then(
      () => setActiveRounds(false),
      setOpenAddSideBar(false),
      setOpenSecondSideBar(false),
      setAddCheck(false),
      setParameterAddCheck(false),
      setRoundAddInitialValue(false),
      setOpenParametrAdd(false),
      setOpenWorkerAdd(false),
      setActiveRoundsNumber(false)
    );
  };
  return (
    <>
      <Modal
        className={styles.messageModal}
        onClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
        size="tiny"
      >
        <Modal.Content
          className={styles.modalContent}
        >{`Delete ${activeItem?.name}  procedure?`}</Modal.Content>
        <Modal.Actions>
          <div className={styles.buttonWrapper}>
            <Button
              content="Cancel"
              className={styles.cancelBtn}
              onClick={() => setOpenDeleteModal(false)}
            />
            <Button
              content="Delete"
              className={styles.deleteBtn}
              onClick={() => {
                return onDeleteQc(), setOpenDeleteModal(false), onClose();
              }}
              positive
            />
          </div>
        </Modal.Actions>
      </Modal>
      <HarvestUnitsImageModal
        open={imageModalOpen}
        blockName={blockName}
        urlList={urlList || []}
        onClose={() => {
          return setimageModalOpen(false);
        }}
      />
      <Container fluid className={styles.root}>
        {!openSecondSideBar ? (
          <>
            <Grid className={styles.sidebarHeader}>
              <Grid.Row columns={"equal"} verticalAlign="middle">
                <Grid.Column className={styles.headerColumn}>
                  <Header className={styles.name} as="h1">
                    {activeItem?.name}
                  </Header>
                </Grid.Column>
                <Grid.Column width={1} floated="right">
                  <Popup
                    inverted
                    position="bottom center"
                    content={"Edit"}
                    size="tiny"
                    trigger={
                      <div className={styles.editButtonWrapper}> 
                      <Button
                        onClick={() => setGeneralEdit(true)}
                        className={styles.editButton}
                        floated="right"
                        icon={<Icon name="edit" />}
                        size="small"
                      />
                    </div>
                    }
                  />
                </Grid.Column>
                <Grid.Column width={2} floated="right">
                  <Popup
                    inverted
                    position="bottom center"
                    content={"Delete"}
                    size="tiny"
                    trigger={
                      <div
                        onClick={() => setOpenDeleteModal(true)}
                        className={styles.deleteButton}
                      >
                        <Icon name="trash alternate" />
                      </div>
                    }
                  />
                </Grid.Column>
                <Grid.Column width={1} floated="right">
                  <Popup
                    inverted
                    position="bottom center"
                    content={"Close"}
                    size="tiny"
                    trigger={
                      <Button
                        onClick={onClose}
                        className={styles.closeButton}
                        floated="right"
                        icon={<Icon className="tuf-times" />}
                        size="small"
                      />
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid.Row>
              {!generalEdit && (
                <StatusChip
                  fullWidth={true}
                  label={
                    activeItem?.qcRounds?.length === activeItem?.rounds
                      ? "Completed"
                      : "In progress"
                  }
                />
              )}
            </Grid.Row>

            <div className={styles.contentWrapper}>
              {!generalEdit ? (
                <>
                  <Grid.Row className={styles.harvestUnitInformationWrapper}>
                    <HarvestUnitInfo activeItem={activeItem} />
                  </Grid.Row>
                  <Grid.Row className={styles.overAllSummaryWrapper}>
                    <OverAllSummary activeItem={activeItem} />
                  </Grid.Row>
                </>
              ) : (
                <Grid.Row className={styles.overAllSummaryWrapper}>
                  <Header className={styles.name} as="h2">
                    General information
                  </Header>
                  <GeneralEdit
                    activeItem={activeItem}
                    setGeneralEdit={setGeneralEdit}
                  />
                </Grid.Row>
              )}
              <Grid.Row className={styles.roundsInfoWrapper}>
                {isFetching ? (
                  <Loader />
                ) : (
                  <RoundsInfo
                    setActiveRoundsNumber={setActiveRoundsNumber}
                    roundAddInitialValue={roundAddInitialValue}
                    setOpenAddSideBar={setOpenAddSideBar}
                    setActiveRounds={setActiveRounds}
                    setOpenSecondSideBar={setOpenSecondSideBar}
                    activeItem={activeItem}
                    employees={employees}
                    setUrlList={setUrlList}
                    setblockName={setblockName}
                    setimageModalOpen={setimageModalOpen}
                    header={activeItem?.name}
                  />
                )}
              </Grid.Row>
              <div className={styles.addRoundBtnWrapper}>
                <Button
                  disabled={!!roundAddInitialValue}
                  content="+ Add a round"
                  primary
                  onClick={() => {
                    setRoundAddInitialValue({
                      roundNumber: activeItem.qcRounds.length + 1,
                      defectReports: [],
                      employeeResults: [],
                      pictures: []
                    });
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {!openAddSideBar ? (
              <>
                <Modal
                  className={styles.messageModal}
                  onClose={() => setOpenRoundDeleteModal(false)}
                  open={openRoundDeleteModal}
                  size="tiny"
                >
                  <Modal.Content className={styles.modalContent}>
                    {`Delete Round ${activeRounds.roundNumber}?`}
                    <p style={{ fontSize: "18px", fontWeight: "500" }}>
                      {" "}
                      All associated data will be permanently deleted.
                    </p>
                  </Modal.Content>
                  <Modal.Actions>
                    <div className={styles.buttonWrapper}>
                      <Button
                        content="Cancel"
                        className={styles.cancelBtn}
                        onClick={() => setOpenRoundDeleteModal(false)}
                      />
                      <Button
                        content="Delete"
                        className={styles.deleteBtn}
                        onClick={() => {
                          const dataToSend = {
                            ...activeItem,
                            qcRounds: activeItem?.qcRounds
                              .filter(
                                item =>
                                  item.roundNumber !== activeRounds.roundNumber
                              )
                              .map((item, index) => ({
                                ...item,
                                roundNumber: index + 1
                              }))
                          };

                          return (
                            onDeleteRounds(dataToSend),
                            setOpenRoundDeleteModal(false),
                            setOpenSecondSideBar(false)
                          );
                        }}
                        positive
                      />
                    </div>
                  </Modal.Actions>
                </Modal>
                <Grid className={styles.sidebarHeader}>
                  <Grid.Row columns={"equal"} verticalAlign="middle">
                    <Grid.Column className={styles.subSideBarheader}>
                      <Popup
                        inverted
                        position="bottom center"
                        content={"Back"}
                        size="tiny"
                        trigger={
                          <Button
                            onClick={() => (
                              setOpenSecondSideBar(false),
                              setActiveRounds({}),
                              setGeneralEdit(false),
                              setParameterAddCheck(false),
                              setCheckEdit(false)
                            )}
                            className={styles.backButton}
                            floated="right"
                            icon={<Icon className="angle left" />}
                            size="small"
                          />
                        }
                      />
                      <Header className={styles.roundsName} as="h1">
                        {`Round ${activeRounds?.roundNumber}`}
                      </Header>
                    </Grid.Column>
                    {activeItem?.qcRounds.length > 1 && (
                      <Grid.Column width={1} floated="right">
                        <Popup
                          inverted
                          position="bottom center"
                          content={"Delete"}
                          size="tiny"
                          trigger={
                            <div
                              onClick={() => setOpenRoundDeleteModal(true)}
                              className={styles.deleteButton}
                            >
                              <Icon name="trash alternate" />
                            </div>
                          }
                        />
                      </Grid.Column>
                    )}
                    <Grid.Column width={2} floated="right">
                    <Popup
                    inverted
                    position="bottom center"
                    content={"Edit"}
                    size="tiny"
                    trigger={
                      <div className={styles.editButtonWrapper}> 
                      <Button
                         onClick={() => setCheckEdit(true)}
                        className={styles.editButton}
                        floated="right"
                        icon={<Icon name="edit" />}
                        size="small"
                      />
                    </div>
                    }
                  />
                    </Grid.Column>
                    <Grid.Column width={1} floated="right">
                      <Popup
                        inverted
                        position="bottom center"
                        content={"Close"}
                        size="tiny"
                        trigger={
                          <Button
                            onClick={onClose}
                            className={styles.closeButton}
                            floated="right"
                            icon={<Icon className="tuf-times" />}
                            size="small"
                          />
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <GeneralInfo activeItem={activeItem} />
                <Tabs
                  activeItem={activeItem}
                  checkEdit={checkEdit}
                  addCheck={addCheck}
                  parameterAddCheck={parameterAddCheck}
                  setCheckEdit={setCheckEdit}
                  setAddCheck={setAddCheck}
                  setParameterAddCheck={setParameterAddCheck}
                  activeRounds={activeRounds}
                  header={activeItem?.name}
                />
              </>
            ) : (
              <RoundsAdd
                activeItem={activeItem}
                setOpenAddSideBar={setOpenAddSideBar}
                setOpenWorkerAdd={setOpenWorkerAdd}
                setOpenSecondSideBar={setOpenSecondSideBar}
                setOpenParametrAdd={setOpenParametrAdd}
                onClose={onClose}
                activeRoundsNumber={activeRoundsNumber}
                openParametrAdd={openParametrAdd}
                openWorkerAdd={openWorkerAdd}
                onSubmit={onSubmit}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
}
export default connect((state, props) => {
  return {
    isFetching: state.qualityControl.data.isFetching
  };
})(Sidebar);
