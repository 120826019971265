import React from "react";
import { Table, Icon, Popup } from "semantic-ui-react";
import { Chip } from "routes/Reports/Harvest/components/Chip";
import styles from "./AreasTable.module.css";
import { areasGrouper } from "../components/helper";

const AreasTable = ({
  areas,
  areasIds,
  areaType,
  setFieldValue,
  handelDeleteAreas,
  setSelectedItem,
  selectedItem
}) => {
  const filtredTodoAreas = areas
    .filter(item => areasIds.map(item => item.areaId).includes(item.id))
    .map(item => {
      return item.type === "BLOCK"
        ? { ...item, children: [] }
        : {
          ...item.parent,
          children: [item]
        };
    });

  const newData =
    areaType === "ALL" ? filtredTodoAreas : areasGrouper(filtredTodoAreas);

  const handelDeleteAll = id => {
    return setFieldValue("areaIds", []);
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <Table className={styles.areaTable} columns={5}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={styles.header}>
                Areas
              </Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>Crop</Table.HeaderCell>
              <Table.HeaderCell>Variety</Table.HeaderCell>
              <Table.HeaderCell className={styles.iconCell}>
                <div
                  className={styles.cleatAll}
                  onClick={() => handelDeleteAll()}
                >
                  Clear all
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {newData.map(item => {
              const uniqueArray = Array.from(
                new Set(
                  (!item?.children?.length
                    ? item.varieties
                    : item?.children?.map(ch => ch.varieties).flat()
                  ).map(obj => obj.variety)
                )
              );
              return (
                <Table.Row className={styles.areaTableBodyRow}>
                  <Table.Cell className={styles.areaTableBodyBlockCell}>
                    {areaType === "ALL" ? (
                      <>
                        {item?.children?.length
                          ? ` ${item.name}/${item?.children[0]?.name}`
                          : item.name}
                      </>
                    ) : (
                      <div className={styles.blockPatchWrapper}>
                        {item.name}
                        {item.children.length ? (
                          <Chip
                            label="Patches"
                            items={item.children.map(i => i.name)}
                          />
                        ) : (
                          <span style={{ width: "95px" }}>-</span>
                        )}
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {item?.children?.length
                      ? item?.children?.reduce(
                        (prev, curr) => prev + curr.size,
                        0
                      )
                      : item.size || "-"}
                  </Table.Cell>
                  <Table.Cell>
                    {uniqueArray.length > 1 ? (
                      <Chip
                        color="green"
                        label="Crops"
                        items={[...new Set(uniqueArray.map(i => i.crop.name))]}
                      />
                    ) : (
                      uniqueArray[0]?.crop.name || "-"
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {uniqueArray.length > 1 ? (
                      <Chip
                        color="green"
                        label="Varieties"
                        items={[...uniqueArray.map(i => i.name)]}
                      />
                    ) : (
                      uniqueArray[0]?.name || "-"
                    )}
                  </Table.Cell>
                  <Table.Cell className={styles.iconBodyCell}>
                    <Popup
                      inverted
                      position="bottom center"
                      content={"Delete"}
                      size="tiny"
                      trigger={
                        <div className={styles.deleteButton}>
                          <Icon
                            name="trash alternate"
                            onClick={() => {
                              return (
                                handelDeleteAreas(
                                  item?.children.length
                                    ? item?.children.map(i => i.id)
                                    : item.id
                                ),
                                setSelectedItem(
                                  selectedItem.filter(item => item.id !== item.id)
                                )
                              );
                            }}
                          />
                        </div>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

export default AreasTable;
