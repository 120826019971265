import React from "react";
import PropTypes from "prop-types";
import { Grid, Header, Table } from "semantic-ui-react";
import moment from "moment";
import styles from "./TasksReport.module.css";
import Logo from "../../../Logo";
import Numeric from "../../../Numeric";

const TasksReport = ({
  data,
  totalBreakdown,
  locationName,
  startDate,
  endDate
}) => {
  const drawMultipleItems = (
    items,
    key,
    additionalSign,
    units = "$ ",
    unitsPosition = "left"
  ) =>
    items.map((item, index) => (
      <div key={`${key}_${index}`}>
        <Numeric
          fractionDigits={2}
          value={item}
          commaSeparatorOnThousands
          units={units}
          unitsPosition={unitsPosition}
        />
        {additionalSign}
      </div>
    ));

  return (
    <div className={styles.reportWrapper}>
      <style>{`@page { size: portrait !important;} html,body {margin: 0px;}`}</style>
      <div className={styles.reportHeaderRow}>
        <div>
          <div className={styles.reportHeader}>Location - {locationName}</div>
          {startDate && endDate && (
            <div className={styles.reportSubHeader}>
              {`Date: ${moment(startDate).format("DD/MM/YY")} - ${moment(
                endDate
              ).format("DD/MM/YY")}`}
            </div>
          )}
        </div>
        <Logo width={140} height={60} />
      </div>
      <Grid.Row>
        <div className={styles.tableHeader}>
          Application Task Cost Breakdown
        </div>
        <Table basic="very" striped className={styles.tableElement}>
          <Table.Header>
            <Table.Row className="header-left">
              <Table.HeaderCell>Task ID</Table.HeaderCell>
              <Table.HeaderCell>Task Type</Table.HeaderCell>
              <Table.HeaderCell>Chemical Cost</Table.HeaderCell>
              <Table.HeaderCell>Machinery Cost</Table.HeaderCell>
              <Table.HeaderCell>Labour Cost</Table.HeaderCell>
              <Table.HeaderCell>Cost ($)/Ha/Task</Table.HeaderCell>
              <Table.HeaderCell>Total Cost($)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(
              ({
                sprayTaskId,
                taskType,
                chemicalCost,
                machineryCost,
                labourCost,
                costHectare,
                totalCost
              }) => (
                <Table.Row
                  key={`task-${sprayTaskId}`}
                  style={{ marginTop: 10 }}
                >
                  <Table.Cell>{sprayTaskId}</Table.Cell>
                  <Table.Cell>{taskType}</Table.Cell>
                  <Table.Cell>
                    <Numeric
                      fractionDigits={2}
                      value={chemicalCost}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Numeric
                      fractionDigits={2}
                      value={machineryCost}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Numeric
                      fractionDigits={2}
                      value={labourCost}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Numeric
                      fractionDigits={2}
                      value={costHectare}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                    /Ha
                  </Table.Cell>
                  <Table.Cell>
                    <Numeric
                      fractionDigits={2}
                      value={totalCost}
                      commaSeparatorOnThousands
                      units="$ "
                      unitsPosition="left"
                    />
                  </Table.Cell>
                </Table.Row>
              )
            )}
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={2}>
                  {`${data.length} Tasks`}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Numeric
                    fractionDigits={2}
                    value={totalBreakdown.chemicalCost}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Numeric
                    fractionDigits={2}
                    value={totalBreakdown.machineryCost}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Numeric
                    fractionDigits={2}
                    value={totalBreakdown.laborCost}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Numeric
                    fractionDigits={2}
                    value={totalBreakdown.cost / totalBreakdown.size}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                  /Ha
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Numeric
                    fractionDigits={2}
                    value={totalBreakdown.cost}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table.Body>
        </Table>
      </Grid.Row>
      <Grid.Row>
        <div className={styles.tableHeader}>
          Chemical Cost and Usage Breakdown
        </div>
        <Table basic="very" striped className={styles.tableElement}>
          <Table.Header>
            <Table.Row className="header-left">
              <Table.HeaderCell>Task ID</Table.HeaderCell>
              <Table.HeaderCell>Task Type</Table.HeaderCell>
              <Table.HeaderCell>Chemical Name</Table.HeaderCell>
              <Table.HeaderCell>Area Treated (Ha)</Table.HeaderCell>
              <Table.HeaderCell>Quantity Applied</Table.HeaderCell>
              <Table.HeaderCell>Cost ($)/Ha</Table.HeaderCell>
              <Table.HeaderCell>Total Cost($)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(
              ({
                sprayTaskId,
                taskType,
                chemicalsForPdf: {
                  chemicalNames,
                  areasTreated,
                  quantitiesApplied,
                  costsHectare,
                  totalCosts
                }
              }) => (
                <Table.Row
                  key={`task-${sprayTaskId}`}
                  style={{ marginTop: 10 }}
                >
                  <Table.Cell>{`AT-${sprayTaskId}`}</Table.Cell>
                  <Table.Cell>{taskType}</Table.Cell>
                  <Table.Cell>
                    {drawMultipleItems(chemicalNames, "chemical")}
                  </Table.Cell>
                  <Table.Cell>
                    {drawMultipleItems(
                      areasTreated,
                      "treated",
                      undefined,
                      " Ha",
                      "right"
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {quantitiesApplied.map(({ value, type }, index) => (
                      <div key={`applied_${index}`}>
                        <Numeric
                          fractionDigits={2}
                          value={value}
                          commaSeparatorOnThousands
                        />
                        {type}
                      </div>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    {drawMultipleItems(costsHectare, "costHectare", "/Ha")}
                  </Table.Cell>
                  <Table.Cell>
                    {drawMultipleItems(totalCosts, "totalCost")}
                  </Table.Cell>
                </Table.Row>
              )
            )}
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={6} />
                <Table.HeaderCell>
                  <Numeric
                    fractionDigits={2}
                    value={totalBreakdown.chemicalCost}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table.Body>
        </Table>
      </Grid.Row>
      <div className={styles.reportCreatedRow}>
        Report created {moment().format("DD/MM/YYYY")} using Tie Up Farming
      </div>
    </div>
  );
};

TasksReport.propTypes = {
  endDate: PropTypes.any,
  startDate: PropTypes.any,
  locationName: PropTypes.string,
  totalBreakdown: PropTypes.object,
  data: PropTypes.array
};

export default TasksReport;
