import React, { useState, useCallback, useEffect } from "react";
import { get, omit, xor } from "lodash";
import {
  Grid,
  Checkbox,
  Form,
  Input,
  Table,
  Segment,
  Icon,
  Modal,
  Button,
  Dropdown,
  Pagination
} from "semantic-ui-react";
import {
  blockSelector,
  archivedBlockSelector,
  cropsOptionSelector,
  varietiesOptionSelector
} from "selectors/areas";
import { farmsOptionsFilterSelector } from "selectors/farms";
import filterTypes from "components/Table/filterTypes";
import Filters from "components/Table/components/Filters";
import styles from "./AreasTree.module.css";
import { Chip } from "routes/Reports/Harvest/components/Chip";

const filtersOptions = [
  {
    filterId: "farmIds",
    title: "Farm",
    type: filterTypes.MultiSelect,
    selector: farmsOptionsFilterSelector
  },
  {
    filterId: "blockIds",
    title: "Block",
    type: filterTypes.MultiSelect,
    selector: state => blockSelector({})(state)
  },
  {
    filterId: "cropIds",
    title: "Crop",
    type: filterTypes.MultiSelect,
    selector: cropsOptionSelector
  },
  {
    filterId: "varietyIds",
    title: "Variety",
    type: filterTypes.MultiSelect,
    selector: state => varietiesOptionSelector({})(state)
  },
  {
    filterId: "archivedBlockIds",
    title: "Show archived",
    type: filterTypes.MultiSelect,
    selector: state => archivedBlockSelector({})(state)
  }
];

const AreasTree = ({
  areas,
  values,
  setFieldValue,
  open,
  setOpenAreaModal
}) => {
  const [search, setSearch] = useState("");
  const [newData, setNewData] = useState(areas);
  const [selectAll, setSelectAll] = useState(
    areas
      .map(item =>
        item.children.length ? item.children.map(ch => ch?.id) : item.id
      )
      .flat().length !== values.areaIds.length
  );
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [expandedItem, setExpandedItem] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    farmIds: [],
    blockIds: [],
    cropIds: [],
    varietyIds: [],
    archivedBlockIds: []
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  useEffect(() => {
    setSelectAll(
      areas
        .map(item =>
          item.children.length ? item.children.map(ch => ch?.id) : item.id
        )
        .flat().length !== values.areaIds.length
    );
  }, [open]);
  const onFilterChange = useCallback(
    (filterKey, value) => {
      const currentFilterElement = filtersOptions.find(
        ({ filterId }) => filterId === filterKey
      );
      if (value === null) {
        setSelectedFilters(filters => omit(filters, filterKey));
      } else if (currentFilterElement.type === filterTypes.MultiSelect) {
        setSelectedFilters(filters => {
          const newValue = xor(filters[filterKey], [value]);
          if (!newValue.length) {
            delete filters[filterKey];
            return { ...filters };
          } else {
            return { ...filters, [filterKey]: newValue };
          }
        });
      } else {
        setSelectedFilters(filters => ({ ...filters, [filterKey]: value }));
      }
    },
    [filtersOptions, setSelectedFilters]
  );

  const clearFilters = useCallback(() => {
    setSelectedFilters({});
  }, [selectedFilters]);

  useEffect(() => {
    const {
      farmIds,
      blockIds,
      cropIds,
      varietyIds,
      archivedBlockIds
    } = selectedFilters;

    const filteredData = areas.filter(item => {
      if (archivedBlockIds?.length && !archivedBlockIds.includes(item.id)) {
        return false;
      }
      if (farmIds?.length && !farmIds.includes(item.farm.id)) {
        return false;
      }
      if (blockIds?.length && !blockIds.includes(item.id)) {
        return false;
      }
      if (
        cropIds?.length &&
        !item.varieties.some(v => cropIds.includes(v.variety.crop.id))
      ) {
        return false;
      }
      if (
        varietyIds?.length &&
        !item.varieties.some(v => varietyIds.includes(v.variety.id))
      ) {
        return false;
      }
      return true;
    });

    setNewData(filteredData);
  }, [selectedFilters, areas]);

  const onSearchChange = useCallback((_, { value: search }) => {
    setSearch(search);
  }, []);

  const searchData = search
    ? newData.filter(item =>
        item.name
          .toUpperCase()
          .replace(/\s/g, "")
          .includes(search.toUpperCase().replace(/\s/g, ""))
      )
    : newData;

  const dataList = searchData.map(item => ({
    ...item,
    expanded: !!expandedItem.find(block => block.id === item.id),
    children:
      item.children.map(patch => ({
        ...patch,
        expanded: !!expandedItem.find(block => patch.id === item.id)
      })) || []
  }));
  const totalPages = Math.ceil(dataList.length / itemsPerPage);
  const paginatedDataList = dataList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, dataList.length);
  useEffect(() => {
    return setSelectedAreas(values.areaIds);
  }, [open]);
  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.areaModal}
    >
      <Modal.Header className={styles.modalHeader}>Select areas</Modal.Header>
      <Modal.Content className={styles.modalContent} scrolling>
        <Form className={styles.firstStepForm}>
          <Form.Group className={styles.tableGroup}>
            <div className={styles.tableWrapper}>
              <div className={styles.filtersContainer}>
                <div className={styles.searchContainet}>
                  <Input
                    value={search}
                    className={styles.searchInput}
                    placeholder="Search"
                    onChange={onSearchChange}
                  />

                  <div className={styles.paginationContainer}>
                    <Dropdown
                      text={`${startItem}-${endItem} from ${dataList.length}`}
                      icon={null}
                      direction="left"
                      className={styles.mainDropdown}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <span className={styles.dropdownLabel}>Show:</span>
                          <Dropdown
                            selection
                            value={itemsPerPage}
                            options={[
                              { key: 10, value: 10, text: "10" },
                              { key: 20, value: 20, text: "20" },
                              { key: 50, value: 50, text: "50" }
                            ]}
                            onChange={(_, { value }) => setItemsPerPage(value)}
                            className={styles.paginationDropDown}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <span className={styles.dropdownLabel}>Go to:</span>
                          <Dropdown
                            selection
                            value={currentPage}
                            options={Array.from(
                              { length: totalPages },
                              (_, i) => ({
                                key: i + 1,
                                value: i + 1,
                                text: ` ${i + 1}`
                              })
                            )}
                            className={styles.pageSelectDropdown}
                            onChange={(_, { value }) => setCurrentPage(value)}
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Pagination
                      prevItem={{ content: "", icon: "angle left" }}
                      nextItem={{ content: "", icon: "angle right" }}
                      ellipsisItem={null}
                      boundaryRange={0}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={0}
                      className={`${styles.pagination} custom-pagination`}
                      totalPages={totalPages}
                      activePage={currentPage}
                      onPageChange={(_, { activePage }) =>
                        setCurrentPage(activePage)
                      }
                    />
                  </div>
                </div>
                <div className={styles.searchAndFiltersContainer}>
                  <div className={styles.filterContainer}>
                    <Filters
                      filterChange={() => {}}
                      options={filtersOptions}
                      selectedFilters={selectedFilters}
                      onFilterChange={onFilterChange}
                      clearFilters={clearFilters}
                    />
                    <Button
                      type="text"
                      onClick={() => {
                        const allAreas = areas.map(item =>
                          item.children.length
                            ? item.children.map(ch => ch?.id)
                            : item.id
                        );
                        return (
                          setSelectAll(!selectAll),
                          setFieldValue(
                            "areaIds",
                            selectAll ? allAreas.flat() : []
                          )
                        );
                      }}
                    >
                      {selectAll ? "Select all" : "Reset all"}
                    </Button>
                  </div>
                </div>
                {(search.length > 0
                  ? dataList.map(i => {
                      return {
                        ...i,
                        expanded: true,
                        children: i.children.map(block => {
                          return {
                            ...block,
                            expanded: true
                          };
                        })
                      };
                    })
                  : paginatedDataList
                )?.map((block, index) => {
                  const uniqueArray = Array.from(
                    new Set(
                      (!block.children.length
                        ? block.varieties
                        : block.children.map(ch => ch.varieties).flat()
                      ).map(obj => obj.variety)
                    )
                  );

                  return (
                    <Grid
                      className={styles.rowContainer}
                      container
                      as={Segment}
                    >
                      <div className={styles.icomNameContainer}>
                        <Grid.Column className={styles.chevronContainer}>
                          <div
                            className={`${
                              block.expanded
                                ? styles.openTable
                                : styles.toggleIcon
                            }`}
                          >
                            <div className={styles.iconNameWrapper}>
                              <Checkbox
                                indeterminate={
                                  !!block.children.some(c =>
                                    values.areaIds.includes(c.id)
                                  ) &&
                                  !block.children.every(c =>
                                    values.areaIds.includes(c.id)
                                  )
                                }
                                checked={
                                  block.children.length
                                    ? block.children.every(block =>
                                        values.areaIds.includes(block.id)
                                      )
                                    : values.areaIds.includes(block.id)
                                }
                                onChange={e => {
                                  setFieldValue(
                                    "areaIds",
                                    !!block.children.length
                                      ? block.children.some(i =>
                                          values.areaIds.includes(i.id)
                                        )
                                        ? values.areaIds.filter(
                                            id =>
                                              !block.children
                                                .map(i => i.id)
                                                .includes(id)
                                          )
                                        : [
                                            ...values.areaIds,
                                            ...block.children.map(i => i.id)
                                          ]
                                      : values.areaIds.includes(block.id)
                                      ? values.areaIds.filter(
                                          i => i !== block.id
                                        )
                                      : [...values.areaIds, block.id]
                                  );
                                }}
                              />
                              <span className={styles.blockName}>
                                {block.name}
                              </span>
                              <span className={styles.cropField}>
                                {uniqueArray.length ? "-" : "Vacant"}
                              </span>
                              <span className={styles.cropField}>
                                {uniqueArray.length > 1 ? (
                                  <Chip
                                    color="green"
                                    label="Crops"
                                    items={[
                                      ...new Set(
                                        uniqueArray.map(i => i.crop.name)
                                      )
                                    ]}
                                  />
                                ) : (
                                  uniqueArray[0]?.crop.name || "-"
                                )}
                              </span>
                              <span className={styles.cropField}>
                                {uniqueArray.length > 1 ? (
                                  <Chip
                                    color="green"
                                    label="Varieties"
                                    items={[
                                      ...new Set(uniqueArray.map(i => i.name))
                                    ]}
                                  />
                                ) : (
                                  uniqueArray[0]?.name || "-"
                                )}
                              </span>
                            </div>
                            {!!block.children.length && (
                              <Icon
                                className={styles.expandedIcon}
                                name={
                                  block.expanded
                                    ? "chevron up"
                                    : "chevron right"
                                }
                                onClick={() => {
                                  let expandedItemInBlock = [...expandedItem];
                                  const blockIndex = expandedItemInBlock.findIndex(
                                    i => i.type === "BLOCK" && i.id === block.id
                                  );
                                  if (blockIndex > -1) {
                                    expandedItemInBlock = expandedItemInBlock.filter(
                                      i =>
                                        i.type === "BLOCK"
                                          ? i.id !== block.id
                                          : i
                                    );
                                  } else {
                                    expandedItemInBlock.push({
                                      type: "BLOCK",
                                      id: block.id
                                    });
                                  }
                                  return setExpandedItem(expandedItemInBlock);
                                }}
                              />
                            )}
                          </div>
                        </Grid.Column>
                      </div>
                      {block.expanded &&
                        !!block.children.length &&
                        block.children.map((patch, index) => {
                          return (
                            patch.parent &&
                            patch.parent?.id === block.id && (
                              <Table
                                size="large"
                                basic="very"
                                className={styles.closeTable}
                              >
                                <Table.Body className={styles.tbody}>
                                  <Table.Row className={styles.patchRow}>
                                    <Table.Cell className={styles.patchCell}>
                                      <div style={{ display: "flex" }}>
                                        <Checkbox
                                          checked={values.areaIds.includes(
                                            patch.id
                                          )}
                                          onChange={() => {
                                            setFieldValue(
                                              "areaIds",
                                              values.areaIds.includes(patch.id)
                                                ? values.areaIds.filter(
                                                    i => i !== patch.id
                                                  )
                                                : [...values.areaIds, patch.id]
                                            );
                                          }}
                                        />
                                        <span className={styles.patchName}>
                                          {patch.name}
                                        </span>
                                        <span className={styles.patchName}>
                                          {uniqueArray.length ? "-" : "Vacant"}
                                        </span>
                                        <span className={styles.cropField}>
                                          {patch.varieties.length > 1 ? (
                                            <Chip
                                              color="green"
                                              label="Crops"
                                              items={[
                                                ...new Set(
                                                  patch.varieties.map(
                                                    i => i.variety.crop.name
                                                  )
                                                )
                                              ]}
                                            />
                                          ) : (
                                            uniqueArray[index].crop.name
                                          )}
                                        </span>
                                        <span className={styles.cropField}>
                                          {patch.varieties.length > 1 ? (
                                            <Chip
                                              color="green"
                                              label="Varieties"
                                              items={[
                                                ...new Set(
                                                  patch.varieties.map(
                                                    i => i.variety.name
                                                  )
                                                )
                                              ]}
                                            />
                                          ) : (
                                            uniqueArray[index].name
                                          )}
                                        </span>
                                      </div>
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                            )
                          );
                        })}
                    </Grid>
                  );
                })}
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className={styles.modalActionBtn}>
        <div className={styles.btnWrapper}>
          <Button
            type="text"
            onClick={() => {
              return (
                setOpenAreaModal(false),
                setFieldValue("areaIds", selectedAreas),
                setSelectAll(true)
              );
            }}
          >
            <Icon name="chevron left" />
            Cancel
          </Button>
          <Button
            content="Save"
            primary
            onClick={() => {
              return setOpenAreaModal(false), setSelectAll(true);
            }}
            disabled={!values.areaIds.length}
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default AreasTree;
