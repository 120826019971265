import React, { useCallback } from "react";
import {
  Button,
  Grid,
  Header,
  Icon,
  Container,
  Popup
} from "semantic-ui-react";
import styles from "./Sidebar.module.css";
import _ from "lodash";
import EmploymentSection from "./components/EmploymentSection";
import PersonalDetailsSection from "./components/PersonalDetailsSection";
import ContactDetailsSection from "./components/ContactDetailsSection";
import AdditionalInformationSection from "./components/AdditionalInformationSection";
import { ROLE_COLORS } from "../../constants";
import { getNameInitials } from "../../utils";
import { RestoreIcon } from "../../../../assets/icons/RestoreIcon";
import { currentUserIdSelector, userBERolesSelector } from "selectors/user";
import { useSelector } from "react-redux";

const Sidebar = ({
  employee,
  Can,
  onClose,
  onEditClick,
  isArchive,
  onDeleteItem
}) => {
  const sectionsArray = [
    EmploymentSection,
    PersonalDetailsSection,
    ContactDetailsSection,
    AdditionalInformationSection
  ];

  const currentUserId = useSelector(currentUserIdSelector);
  const userPermissions = useSelector(userBERolesSelector);

  const img = employee && _.get(employee, "picture.presignedUrl", null);

  if (_.isEmpty(employee)) {
    onClose();
  }

  const canUpdate = useCallback(() => {
    const role = employee?.type.toLowerCase();
    const ourPermissions = userPermissions.filter(el => el.includes("manage"));

    return ourPermissions.includes(`employees:manage_${role}`);
  }, [employee]);

  return (
    !!employee && (
      <Container fluid className={styles.root}>
        <Grid className={styles.sidebarHeader}>
          <Grid.Row columns={"equal"} verticalAlign="middle">
            <Grid.Column width={10}>
              <div className={styles.headerColumn} width={10}>
                <div
                  className={styles.avatarHolder}
                  style={
                    img
                      ? { backgroundImage: `url(${img})` }
                      : {
                        background: ROLE_COLORS[employee.type]
                          ? ROLE_COLORS[employee.type]
                          : "gray"
                      }
                  }
                >
                  {!img && getNameInitials(employee)}
                </div>
                <div className={styles.headerContainer}>
                  <Header className={styles.name} as="h1">
                    {employee.firstName} {employee.lastName}
                  </Header>
                </div>
              </div>
            </Grid.Column>
            <Can I="update" a="employees">
              <Grid.Column floated="right">
                {isArchive ? (
                  <Popup
                    inverted
                    content={
                      employee.id === currentUserId || canUpdate()
                        ? "Restore"
                        : "You dont have permissions to perform this action"
                    }
                    position="bottom center"
                    size="tiny"
                    trigger={
                      <div className={styles.editButtonWrapper}>
                        <Button
                          onClick={e => onDeleteItem(e, employee.id, "Restore")}
                          className={styles.editButton}
                          disabled={
                            employee.id === currentUserId ? false : !canUpdate()
                          }
                          floated="right"
                          icon={<RestoreIcon />}
                          size="small"
                        />
                      </div>
                    }
                  />
                ) : (
                  <Popup
                    inverted
                    content={
                      employee.id === currentUserId || canUpdate()
                        ? "Edit"
                        : "You dont have permissions to perform this action"
                    }
                    size="tiny"
                    position="bottom center"
                    trigger={
                      <div className={styles.editButtonWrapper}>
                        <Button
                          onClick={e => onEditClick(e, employee.id)}
                          className={styles.editButton}
                          disabled={
                            employee.id === currentUserId ? false : !canUpdate()
                          }
                          floated="right"
                          icon={<Icon name="edit" />}
                          size="small"
                        />
                      </div>
                    }
                  />
                )}
              </Grid.Column>
            </Can>
            <Grid.Column width={1} floated="right">
              <Popup
                inverted
                position="bottom center"
                content={"Close"}
                size="tiny"
                trigger={
                  <Button
                    className={styles.closeButton}
                    onClick={onClose}
                    floated="right"
                    icon={<Icon className="tuf-times" />}
                    size="small"
                  />
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid className={styles.infoContainer}>
          {sectionsArray.map((Component, index) => (
            <Component
              key={index}
              Can={Can}
              editButton={
                !isArchive && (
                  <Button
                    onClick={e => onEditClick(e, employee.id, index + 1)}
                    className={styles.sectionEditButton}
                    floated="right"
                    icon={<Icon name="edit" />}
                    size="small"
                    position="bottom right"
                  />
                )
              }
              employee={employee}
            />
          ))}
        </Grid>
        <div className={styles.sidebarFooter}>
          <Button
            disabled={employee.id === currentUserId || !canUpdate()}
            size="small"
            type={!isArchive ? 'text' : 'delete'}
            onClick={e =>
              onDeleteItem(e, employee.id, !isArchive ? "Archive" : "Delete")
            }
            color={isArchive ? "red" : "green"}
          >
            {isArchive ? (
              <span style={{ display: "flex" }}>
                <Icon name="trash alternate" /> Delete
              </span>
            ) : (
              <span>
                {" "}
                <Icon name="archive" /> Archive
              </span>
            )}
          </Button>
        </div>
      </Container>
    )
  );
};

export default Sidebar;
