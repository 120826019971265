import React, { useCallback, useMemo } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import ObjectID from 'bson-objectid';

import { calcNotAllocatedPallets } from '../../Stock';
import AllocatePalletsTableItem from './AllocatePalletsTableItem';
import styles from './AllocatePallets.module.css';
import { validationErrors } from '../../../../../utils/validationRules';
import ErrorLabel from '../../../../../components/ErrorLabel';
import PropTypes from 'prop-types';

const AllocatePalletsTable = ({ stock, items, setItems }) => {
  const onDelete = useCallback(
    (_, _id) => {
      setItems(items.filter(item => item._id !== _id));
    },
    [items, setItems],
  );

  const changeItemValue = useCallback(
    (name, _id, value) => {
      setItems(
        items.map(item => {
          if (item._id === _id) {
            return {
              ...item,
              [name]: value,
            };
          }
          return item;
        }),
      );
    },
    [items, setItems],
  );

  const palletsToCreate = useMemo(() => items.map(item => item.count).reduce((sum, x) => sum + Number(x), 0), [items]);
  const notAllocatedPallets = useMemo(() => calcNotAllocatedPallets(stock), [stock]);

  return (
    <>
      <Grid.Row className={styles.tableItem}>
        <Grid.Column width={5}>
          <Form.Field>
            <label>Pallets count</label>
          </Form.Field>
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Field>
            <label>Select area</label>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      {items.map(item => (
        <AllocatePalletsTableItem
          key={item._id}
          stock={stock}
          item={item}
          showDeleteButton={items.length > 1}
          changeItemValue={changeItemValue}
          onDelete={onDelete}
        />
      ))}
      <Grid.Row className={styles.tableItem}>
        <Grid.Column width={13}>
          <Form.Input
            name="totalPallets"
            value={notAllocatedPallets - palletsToCreate}
            type="hidden"
            className={styles.inputHidden}
            validations={'maxPalletsCount'}
            validationErrors={validationErrors({
              maxPalletsCount: notAllocatedPallets,
            })}
            errorLabel={ErrorLabel}
          />
          <div className={`${styles.addHolder} ${styles.tableItem} ${styles.wide}`}>
            <Button
              type="button"
              icon={<Icon className={styles.plusIcon} name='plus circle' size='large' />}
              className="transparent"
              onClick={() => {
                setItems([
                  ...items,
                  {
                    _id: ObjectID().toHexString(),
                    area: { id: null },
                    count: Math.max(notAllocatedPallets - palletsToCreate, 0),
                  },
                ]);
              }}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

AllocatePalletsTable.propTypes = {
  stock: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
};

AllocatePalletsTable.defaultProps = {
  items: [],
};

export default AllocatePalletsTable;
