import React, { useEffect, useState } from "react";
import { Segment, Table, Icon, Button, Popup } from "semantic-ui-react";
import styles from "../Sidebar.module.css";
import { StatusChip } from "routes/WorkingTime/components/StatusChip";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { updateWorkingTimeStatus } from "actions/WorkingTime";
import doubleCheck from "assets/img/doubleCheck.svg";

const ClockDetails = ({
  activeItem = {},
  areas,
  farms,
  employees,
  setSubRowsIndex,
  setClockOutOpen,
  subRowsIndex,
  index
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const approvedBy = employees.find(item => item.id === activeItem.approvedBy);
  const filtredArea = areas.filter(item =>
    activeItem?.areas?.some(i => i.areaId === item.id)
  );
  const allFarm = filtredArea.map(item => item.farm.name);
  const activeEmployee = employees.find(
    item => item.id === activeItem.employeeId
  );
  const start = new Date(activeItem.startAt);
  const end = new Date(activeItem.endAt);
  const diffInMs = end - start;
  const lunchIntervalInMs = (activeItem.lunchInterval || 0) * 60 * 1000;
  const diffInHours = (diffInMs - lunchIntervalInMs) / (1000 * 60 * 60);

  function convertToHoursMinutes() {
    const date = diffInHours.toFixed(2);
    const hours = Math.floor(date);
    const minutes = Math.round((date - hours) * 60);
    return `${hours}h ${minutes}m`;
  }

  const approvedWorkingTime = wtId => {
    dispatch(updateWorkingTimeStatus([wtId]));
  };

  useEffect(() => {
    (index === subRowsIndex || subRowsIndex == "totalRow") && setOpen(true);
  });
  return (
    <Segment className={styles.segment}>
      <div className={styles.rowFieldWrapper}>
        <span className={styles.nameRowField}>
          {" "}
          {`${activeEmployee?.firstName} ${activeEmployee?.lastName}`}
        </span>
        <span className={styles.totalRowField}>Total time:</span>
        <span className={styles.totalRowField}>{convertToHoursMinutes()}</span>
        <span className={styles.iconRowField}>
          <Popup
            inverted
            position="bottom center"
            content={"Edit"}
            size="tiny"
            trigger={
              <div className={styles.editButtonWrapper}>
                <Button
                  onClick={() => {
                    return setClockOutOpen(true), setSubRowsIndex(index);
                  }}
                  className={styles.editButton}
                  floated="right"
                  icon={<Icon name="edit" />}
                  size="small"
                />
              </div>
            }
          />
        </span>
        <span className={styles.iconRowField}>
          {!activeItem.approved ? (
            <Popup
              inverted
              position="bottom center"
              content={"Edit"}
              size="tiny"
              trigger={
                <div className={styles.editButtonWrapper}>
                  <Button
                    onClick={() => approvedWorkingTime(activeItem.id)}
                    className={styles.editButton}
                    floated="right"
                    icon={<Icon id="btn" name="checkmark" />}
                    size="small"
                  />
                </div>
              }
            />
          ) : (
            <img
              id="btn"
              className={styles.doubleCheck}
              src={doubleCheck}
              alt="Drag icon"
            />
          )}
        </span>
        <span className={styles.iconRowField}>
          <Icon
            className={styles.rowChevronIcon}
            onClick={() => {
              return setSubRowsIndex(false), setOpen(!open);
            }}
            name={!!open ? `chevron up` : `chevron down`}
          />
        </span>
      </div>
      {(open || index === subRowsIndex || subRowsIndex == "totalRow") && (
        <Table className={styles.taskDetailsTable} definition fixed>
          <Table.Body>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>Farm</Table.Cell>
              <Table.Cell> {allFarm.join(", ")}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>Areas</Table.Cell>
              <Table.Cell>
                {filtredArea.map(item => item?.name).join(", ")}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>Clock in</Table.Cell>
              <Table.Cell>
                {moment(activeItem.startAt).format("DD/MM/YYYY hh:mm A")}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Clock out
              </Table.Cell>
              <Table.Cell>
                {moment(activeItem.endAt).format("DD/MM/YYYY hh:mm A")}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Lunch interval (min)
              </Table.Cell>
              <Table.Cell>{activeItem.lunchInterval || "-"}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Total working time
              </Table.Cell>
              <Table.Cell> {convertToHoursMinutes()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                No. of pieces
              </Table.Cell>
              <Table.Cell> {activeItem.piecesPerEmployee || "-"}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Piece rate ($)
              </Table.Cell>
              <Table.Cell> {activeItem.pieceRate || "-"}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Approved by
              </Table.Cell>
              <Table.Cell>
                {" "}
                {approvedBy
                  ? `${approvedBy?.firstName} ${approvedBy?.lastName}`
                  : "-"}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>Comments</Table.Cell>
              <Table.Cell> {activeItem.comment || "-"}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </Segment>
  );
};

export default connect((state, props) => {
  return {
    employees: [
      ...state.employee.list.content,
      ...state.employee.archivedList.content
    ],
    areas: state.areas.list.content,
    farms: state.farms.summary
  };
})(ClockDetails);
