import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Grid, Icon } from "semantic-ui-react";
import styles from "./TableSprayTask.module.css";
import Numeric from "../Numeric";
import AreasColumn from "./AreasColumn";
import TaskPlannedOrEndDate from "./TaskPlannedOrEndDate";
import ShowMoreColumn from "./ShowMoreColumn";

const handleFullList = (event, toggleChemicals, chemicalsToShow) => {
  event.stopPropagation();
  toggleChemicals(chemicalsToShow);
};

function RowEmployee({
  task,
  setActiveTask,
  taskData,
  statuses,
  dilutionRateTypes,
  handlePrintTask
}) {
  const [chemicalsToShow, toggleChemicals] = useState(
    task.chemicals ? [task.chemicals[0]] : []
  );
  const [showAll, toggleShowAll] = useState(false);
  const assigneesToShow = useMemo(() => {
    return taskData && taskData.assignees
      ? taskData.assignees.map(item => item.employee)
      : [];
  }, [taskData]);

  return (
    <Grid.Row
      className={`${styles[`row${task.status}`]} ${task._id && styles.newTask}`}
      onClick={() => setActiveTask(task.id || task._id)}
    >
      <Grid.Column
        mobile={8}
        tablet={2}
        computer={2}
        largeScreen={2}
        widescreen={2}
      >
        AT-{task.id || "New"}
        <br />
        {task.taskName || ""}
      </Grid.Column>
      <Grid.Column
        mobile={8}
        tablet={2}
        computer={2}
        largeScreen={2}
        widescreen={1}
      >
        <Icon name="circle" className={styles[`status${task.status}`]} />
        {statuses[task.status]}
      </Grid.Column>
      <AreasColumn
        mobile={8}
        tablet={2}
        computer={2}
        largeScreen={2}
        items={taskData.blocksNames}
        showAll={showAll}
        onChange={toggleShowAll}
      />
      <Grid.Column
        mobile={8}
        tablet={2}
        computer={2}
        largeScreen={2}
        className={styles.columnSize}
      >
        <div>
          <Numeric value={taskData.hectares} /> ha
        </div>
        <div>
          <Numeric value={task.literPerHectare} /> L/Ha
        </div>
      </Grid.Column>

      <Grid.Column
        mobile={8}
        tablet={1}
        computer={1}
        largeScreen={1}
        className="show-sm"
      >
        <Numeric value={taskData.plannedTanks} /> Tank
      </Grid.Column>
      <Grid.Column mobile={16} tablet={2} computer={2} largeScreen={2}>
        {task.machinery && <div>{task.machinery.name}</div>}
        <div>Concentration of {task.conc}</div>
        {chemicalsToShow.map(chemical => (
          <div key={`chem_${chemical.chemical.name}`}>
            {chemical.chemical.name} <Numeric value={chemical.qtyPerFullVat} />{" "}
            per {dilutionRateTypes[chemical.dilutionRateType]}
          </div>
        ))}
        {task.chemicals.length > 1 && (
          <Button
            className={`button-text ${styles.readFull}`}
            onClick={event =>
              handleFullList(
                event,
                toggleChemicals,
                chemicalsToShow.length === 1
                  ? task.chemicals
                  : [task.chemicals[0]]
              )
            }
          >
            {chemicalsToShow.length === 1 ? "Read" : "Close"} full instructions
          </Button>
        )}
      </Grid.Column>
      <ShowMoreColumn
        mobile={8}
        tablet={2}
        computer={2}
        largeScreen={2}
        items={assigneesToShow}
        showAll={showAll}
        onChange={toggleShowAll}
        renderItem={item => (
          <div className={styles.chemicalName}>
            {item.firstName} {item.lastName}
          </div>
        )}
      />
      <Grid.Column
        mobile={8}
        tablet={2}
        computer={2}
        largeScreen={2}
        widescreen={2}
      >
        <TaskPlannedOrEndDate task={task} />
      </Grid.Column>
      <Grid.Column
        mobile={8}
        tablet={1}
        computer={1}
        largeScreen={1}
        widescreen={1}
      >
        <Numeric
          value={
            task.status === "COMPLETED"
              ? taskData.actualTotalTanks
              : taskData.plannedTanks
          }
          fractionDigits={2}
          commaSeparatorOnThousands
        />
      </Grid.Column>
      <Grid.Column width={1} className={styles.columnActions} textAlign="right">
        <span
          className={styles.printHolder}
          onClick={event => handlePrintTask(event, task)}
        >
          <Icon name="print" />
        </span>
        <Dropdown
          icon="ellipsis vertical"
          iconposition="right"
          floating
          button
          className={`button-text ${styles.buttonMore}`}
        >
          <Dropdown.Menu direction="left">
            <Dropdown.Item>Some menu</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Column>
    </Grid.Row>
  );
}

RowEmployee.propTypes = {
  task: PropTypes.object.isRequired,
  setActiveTask: PropTypes.func,
  handlePrintTask: PropTypes.func,
  taskData: PropTypes.object,
  statuses: PropTypes.object,
  dilutionRateTypes: PropTypes.object
};

RowEmployee.defaultProps = {
  task: {}
};

export default RowEmployee;
