import React, { Component } from "react";
import { Button, Confirm, Grid, Table } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { validationErrors } from "../../../../utils/validationRules";
import { abilitiesSelector } from "../../../../selectors/user";
import SegmentClosable from "../../../../components/SegmentClosable";
import styles from "./EmployeesBarcodesGeneratorForm.module.css";
import SelectEmployee from "../../../../components/SelectEmployee/SelectEmployee";
import ErrorLabel from "../../../../components/ErrorLabel";
import {
  clearSeries,
  generateEmployeesBarcodes,
  markPrinted
} from "../../../../actions/EmployeeBarcodes/employeeBarcodes";
import { toBarcodeFormat } from "../../../../utils/employeesBarcodes";
import ReactDOMServer from "react-dom/server";
import { copyStyles } from "../../../../utils/styles";
import EmployeesBarcodesPDF from "../../EmployeesBarcodesPDF";
import SelectEmployeesBarcodesPrintLayout, {
  PrintLayouts
} from "./SelectPrintLayout";
import PropTypes from "prop-types";

const initialValues = {
  selectedEmployees: [],
  quantity: null,
  showConfirm: false,
  confirmAction: null
};

const columnMiddle = {
  mobile: 16,
  tablet: 16,
  computer: 10,
  largeScreen: 10,
  widescreen: 10
};

class EmployeesBarcodesGeneratorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialValues
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    //clear();
  }

  componentWillUnmount() {
    //clear();
  }

  clear = () => {
    const { actions, formRef } = this.props;
    actions.clearSeries();
    this.isSubmitted = false;
    this.setState({ ...initialValues }, () => {
      const targetRef = formRef || this.formRef;
      targetRef.current.reset();
    });
  };

  print = employeeBarcodes => {
    const { actions, printLayout } = this.props;
    const newWindow = window.open();
    newWindow.document.title = ``;
    copyStyles(window.document, newWindow.document);
    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      PrintLayouts[printLayout].html.render(employeeBarcodes)
    );
    actions.markPrinted(employeeBarcodes.employee.id);
    newWindow.focus();
    setTimeout(function() {
      newWindow.print();
    }, 2000);
  };

  showReprintConfirm = confirmAction => {
    this.setState({
      showConfirm: true,
      confirmAction: confirmAction
    });
  };

  hideConfirm = () => {
    this.setState({
      showConfirm: false,
      confirmAction: null
    });
  };

  onConfirm = () => {
    this.hideConfirm();
    const { confirmAction } = this.state;
    confirmAction();
  };

  onValidSubmit = async () => {
    const { actions } = this.props;
    const { selectedEmployees, quantity } = this.state;

    actions.generateEmployeesBarcodes({
      employeesIds: selectedEmployees,
      quantity
    });
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  render() {
    const {
      Can,
      formRef,
      series,
      isFetching,
      actions,
      printLayout
    } = this.props;
    const { selectedEmployees, quantity, showConfirm } = this.state;
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        onReset={() => this.clear()}
        loading={false}
        ref={formRef || this.formRef}
      >
        <div className={styles.sprayGrid}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <SegmentClosable title="Employees barcode information">
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <label>Select employees</label>
                        <SelectEmployee
                          multiple
                          name="employees"
                          validations={"atLeaseOnSelected"}
                          validationErrors={validationErrors()}
                          errorLabel={ErrorLabel}
                          required
                          value={selectedEmployees}
                          onChange={(_, data) =>
                            this.setState({ selectedEmployees: data.value })
                          }
                          disabled={isFetching || (series && series.length > 0)}
                          showSelectAll={true}
                          clearable
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <Form.Input
                          fluid
                          required
                          label="Quantity"
                          placeholder="Insert barcodes quantity"
                          name="quantity"
                          type="number"
                          format="integer"
                          value={quantity}
                          onChange={event =>
                            this.setState({ quantity: event.target.value })
                          }
                          validations={{
                            isPositiveInteger: true,
                            upTo1000: true
                          }}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Quantity"
                          })}
                          errorLabel={ErrorLabel}
                          disabled={isFetching || (series && series.length > 0)}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Can I="add" a="employees_barcodes">
                        <Button
                          primary
                          size="large"
                          type="button"
                          loading={isFetching}
                          disabled={isFetching || (series && series.length > 0)}
                          onClick={this.onSubmit}
                        >
                          Generate
                        </Button>
                        <Button primary size="large" type="reset">
                          Clear
                        </Button>
                      </Can>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
              {series && series.length > 0 && (
                <>
                  <SegmentClosable title="Series list">
                    <Grid>
                      <Grid.Row>
                        <Grid.Column {...columnMiddle}>
                          <SelectEmployeesBarcodesPrintLayout />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column {...columnMiddle}>
                          <div className={`${styles.barcodesTableHolder}`}>
                            <Table
                              unstackable
                              compact
                              basic="very"
                              className={`${styles.barcodesTable}`}
                            >
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Employee</Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Start barcode
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    End barcode
                                  </Table.HeaderCell>
                                  <Table.HeaderCell />
                                  <Table.HeaderCell />
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {series
                                  .sort((s1, s2) =>
                                    (s1.printed || false) ===
                                    (s2.printed || false)
                                      ? s1.employee.id - s2.employee.id
                                      : (s1.printed || false) -
                                        (s2.printed || false)
                                  )
                                  .map(employeeBarcodes => (
                                    <Table.Row
                                      key={employeeBarcodes.employee.id}
                                      className={`${
                                        styles.barcodesRow
                                      } ${employeeBarcodes.printed &&
                                        styles.printed}`}
                                    >
                                      <Table.Cell>
                                        {employeeBarcodes.employee.firstName +
                                          " " +
                                          employeeBarcodes.employee.lastName}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {toBarcodeFormat(
                                          employeeBarcodes.employee,
                                          employeeBarcodes.firstBarcode
                                        )}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {toBarcodeFormat(
                                          employeeBarcodes.employee,
                                          employeeBarcodes.lastBarcode
                                        )}
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div
                                          className={styles.barcodesRowActions}
                                        >
                                          {PrintLayouts[printLayout] &&
                                            PrintLayouts[printLayout].html && (
                                              <Button
                                                primary
                                                type="button"
                                                loading={isFetching}
                                                onClick={() =>
                                                  employeeBarcodes.printed
                                                    ? this.showReprintConfirm(
                                                        () =>
                                                          this.print(
                                                            employeeBarcodes
                                                          )
                                                      )
                                                    : this.print(
                                                        employeeBarcodes
                                                      )
                                                }
                                              >
                                                Print
                                              </Button>
                                            )}
                                          {PrintLayouts[printLayout] &&
                                            PrintLayouts[printLayout].pdf && (
                                              // TODO: TBD, should use printLayout.pdf.render
                                              <EmployeesBarcodesPDF
                                                employeeBarcodes={
                                                  employeeBarcodes
                                                }
                                                onClick={defaultAction =>
                                                  employeeBarcodes.printed
                                                    ? this.showReprintConfirm(
                                                        defaultAction
                                                      )
                                                    : defaultAction()
                                                }
                                                onPDFshow={() =>
                                                  actions.markPrinted(
                                                    employeeBarcodes.employee.id
                                                  )
                                                }
                                              />
                                            )}
                                        </div>
                                      </Table.Cell>
                                    </Table.Row>
                                  ))}
                              </Table.Body>
                            </Table>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </SegmentClosable>
                </>
              )}
            </div>
          </div>
        </div>
        <Confirm
          content={"Are you sure you want to print it again?"}
          open={showConfirm}
          onConfirm={this.onConfirm}
          onCancel={this.hideConfirm}
        />
      </Form>
    );
  }
}

EmployeesBarcodesGeneratorForm.propTypes = {
  isFetching: PropTypes.bool,
  formRef: PropTypes.func,
  Can: PropTypes.func,
  printLayout: PropTypes.any,
  series: PropTypes.array,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  const {
    employeesBarcodes: { series, isFetching, printLayout }
  } = state;
  return {
    Can: abilitiesSelector(state),
    series,
    isFetching,
    printLayout
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      markPrinted,
      generateEmployeesBarcodes,
      clearSeries
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesBarcodesGeneratorForm);
