import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Button,
  Confirm,
  Icon,
  Form,
  Container
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import moment from "moment";
import ObjectID from "bson-objectid";

import { progressBarStep } from "routes/Tasks/components/helper";
import AttachmentLabel from "components/Attachments/AttachmentLabel";
import FileChooser from "components/Attachments/FileChooser";
import {
  createWorkingTime,
  updateGeneralTask,
  uploadAttachment,
  fetchGeneralTaskById
} from "actions/Tasks";
import ComboForm from "./StepOneForm";
import DetailsSection from "./Detailsection";
import { initialValues, getValidationSchema } from "./validationSchema";

import styles from "./CompletionModal.module.css";

const CompletionModal = ({
  open,
  employeeList,
  machineryList,
  selectedAreaIds,
  setSelectedItems,
  onClose,
  existingWorkingTimes,
  filtredArea,
  task
}) => {
  const dispatch = useDispatch();
  const [secondOpen, setSecondOpen] = useState(false);
  const [loadingAttachments, setLoadingAttachments] = useState([]);
  const [step, setStep] = useState(1);

  const handleTaskSubmit = async taskData => {
    const dataToSend = {
      ...task,
      ...taskData,
      areas: [
        ...task.areas.filter(item =>
          taskData.areas.every(area => area.areaId !== item.areaId)
        ),
        ...taskData.areas
      ]
    };
    await dispatch(updateGeneralTask(task.id, dataToSend));
    dispatch(fetchGeneralTaskById(task.id));
  };

  const handleSubmit = async ({ step1, step2, common }) => {
    let workingTimes = [];

    const prepareWorkingTimeData = (wt, assigneeRow, assigneeRowIndex) => {
      return {
        areas: assigneeRowIndex
          ? [{ areaId: selectedAreaIds[assigneeRowIndex] }]
          : selectedAreaIds.map(areaId => ({ areaId })),
        employeeId: assigneeRow.assignee,
        machineryId: assigneeRow.machinery,
        generalTaskId: task.id,
        startAt: `${moment(wt.dateStart, "DD/MM/YYYY").format("YYYY-MM-DD")}T${wt.timeStart
          }:00Z`,
        endAt: `${moment(wt.dateEnd, "DD/MM/YYYY").format("YYYY-MM-DD")}T${wt.timeEnd
          }:00Z`,
        lunchInterval: wt.lunchInterval,
        pieceRate: wt.pieceRate,
        piecesPerEmployee: wt.piecesCount
      };
    };

    if (common.isDetailedCompletion) {
      step2.areaRows.forEach((areaRow, areaRowIndex) => {
        areaRow.assigneeRows.forEach(assigneeRow => {
          assigneeRow.workingTimes.forEach(wt =>
            workingTimes.push(
              prepareWorkingTimeData(wt, assigneeRow, areaRowIndex)
            )
          );
        });
      });
    } else {
      workingTimes = step1.assigneeRows.map(assigneeRow =>
        prepareWorkingTimeData(step2.general, assigneeRow)
      );
    }

    const dispatchPromises = workingTimes.map(formData =>
      dispatch(createWorkingTime({ formData }))
    );
    const results = await Promise.all(dispatchPromises);
    const allSuccessful = results.every(result => result.status === 200);

    if (allSuccessful) {
      setSelectedItems([]);

      const taskData = {
        areas: filtredArea.map(item => ({
          areaId: item.id,
          completed: true
        })),
        attachments: step1.attachments,
        description: step1.description
      };

      handleTaskSubmit(taskData);
    } else {
      console.error("One or more requests failed");
    }

    onClose();
  };

  const onAttachmentSelected = (setFieldValue, values) => files => {
    setLoadingAttachments(attachments => [...attachments, "attachments"]);
    files.forEach(file => {
      const dataToSend = {
        file,
        idReplace: ObjectID().toHexString(),
        callBack: loadedFile => {
          setFieldValue(
            "step1.attachments",
            values.step1["attachments"]
              ? [...values.step1["attachments"], loadedFile]
              : [loadedFile]
          );
          setLoadingAttachments(attachments =>
            attachments.filter(i => i !== "attachments")
          );
        }
      };
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(uploadAttachment(dataToSend));
      };
    });
  };

  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
    >
      <Formik
        validationSchema={getValidationSchema(existingWorkingTimes)}
        validateOnMount
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isValid, values, dirty, errors, setFieldValue, submitForm }) => (
          <>
            <Modal.Header className={styles.modalHeader}>
              {selectedAreaIds?.length
                ? `Completion of ${selectedAreaIds.length} area${selectedAreaIds.length > 1 ? "s" : ""
                }`
                : "Edit area"}
              <Icon
                name="close"
                className={styles.closeIcon}
                onClick={() => {
                  dirty ? setSecondOpen(true) : onClose();
                }}
              />
            </Modal.Header>
            <Modal.Content scrolling className={styles.modalContentContainer}>
              <div className={styles.progressBar}>
                {progressBarStep(step)}
                <div className={styles.progressBarHeader}>
                  {step === 1
                    ? "Choose combinations of assignees and machines that worked on the areas"
                    : "How long did it take you to complete the task?"}
                </div>
              </div>
              <div className={styles.formContainer}>
                {step === 1 ? (
                  <div className={styles.stepOneContainer}>
                    <Form>
                      <div className={styles.assigneeField}>
                        {values.step1.assigneeRows.map((row, index, rows) => (
                          <ComboForm
                            key={index}
                            assigneeIndex={index}
                            assignee={row}
                            assigneeRows={rows}
                            error={
                              errors?.step1?.assigneeRows?.[index]?.assignee
                            }
                            handleFieldChange={(value, key) => {
                              // update specific row property or update entire assigneeRows
                              const finalKey = `step1.assigneeRows${key ? `.${index}.${key}` : ""
                                }`;
                              setFieldValue(finalKey, value);
                            }}
                          />
                        ))}
                        <div className={styles.addBtnWrapper}>
                          <Button
                            type="text"
                            onClick={() => {
                              setFieldValue("step1.assigneeRows", [
                                ...values.step1.assigneeRows,
                                { assignee: "", machinery: "" }
                              ]);
                            }}
                          >
                            <Icon name="plus" />
                            Add assignee/machinery combo
                          </Button>
                        </div>
                      </div>
                      <div className={styles.additionalInformation}>
                        <div className={styles.assigneeWrapper}>
                          <div className={styles.assigneeHeader}>
                            Additional information
                          </div>
                          <div className={styles.comboContainer}>
                            <Form.Group>
                              <Field
                                placeholder="You can leave some notes here"
                                as={Form.TextArea}
                                width={16}
                                name="description"
                                label="Notes"
                                onChange={(_, { value }) =>
                                  setFieldValue("step1.description", value)
                                }
                              />
                            </Form.Group>
                            <Form.Group className={styles.attachFileWrapper}>
                              <Container
                                fluid
                                className={styles.generalAttachmentsContainer}
                              >
                                {values.step1.attachments.map(
                                  (attachment, index) => (
                                    <AttachmentLabel
                                      attachment={attachment}
                                      withDownloadfromEdit={
                                        attachment.online ? false : true
                                      }
                                      onDeleteClick={() => {
                                        setFieldValue(
                                          "step1.attachments",
                                          values.step1.attachments.filter(
                                            (_, i) => i !== index
                                          )
                                        );
                                      }}
                                    />
                                  )
                                )}
                                <FileChooser
                                  label="Attach files"
                                  className={styles.generalAttachmentsButton}
                                  disabled={loadingAttachments.includes(
                                    "attachments"
                                  )}
                                  onChange={onAttachmentSelected(
                                    setFieldValue,
                                    values
                                  )}
                                />
                              </Container>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                ) : (
                  <DetailsSection
                    stepData={values.step2}
                    isDetailedCompletion={values.common.isDetailedCompletion}
                    employeeList={employeeList}
                    machineryList={machineryList}
                    areasList={values.blocks}
                    filtredArea={filtredArea}
                    errors={errors?.step2}
                    handleFieldChange={setFieldValue}
                  />
                )}
              </div>
              <div className={styles.buttonContainer}></div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                disabled={step === 1}
                type="text"
                onClick={() => {
                  setStep(1);
                }}
              >
                <Icon name="chevron left" />
                Back
              </Button>
              {step === 1 && (
                <Button
                  content="Next"
                  primary
                  disabled={errors.step1}
                  onClick={() => {
                    setStep(2);

                    // creating areaRows for step2 based on assigneeRows from step1
                    const areaRows = Array(selectedAreaIds.length)
                      .fill()
                      .map(() => ({
                        assigneeRows: values.step1.assigneeRows.map(
                          assigneeRow => ({
                            ...assigneeRow,
                            workingTimes: [
                              {
                                dateStart: null,
                                dateEnd: null,
                                timeStart: null,
                                timeEnd: null,
                                lunchInterval: null,
                                pieceRate: null,
                                piecesCount: null
                              }
                            ]
                          })
                        )
                      }));

                    setFieldValue("step2.areaRows", areaRows);
                  }}
                />
              )}
              {step === 2 && (
                <Button
                  primary
                  content="Complete"
                  disabled={!isValid}
                  onClick={submitForm}
                />
              )}
            </Modal.Actions>
          </>
        )}
      </Formik>
      <Confirm
        className={styles.confirmModal}
        content="Discard unsaved changes?"
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={onClose}
      />
    </Modal>
  );
};

export default CompletionModal;
