import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import { Popup, Button, Icon } from "semantic-ui-react";
import { get } from "lodash";
import { fetchGeneralTaskList } from "../actions/GeneralTasks/generalTasks";
import { generalTasksFilterOptions } from "selectors/generalTasks";
import styles from "../routes/GeneralTasks/CostEntering/CostEntering.module.css";
import { Chip } from "routes/Reports/Harvest/components/Chip";
import { CONTRACTOR_TYPE } from "constants/GeneralTasks/costEntering";
import moment from "moment";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { costTypeFilterOptions } from "selectors/generalTasks";
import Numeric from "components/Numeric/Numeric";
import { areaFilterOptions } from "selectors/areas";
import { farmsOptionsFilterSelector } from "selectors/farms";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";

export const getCostEnteringTableColumns = (
  areasList,
  deleteCosts,
  isFetching
) => {
  const columnsConfig = {
    costType: {
      withSort: true,
      type: "string",
      id: "costType",
      filterId: "costType",
      title: "Cost type",
      accessor: ({ costType }) => {
        return get(
          CONTRACTOR_TYPE.find(({ value }) => value === costType),
          "text",
          "-"
        );
      },
      cellWidth: 2, // of 16, see Semantic Table
      filter: {
        type: filterTypes.SingleSelect,
        selector: costTypeFilterOptions
      }
    },
    area: {
      title: "Area",
      type: "string",
      withSort: true,
      id: "areaIds",
      filterId: "areaIds",
      filter: {
        type: filterTypes.MultiSelect,
        selector: areaFilterOptions
      },
      accessor: ({ areaIds }) => {
        let result = areasList
          .filter(area => areaIds.some(areaId => area.key === areaId))
          .map(item => item.text);
        return result ? (
          Array.isArray(result) && result.length > 1 ? (
            <Chip items={result} label="Areas" />
          ) : (
            result[0]
          )
        ) : (
          "-"
        );
      },
      cellWidth: 1
    },
    costSubType: {
      withSort: true,
      id: "costSubType",
      type: "string",
      title: "Type",
      accessor: ({ costSubType }) => costSubType || "_",
      cellWidth: 2,
      filterId: "farmIds",
      filter: {
        title: "Farm",
        type: filterTypes.MultiSelect,
        selector: farmsOptionsFilterSelector
      }
    },

    units: {
      withSort: true,
      id: "units",
      type: "number",
      title: "Units",
      cellWidth: 1,
      accessor: ({ units }) => {
        return units || "-";
      }
    },
    rate: {
      withSort: true,
      title: "Rate ($)",
      type: "number",
      id: "rate",
      accessor: ({ rate }) => {
        return (
          <Numeric
            value={rate ? rate : 0}
            fractionDigits={rate > 1000 ? 0 : 2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        );
      },
      cellWidth: 1
    },

    total: {
      withSort: true,
      id: "total",
      type: "number",
      title: "Total ($)",
      accessor: ({ total }) => {
        return (
          <Numeric
            value={total ? total : 0}
            fractionDigits={total > 1000 ? 0 : 2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        );
      },
      cellWidth: 2
    },
    notes: {
      withSort: true,
      id: "notes",
      title: "Notes",
      accessor: ({ notes }) => (
        <Popup
          trigger={<div className={styles.notesWrapper}>{notes || "—"}</div>}
          content={<div className={styles.commentContainer}>{notes}</div>}
          inverted
          position="left center"
        />
      ),
      cellWidth: 2
    },
    datePeriod: {
      withSort: true,
      id: "startAt",
      type: "number",
      title: "Date/Period",
      accessor: ({ startAt, endAt }) => {
        return endAt ? (
          <span>
            {`${moment(startAt).format("DD/MM/YYYY")}-${moment(endAt)
              .add(-1, "day")
              .format("DD/MM/YYYY")}`}
          </span>
        ) : (
          <span>{`${moment(startAt).format("DD/MM/YYYY")}`}</span>
        );
      },
      cellWidth: 2
    },

    dateCreated: {
      withSort: true,
      id: "createdAt",
      type: "number",
      title: "Date created",
      filterId: "dateCreated",
      accessor: ({ createdAt }) => {
        return (
          <span>
            {moment
              .utc(createdAt)
              .local()
              .format("DD/MM/YYYY")}
          </span>
        );
      },
      cellWidth: 2,
      filter: {
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching} />
      }
    },
    deleteButton: {
      id: "deleteButton",
      title: "",
      withSort: true,
      accessor: ({ id }) => {
        return (
          <div className={styles.deleteButtonWrapper}>

            <Popup
              inverted
              position="bottom center"
              content={"Delete"}
              size="tiny"
              trigger={
                <div className={styles.deleteButton}>
                  <Icon
                    name="trash alternate"
                    onClick={() => deleteCosts(id)}
                  />
                </div>
              }
            />
          </div>
        );
      },
      filterId: "seasons",
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector
      },
      cellWidth: 1
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};
