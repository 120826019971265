import PropTypes from "prop-types";
import React from "react";
import { Button, Container, Grid, Header, Icon } from "semantic-ui-react";
import ExcelReport from "./components/ExcelReport";
import Loader from "../Loader";
import classNames from "classnames";
import { connect, shallowEqual, useSelector } from "react-redux";
import styles from "./index.module.css";
import TasksTable from "./components/TasksTable";
import { filter, sum, groupBy, sortBy, sumBy } from "lodash";
import { copyStyles } from "../../utils/styles";
import ReactDOMServer from "react-dom/server";
import PrintReport from "./components/PrintReport";
import { getFiltersFromLocations } from "../../utils/routeHelpers";
import SortDropdown from "../SortDropdown";
import { hashToObject } from "../../utils/hashToObject";
import { columnsConfig, defaultFiltersOptions, sortOptions } from "./options";
import Filters from "../Filters";
import { minutesToHM } from "../../utils/timeUtils";
import ExportToExcel from "../ExportToExcel";
import { taskList } from "selectors/generalTasks";

const GeneralTasksCostsBreakdown = ({
  breakdownTitle,
  generalTasks,
  isReportInProgress,
  area,
  farm,
  farmName,
  patchName,
  dateFrom,
  dateTo,
  excludeFilters,
  showDetailedInfo,
  setGeneralTasksSummary,
  totalSize,
  navigate,
}) => {
  const isFarm = !!farm && !area;
  const isDateProvided = dateFrom || dateTo;
  const itemSize = (isFarm ? farm.size : area?.size) || totalSize;
  const location = useSelector((state) => state.router.location, shallowEqual);

  const { tasksCostsSort } = hashToObject(location.hash);
  const [sortValue, sortOrder] = (tasksCostsSort || "taskType,desc").split(",");

  const filterOptions = defaultFiltersOptions.filter(
    (f) => !excludeFilters.includes(f.id)
  );

  const filterData = getFiltersFromLocations(filterOptions, location);

  const dateFromL = filterData.task_costs_dateFrom
    ? filterData.task_costs_dateFrom[0]
    : null;
  const dateToL = filterData.task_costs_dateTo
    ? filterData.task_costs_dateTo[0]
    : null;

  dateFrom = isDateProvided ? dateFrom : dateFromL;
  dateTo = isDateProvided ? dateTo : dateToL;

  const combinedTasksMap = groupBy(
    generalTasks.map((task) => {
      const totalCosts = sum([task.labourCost, task.machineryCost]);

      return {
        ...task,
        taskId: `GT-${task.generalTaskId}`,
        totalWorkingTimeString: minutesToHM(task.totalWorkingTimesMins),
        totalCosts,
        calculatedCostPerHectare: totalCosts / task.totalAreasSize,
      };
    }),
    "generalTaskType"
  );
  const combinedTasks = Object.keys(combinedTasksMap).map((taskType) => {
    const tasksList = combinedTasksMap[taskType];

    const totalMachineryCost = sumBy(tasksList, "machineryCost");
    const totalLabourCost = sumBy(tasksList, "labourCost");
    const totalAltLabourCost = sumBy(tasksList, "altLabourCost");
    const totalWorkingTimeInMinutes = sumBy(tasksList, "totalWorkingTimesMins");
    const totalWorkingTimesMins =
      tasksList.reduce(
        (prev, curr) => prev + curr.totalWorkingTimesMins / curr.totalAreasSize,
        0
      ) || 0;
    const costPerAreaPerTask =
      (totalMachineryCost + totalLabourCost + totalAltLabourCost) /
      itemSize /
      tasksList.length;
    return {
      taskType,
      tasksList,
      totalMachineryCost,
      totalLabourCost,
      totalAltLabourCost,
      totalWorkingTimesMins,
      totalWorkingTimeInMinutes,
      costPerAreaPerTask,
    };
  }, []);
  const listOfTasks = sortBy(
    Object.values(combinedTasksMap).flat(),
    (i) => i.generalTaskId
  );

  const print = () => {
    const newWindow = window.open();
    newWindow.document.title = `General Tasks Cost Breakdown`;
    copyStyles(window.document, newWindow.document);
    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <PrintReport
        data={listOfTasks}
        combinedTasks={combinedTasks}
        size={itemSize}
        locationName={farmName ? farmName : patchName}
        startDate={dateFrom}
        endDate={dateTo}
      />
    );
    newWindow.print();
  };

  setGeneralTasksSummary(
    sum(
      listOfTasks.map(
        (task) =>
          (task.labourCost ? task.labourCost : 0) +
          (task.machineryCost ? task.machineryCost : 0) +
          (task.altLabourCost ? task.altLabourCost : 0)
      )
    )
  );

  return (
    <Container className={styles.wrapper}>
      <div style={{ height: 10 }} />
      <Grid.Row className={classNames(styles.marginBottom)}>
        {breakdownTitle && (
          <Header as="h2" className={styles.noMargin}>
            {breakdownTitle}
          </Header>
        )}
      </Grid.Row>
      <Grid.Row className={classNames(styles.justifyContentBetween)}>
        <div>
          <Filters navigate={navigate} options={filterOptions} />
        </div>
        <div>
          <ExportToExcel
            rawData={listOfTasks}
            columns={columnsConfig}
            name={"Costs Breakdown"}
            className={styles.mr10}
          />
          <Button primary style={{ marginLeft: 20 }} onClick={print}>
            <Icon name="print" />
            <span>Print</span>
          </Button>
          <SortDropdown
            navigate={navigate}
            sortName={"tasksCostsSort"}
            sortOptions={sortOptions}
            sortOrder={sortOrder}
            sortValue={sortValue}
            defaultSort={"taskType,desc"}
          />
        </div>
      </Grid.Row>
      <div style={{ height: 10 }} />
      <div
        style={{
          width: "98%",
        }}
      >
        {isReportInProgress ? (
          <Loader />
        ) : (
          <TasksTable
            sortBy={{ id: sortValue, desc: sortOrder === "desc" }}
            size={itemSize}
            showDetailedInfo={showDetailedInfo}
            combinedTasks={combinedTasks}
          />
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const {
    farmGeneralTaskReport: { isReportInProgress, data },
  } = state;

  return {
    isReportInProgress,
    generalTasks: Array.isArray(data) ? data : [],
  };
};

GeneralTasksCostsBreakdown.propTypes = {
  area: PropTypes.object,
  breakdownTitle: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  farm: PropTypes.object,
  excludeFilters: PropTypes.array,
  isFarm: PropTypes.bool.isRequired,
  manual: PropTypes.bool,
  generalTasks: PropTypes.arrayOf({
    generalTaskId: PropTypes.number.isRequired,
    generalTaskName: PropTypes.string.isRequired,
    generalTaskSubtaskName: PropTypes.string,
    generalTaskType: PropTypes.string,
    generalTaskTypeId: PropTypes.number,
    labourCost: PropTypes.number,
    machineryCost: PropTypes.number,
    totalAreasSize: PropTypes.number,
    totalWorkingTimesMins: PropTypes.number,
  }),
  setGeneralTasksSummary: PropTypes.func,
  showDetailedInfo: PropTypes.bool,
  tasks: PropTypes.array,
};

GeneralTasksCostsBreakdown.defaultProps = {
  excludeFilters: [],
  isFarm: false,
  manual: false,
  setGeneralTasksSummary: () => {},
  showDetailedInfo: true,
  tasks: [],
  generalTasks: [],
};

export default connect(
  mapStateToProps,
  null
)(React.memo(GeneralTasksCostsBreakdown));
