import React, { useEffect } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { Dropdown, Form, Grid, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, find } from "lodash";
import { fetchScoutingTasks } from "actions/Scouting/scouting";
import { NO_RESULTS_MESSAGE } from "constants/shared/messages";
import styles from "../Sidebar.module.css";

const ScoutingTaskDropdown = ({ scoutingTasksList, isFetching, actions }) => {
  const { values, setFieldValue } = useFormikContext();

  const selectedScouting = find(
    scoutingTasksList,
    item => item.id === values.scoutingId
  );

  useEffect(() => {
    actions.fetchScouting({ unpaged: true });
  }, []);

  return (
    <Grid.Row>
      <Grid.Column>
        <Form.Field>
          <label>Link scouting task</label>
          <Dropdown
            fluid
            clearable
            loading={isFetching}
            placeholder="Select"
            noResultsMessage={NO_RESULTS_MESSAGE}
            text={selectedScouting ? `AT-${selectedScouting.id}` : null}
            onChange={(_, { value }) => {
              setFieldValue("scoutingId", value);
            }}
            className={classNames(styles.dropdown, "selection")}
          >
            <Dropdown.Menu>
              {scoutingTasksList.map(item => (
                <Dropdown.Item
                  onClick={() => setFieldValue("scoutingId", item.id)}
                >
                  <Header
                    size="small"
                    className="bold"
                    color="grey"
                    style={{ marginBottom: 5 }}
                  >
                    AT-{item.id}
                  </Header>
                  <Header size="small">{item.name}</Header>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Field>
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = state => {
  const { isFetching, data } = state.scouting;

  return {
    scoutingTasksList: get(data, "content", []),
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchScouting: fetchScoutingTasks
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScoutingTaskDropdown);
