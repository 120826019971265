import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Button, Header, Modal } from "semantic-ui-react";
import styles from "./Chemicals.module.css";
import { chemicalsSelector } from "../../../../selectors/sprayDiary";
import { connect } from "react-redux";
import TaskHeader from "../TaskHeader";
import assignValue from "../../../../utils/assignValue";
import ChemicalForm from "../../../Chemicals/AddNewChemical/ChemicalForm";
import {
  addNewChemical,
  setChemicalFromList,
  deleteChemicalItem,
  setChemicalItemToAdd,
  addChemicalItem
} from "../../../../actions/SprayDiary/chemicals";
import ChemicalItem from "./ChemicalItem/ChemicalItem";
import { valueToString } from "../../../../utils/tasksUtils";
import LayoutOverlay from "../../../../components/LayoutOverlay";

class Chemicals extends Component {
  state = {
    showChemicalModal: false,
    chemicalIdToDelete: null,
    showDeleteModal: false,
    showAddNewChemical: false,
    chemicalToAdd: null
  };
  newChemicalForm = React.createRef();

  componentDidMount() {
    const { actions } = this.props;
    actions.setChemicalItemToAdd();
  }

  deleteChemicalItem = chemicalIdToDelete => {
    this.setState({
      chemicalIdToDelete,
      showDeleteModal: true
    });
  };

  hideDeleteModal = () => {
    this.setState({
      chemicalIdToDelete: null,
      showDeleteModal: false
    });
  };

  handleDeleteChemical = async () => {
    const { actions, updateTask } = this.props;
    const { chemicalIdToDelete } = this.state;
    await actions.deleteChemicalItem(chemicalIdToDelete);
    this.hideDeleteModal();
    updateTask();
  };

  handleCloseChemicalModal = () => {
    const { setExternalField } = this.props;
    this.setState({
      showChemicalModal: false
    });
    setExternalField(false);
  };

  createChemicalSubmit = async data => {
    const { actions, onChange } = this.props;

    const chemical = await actions.addNewChemical(data);
    if (chemical && chemical.id) {
      onChange(chemical.id);
    }
    this.setState({
      showChemicalModal: false
    });
  };

  onChangeChemical = (value, isNew) => {
    const { actions, setExternalField } = this.props;
    if (value === "new") {
      this.setState({
        showChemicalModal: true
      });
      setExternalField(true);
    } else if (isNew) {
      actions.setChemicalFromList("new", value);
    }
  };

  onChangeToAdd = (value, name) => {
    const {
      actions,
      sprayTask: { chemicalToAdd }
    } = this.props;
    let targetKey = name.split("chemical")[1];
    targetKey = targetKey.charAt(0).toLowerCase() + targetKey.slice(1);
    const keyToSet = targetKey.split(".")[0];
    assignValue(chemicalToAdd, targetKey, value);
    actions.setChemicalItemToAdd("new", {
      [keyToSet]: chemicalToAdd[keyToSet],
      new: true
    });
  };

  onChangeDilution = (id, value, isNew) => {
    const { onChange, actions } = this.props;

    if (isNew) {
      actions.setChemicalItemToAdd(id, { ...value, new: isNew });
    } else {
      onChange(value);
    }
  };

  doSubmitForm = () => {
    if (this.newChemicalForm.current) {
      this.newChemicalForm.current.submit();
    }
  };

  handleAddNewChemical = keepField => {
    const { toggleEditField, setExternalField } = this.props;
    if (!keepField) {
      toggleEditField({});
    }
    this.setState({
      showAddNewChemical: true
    });
    setExternalField(true);
  };

  handleCloseAddModal = () => {
    const { setExternalField, actions, toggleEditField } = this.props;

    this.setState({
      showAddNewChemical: false
    });
    setExternalField(false);
    actions.setChemicalItemToAdd();
    toggleEditField({});
  };

  addNewDisabled = () => {
    const {
      sprayTask: { chemicalToAdd }
    } = this.props;

    const gotRate =
      chemicalToAdd.rate.rate >= 0 ||
      (chemicalToAdd.rate.minRate >= 0 && !(chemicalToAdd.rate.maxRate >= 0)) ||
      (chemicalToAdd.rate.minRate >= 0 &&
        chemicalToAdd.rate.maxRate >= 0 &&
        chemicalToAdd.rate.minRate === chemicalToAdd.rate.maxRate);

    return (
      !chemicalToAdd.name || !gotRate || !valueToString(chemicalToAdd.rate.rate)
    );
  };

  addChemicalToTheList = async () => {
    const { actions, updateTask, fieldToEdit } = this.props;
    await actions.addChemicalItem(
      "new",
      fieldToEdit ? fieldToEdit.suffix : null
    );
    updateTask();
    this.handleCloseAddModal();
  };

  toggleEditField = async ({ name, value, suffix, ...rest }) => {
    const { toggleEditField, actions } = this.props;
    toggleEditField({ name, value, suffix, ...rest });
    if (["selectedChemical", "chemicalTarget"].includes(name)) {
      await actions.setChemicalFromList("new", value, suffix);
      this.handleAddNewChemical(true);
    }
  };

  render() {
    const {
      sprayTask,
      shedId,
      chemicalsOptions,
      totalTanks,
      toggleEditField,
      fieldToEdit,
      onChange,
      shouldDisableFieldsEdit
    } = this.props;
    const {
      showChemicalModal,
      showDeleteModal,
      showAddNewChemical
    } = this.state;

    return (
      <>
        <Header>Chemicals</Header>
        <Modal
          size="mini"
          open={showDeleteModal}
          onClose={this.hideDeleteModal}
        >
          <Modal.Header>Delete Chemical</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this Chemical?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.hideDeleteModal}>No</Button>
            <Button positive onClick={this.handleDeleteChemical}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>

        {showAddNewChemical && (
          <div className={styles.formHolder}>
            <div className={styles.formHolderIn}>
              <TaskHeader
                fieldToEdit={{}}
                sprayTaskId={sprayTask.id}
                onSave={this.addChemicalToTheList}
                disabledSave={this.addNewDisabled()}
                onCancel={this.handleCloseAddModal}
              />
              <div className={styles.formHolderBody}>
                <Header>Add Chemical to the Task</Header>
                <ChemicalItem
                  item={sprayTask.chemicalToAdd}
                  sprayTask={sprayTask}
                  shedId={shedId}
                  chemicalsOptions={chemicalsOptions}
                  totalTanks={totalTanks}
                  toggleEditField={toggleEditField}
                  showAddNewChemical={showAddNewChemical}
                  fieldToEdit={fieldToEdit}
                  onChange={this.onChangeToAdd}
                  onChangeChemical={value => this.onChangeChemical(value, true)}
                  onChangeDilution={(id, value) =>
                    this.onChangeDilution("new", value, true)
                  }
                  editAllMode
                />
              </div>
            </div>
          </div>
        )}
        {showChemicalModal && (
          <div className={styles.formHolder}>
            <div className={styles.formHolderIn}>
              <TaskHeader
                fieldToEdit={{}}
                sprayTaskId={sprayTask.id}
                onSave={this.doSubmitForm}
                onCancel={this.handleCloseChemicalModal}
              />
              <div className={styles.formHolderBody}>
                <Header>Add New Chemical</Header>
                <LayoutOverlay childrenOnly noOverflow>
                  <ChemicalForm
                    modal={true}
                    onValidSubmit={this.createChemicalSubmit}
                    showMobileHeader
                    sprayTaskId={sprayTask.id}
                    onCancel={this.handleCloseChemicalModal}
                    formRef={this.newChemicalForm}
                    className={styles.chemicalForm}
                  />
                </LayoutOverlay>
              </div>
            </div>
          </div>
        )}
        {sprayTask &&
          !showAddNewChemical &&
          sprayTask.selectedChemicals.map((item, index) => (
            <ChemicalItem
              key={`chemical_${item.id}`}
              item={item}
              sprayTask={sprayTask}
              chemicalsOptions={chemicalsOptions}
              totalTanks={totalTanks}
              toggleEditField={this.toggleEditField}
              fieldToEdit={fieldToEdit}
              onChange={onChange}
              onChangeChemical={value => this.onChangeChemical(value, false)}
              onChangeDilution={this.onChangeDilution}
              deleteChemicalItem={this.deleteChemicalItem}
              shouldDisableFieldsEdit={shouldDisableFieldsEdit}
            />
          ))}
        {!showAddNewChemical && !shouldDisableFieldsEdit && (
          <div className={styles.addHolder}>
            <Button
              type="text"
              icon={{ name: "plus circle", size: "large", }}
              onClick={() => this.handleAddNewChemical()}
            />
          </div>
        )}
      </>
    );
  }
}

Chemicals.propTypes = {
  sprayTask: PropTypes.object,
  actions: PropTypes.object,
  chemicalsOptions: PropTypes.array,
  updateTask: PropTypes.func,
  setExternalField: PropTypes.func,
  toggleEditField: PropTypes.func,
  fieldToEdit: PropTypes.object,
  onChange: PropTypes.func,
  shouldDisableFieldsEdit: PropTypes.bool
};

Chemicals.defaultProps = {};

const mapStateToProps = state => {
  return {
    chemicalsOptions: chemicalsSelector(state),
    totalTanks: state.sprayDiary.totalTanks
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addNewChemical,
      setChemicalFromList,
      deleteChemicalItem,
      setChemicalItemToAdd,
      addChemicalItem
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Chemicals);
