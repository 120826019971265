import React, { useEffect, useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import styles from "./index.module.css";
import { connect, useDispatch } from "react-redux";
import { fetchTaskTypeList } from "actions/GeneralTasks/generalTasks";
import { history } from "store";
import { setSelectAddType } from "actions/Tasks";

const TaskTypeModal = ({
  open,
  setOpen,
  data,
  taskTypes,
  activeType,
  navigate
}) => {
  const [selectedType, setSelectedType] = useState();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    setSelectedType(activeType?.value);
  };

  useEffect(() => {
    setSelectedType(activeType?.value);
  }, [activeType?.value]);

  useEffect(() => {
    !taskTypes.length && dispatch(fetchTaskTypeList());
  }, []);
  const taskTypesOptions = taskTypes
    .map(type => ({
      id: type.id,
      name: type.name,
      image: type.logo.presignedUrl
    }))
    .sort((a, b) => (a.id > b.id ? 1 : -1));

  return (
    <Modal
      size="large"
      open={open}
      onClose={handleClose}
      className={styles.taskTypeModal}
    >
      <Modal.Header className={styles.taskTypeModalContentHeader}>
        <div>Add a task</div>
      </Modal.Header>
      <Modal.Content className={styles.taskTypeModalContent}>
        {taskTypesOptions.map(
          type =>
            data.allowedGeneralTaskTypeIds.includes(type.id) && (
              <div
                key={type.id}
                onClick={() => (
                  setSelectedType(type.id), dispatch(setSelectAddType(type))
                )}
                className={
                  selectedType !== 0 && selectedType === type.id
                    ? styles.activeTaskCard
                    : styles.taskCard
                }
              >
                <img src={type.image} alt="" className={styles.taskTypeIcon} />
                <span className={styles.caption}>{type.name}</span>
              </div>
            )
        )}
      </Modal.Content>
      <Modal.Actions className={styles.btnContainer}>
        <Button
          content="Cancel"
          type="text"
          onClick={handleClose}
        />
        <Button
          className={styles.addButton}
          content="Save"
          onClick={() => navigate("/tasks/add")}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};

export default connect((state, props) => {
  return {
    activeType: state.newTasks.activeTab,
    taskTypes: state.taskCreator.taskTypes.content,
    data: state.settings.data,
    isCreating: state.settings.isCreating
  };
})(TaskTypeModal);
